.page-head-wrap-fix {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
  opacity: 0;
}

.opacity-0 {
  z-index: -1;
  opacity: 0;
}

.opacity-1 {
  z-index: 2; // fix:CDD-1438
  opacity: 1;
}

.header-banner-box {
  width: 100%;
  overflow: hidden;
  position: relative;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
  }
}

.shop-head-user-box {
  height: 32px;
  background: #F8F8F8;
  border-bottom: 1px solid #eee;

  .main-box {
    margin: 0 auto;
    max-width: 1200px;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: space-between;
  }

  .head-welcome-txt {
    font-size: 12px;
    color: var(--font-normal);
    padding-left: 24px;
    line-height: 32px;
  }

  .head-user-nav-box {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: flex-end;

    .ant-popover-inner-content {
      padding: 0 !important;
    }

    p {
      margin-bottom: 0;
    }

    .nav-card {
      margin-left: 16px;
      cursor: pointer;
      font-size: 12px;
    }
  }
}

.nav-card-main {
  display: flex;
  padding: 16px;

  .qr-code-box {
    text-align: center;
    margin: 0 12px;

    p {
      margin-bottom: 0;
    }

    .code-box {
      display: inline-block;
      width: 104px;
      height: 104px;

      img {
        width: 100%;
        height: 100%;
      }
    }

  }

}

.user-nav-card-box {
  .user-nav-card {
    width: 160px;
    height: 32px;
    line-height: 32px;
    border-bottom: 1px solid var(--border-deep);
    cursor: pointer;
    color: var(--font-normal);
    padding: 0 12px;

    &:hover {
      color: var(--color);
    }
  }
}

.head-main-box {
  position: relative;

  .line-wrap {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 2px;
    background: var(--color);
  }

  .main-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    height: 78px;

    .logo-box {
      width: 200px;
      height: 50px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .search-wrap {

      .cart-box {
        display: inline-flex;
        vertical-align: middle;
        margin-left: 12px;
        height: 36px;
        align-items: center;
        align-content: center;
        justify-content: center;
        border: 1px solid var(--border-deep);
        padding: 0 12px;
        cursor: pointer;

        i {
          margin-right: 6px;
        }
      }
    }

    .search-box {
      width: 400px;
      height: 36px;
      line-height: 36px;
      vertical-align: middle;
      display: inline-flex;
      align-content: center;
      align-items: center;
      border: 2px solid var(--color);
      position: relative;

      .txt {
        flex: 1;
        height: 30px;
        line-height: 30px;
        border: 0 none;
        font-size: 14px;
      }

      .search-btn {
        width: 54px;
        height: 100%;
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        background: var(--color);
        color: #fff;
        cursor: pointer;

        &:hover {
          opacity: .8;
        }
      }

      .dropdown-box {
        position: absolute;
        top: 34px;
        left: -2px;
        right: -2px;
        background: #fff;
        z-index: 1;
      }
    }
  }

}

.search-log-list-box {
  border: 1px solid var(--color);
  background: #fff;
  max-height: 300px;
  display: flex;
  flex-direction: column;

  .keyword-foot-box {
    width: 100%;
    height: 34px;
    line-height: 34px;
    padding: 0 12px;
    text-align: right;
    border-top: 1px solid var(--border-deep);

    .txt {
      font-size: 14px;
      color: var(--font-normal);
      cursor: pointer;

      &:hover {
        color: var(--color);
      }
    }
  }

  .logo-box {
    flex: 1;
    height: 0;
    overflow: scroll;
    width: 100%;
  }

  .log-item {
    height: 24px;
    line-height: 24px;
    padding: 0 12px;
    display: flex;
    align-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    color: var(--color);
    font-size: 12px;
    margin: 0 !important;

    .tips {
      color: var(--font-tips);
    }

    .ope {
      position: relative;
      z-index: 2;
    }

    .del {
      display: none;
    }

    &:hover {
      background: #fff;

      .tips {
        display: none;
      }

      .del {
        display: block;
      }

      &:after {
        content: "";
        background: var(--color);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: .1;
        z-index: 0;
      }
    }

    .txt {
      flex: 1;
      width: 0;
      position: relative;
      z-index: 2;
    }
  }
}

.nav-wrap {
  display: flex;
  align-content: center;
  align-items: center;

  .menu-wrap {
    position: relative;
    width: 204px;

    &:hover {
      .menu-box {
        display: block;
      }
    }

    .menu-title {
      width: 100%;
      height: 40px;
      background-color: var(--color);
      color: #FFFFFF;
      padding: 0 24px;
      line-height: 38px;
      font-size: 16px;
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: space-between;

      i {
        font-size: 18px;
      }
    }

    .menu-box {
      display: none;
      position: absolute;
      width: 100%;
      z-index: 2;

      .menu-main-box {
        width: 100%;
        background: #fff;

        .menu-item {
          display: inline-flex;
          align-content: center;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          height: 38px;
          line-height: 38px;
          font-size: 14px;
          color: var(--font-title);
          padding: 0 12px;
          border-bottom: 1px solid var(--border-deep);
          cursor: pointer;

          .icon-jump {
            font-size: 12px;
            color: #ccc;
            pointer-events: none;
          }

          &:hover {
            background: var(--color-hover);
            color: var(--color);

            .goto {
              color: var(--color);
            }
          }
        }

      }
    }
  }

  .nav-box {
    flex: 1;
    width: 0;
    overflow: hidden;
    white-space: nowrap;

    .nav-item {
      display: inline-block;
      vertical-align: middle;
      font-size: 16px;
      font-weight: 600;
      height: 38px;
      line-height: 34px;
      margin: 0 28px 2px;
      cursor: pointer;
      color: #333;

      &:hover, &.active {
        color: var(--color)
      }
    }
  }
}

.menu-sub-box {
  display: none;
  position: absolute;
  top: 0;
  left: 204px;
  width: 996px;
  height: 100%;
  background: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  line-height: 1;
  font-size: 0;
  cursor: pointer;
  z-index: 2;
  overflow: auto;
  padding: 0 24px;

  dl {
    width: calc(50% - 6px);
    display: inline-block;
    vertical-align: top;

    &:nth-child(2n -1) {
      margin-right: 12px;

    }
  }

  dt {
    padding: 24px 0 12px;
    color: var(--font-title);
    border-bottom: 1px solid var(--border-middle);
    font-size: 14px;
    font-weight: bold;

    &:hover {
      color: var(--color);
      background-color: var(--color-hover);
    }
  }

  dd {
    display: inline-block;
    padding: 0 10px;
    font-size: 12px;
    height: 32px;
    line-height: 32px;
    color: var(--font-normal);
    margin-right: 8px;
    position: relative;

    .short-line {
      display: inline-block;
      width: 1px;
      background: var(--border-deep);
      position: absolute;
      height: 16px;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    &:hover {
      color: var(--color);
      background-color: var(--color-hover);
    }
  }

  .nav-service-item-box {
    padding: 16px 0;
    font-size: 0;
  }

  .item-box-card {
    display: inline-block;
    vertical-align: top;
    width: 144px;
    line-height: 20px;
    margin-right: 16px;
    margin-bottom: 20px;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    color: var(--font-title);
    text-align: center;

    &:hover {
      color: var(--color);
    }

    &:nth-child(6n) {
      margin-right: 0
    }
  }

  .active {
    color: var(--color-active);
  }
}

.menu-active {
  display: block;
}


.menu-brand-item-box {
  padding: 52px 0 16px;
  font-size: 0;
  position: relative;
  height: 100%;

  p {
    margin-bottom: 0;
  }

  .first-letter-box {
    position: absolute;
    top: 16px;
    left: 0;
    width: 100%;
    font-size: 14px;
    color: var(--font-title);
    margin-bottom: 12px;
    z-index: 2;

    .letter {
      display: inline-block;
      vertical-align: middle;
      padding: 0 8px;
      height: 24px;
      line-height: 24px;
      border: 1px solid transparent;
      cursor: pointer;

      &:hover {
        color: var(--color);
      }

      &.active {
        border-color: var(--color);
        color: var(--color);
      }
    }
  }

  .brand-item-box-main {
    height: 100%;
    overflow: auto;
  }

  .brand-box-card {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 120px;
    height: 70px;
    border: 1px solid var(--border-deep);
    cursor: pointer;
    -webkit-transition: all 0.4s ease-out;
    -moz-transition: all 0.4s ease-out;
    transition: all 0.4s ease-out;
    word-break: break-all;

    &:hover {
      .info-box {
        opacity: 1;
        transition: opacity .3s linear;
      }
    }

    .img-box {
      width: 100%;
      height: 100%;
      display: inline-flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      overflow: hidden;

      span {
        font-size: 14px;
        color: var(--font-title);
        padding: 0 16px;
        font-weight: bold;
      }

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }

    .info-box {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.8);
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      opacity: 0;
      transition: opacity .3s linear;

      .title-box {
        padding: 0 8px;
        text-align: center;
      }

      .title {
        font-size: 14px;
        line-height: 20px;
      }

      .tips {
        font-size: 12px;
        line-height: 18px;
      }
    }
  }
}

.affix-box {
  position: fixed;
  right: 18px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;

  .affix-item {
    width: 36px;
    height: 36px;
    cursor: pointer;
    text-align: center;
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      .iconfont {
        color: var(--color);
      }
    }

    .iconfont {
      font-size: 24px;
      line-height: 36px;
      color: #9B9B9B;
    }
  }

  .qrcode-box {
    text-align: center;

    .img-box {
      width: 136px;
      margin-top: 12px;

      img {
        height: 136px;
      }

      .txt {
        font-size: 12px;
        color: var(--font-tips);
        margin-top: 8px;
      }
    }
  }

  .service-box {
    text-align: left;

    dt {
      font-size: 12px;
      color: var(--font-tips);
      margin-bottom: 10px;
    }

    dd {
      margin-bottom: 8px;

      &:hover {
        color: var(--color);

        .txt {
          color: var(--color);
        }
      }

      .svg-icon {
        width: 16px;
        height: 16px;
      }

      .tel {
        font-size: 16px;
        font-weight: bold;
      }
    }
  }
}
