.cart-page-wrap {
  width: 1200px;
  min-width: 1200px;
  min-height: 400px;
  padding: 12px 14px 64px;
  margin: 0 auto;
  background: #fff;

  .ant-spin {
    display: block;
    padding: 48px 0 36px;
  }

  .ant-empty {
    padding: 48px 0 36px;
  }
}

.cart-table {
  .cart-table-head {
    display: flex;
    align-items: center;
    height: 50px;
    border: 1px solid var(--border-deep);
    background: #F5F5F5;

    .ant-checkbox-wrapper {
      padding: 0 10px;
    }

    .item {
      flex-grow: 1; // 是否允许放大
      flex-shrink: 1; // 是否允许缩小
      flex-basis: auto; //
      padding: 10px;
    }
  }

  .cart-table-body-order-promotion-box {

    .order-promotion-box {
      font-size: 14px;

      .tag {
        display: inline-block;
        vertical-align: middle;
        margin-right: 4px;
        padding: 0 8px;
        height: 18px;
        font-size: 12px;
        line-height: 16px;
        border: 1px solid #FF6350;
        color: #FF6350;
        border-radius: 4px;
      }

      .text {
        display: inline-block;
        vertical-align: middle;
        margin-right: 4px;
        font-size: 14px;
        color: var(--font-title);
      }

      .tips {
        display: inline-block;
        vertical-align: middle;
        margin-right: 4px;
        color: #FF6350;
      }

      .btn {
        display: inline-block;
        vertical-align: middle;
        margin-right: 4px;
        padding: 0 8px;
        height: 20px;
        font-size: 12px;
        line-height: 18px;
        border: 1px solid var(--border-deep);
        color: var(--font-normal);
        border-radius: 4px;
        cursor: pointer;

        .iconfont {
          font-size: 12px;
          margin-left: 4px;
        }
      }
    }
  }

  .cart-table-body {

  }

  .cart-table-body-head {
    line-height: 32px;
    margin-top: 8px;
    font-size: 14px;
    color: var(--font-title);

    .col {
      margin: 0 12px;

      .time,
      .icon-goods-tag-delivery,
      .icon-goods-tag-shipments {
        color: var(--font-high);
      }
    }

    .ant-checkbox-wrapper {
      padding: 10px;
      margin-right: 10px;
    }
  }


  .cart-table-body-tr {
    border: 1px solid #E3E3E3;
    padding: 4px 0 6px;
    margin-top: -1px;

    .tr-expand {
      padding-left: 10px;

      .iconfont {
        color: var(--color);
        margin-right: 8px;
      }

      .col {
        margin-right: 16px;
        color: var(--font-tips);
      }

      .remark-input {
        width: 390px;
      }
    }

    .tr-expand-check {
      padding-left: 46px;
    }

    .tr-body {
      display: flex;

      .ant-checkbox-wrapper {
        padding: 10px;
      }

      .check-hint {
        margin: 10px 0 0 6px;
        font-size: 12px;
        line-height: 18px;
        height: 18px;
        width: 46px;
        text-align: center;
        border-radius: 8px;
        color: #fff;
        background-color: #999;
      }

      .item {
        flex: 1 1 auto;
        padding: 10px;
        word-break: break-word;
      }

      .img-box {
        width: 80px;
        height: 80px;
        text-align: center;
        line-height: 74px;
        border: 1px solid var(--border-deep);
        overflow: hidden;

        img {
          max-width: 100%;
          max-height: 100%;
        }
      }

      .tips {
        font-size: 12px;
        color: var(--font-tips);
        margin-top: 6px;
      }

      .price {
        color: var(--font-high);
      }

      .text-btn {
        border: 0 none;
        cursor: pointer;
        color: var(--font-title);
        background: transparent;
      }
    }
  }
}

.page-cart-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1200px;
  margin: 0 auto;
  min-height: 48px;
  max-height: 96px;
  padding: 0 0 0 24px;
  border-top: 1px solid var(--border-deep);
  border-bottom: 1px solid var(--border-deep);
  background: #FCFCFC;

  .opt-btn {
    font-size: 14px;
    color: var(--font-normal);
    margin: 0 16px 0 24px;
    background: transparent;
    border: 0 none;
    cursor: pointer;

    &:hover {
      color: var(--font-title);
    }
  }

  .btn {
    width: 114px;
    height: 48px;
    font-size: 16px;
    color: #fff;
    margin-left: 24px;
    border: 0 none;
    background: var(--color);

    &:hover,
    &:focus {
      cursor: pointer;
      background: var(--color-active);
    }

    &:disabled {
      background: var(--disabled);
      cursor: not-allowed;
    }
  }

  .text {
    margin-right: 24px;
    font-size: 14px;
    color: var(--font-normal);

    .hint {
      font-size: 18px;
      font-weight: 700;
      color: var(--font-high);
      margin: 0 6px;
    }
  }

}

.page-crumb {
  line-height: 58px;
  padding: 0 24px;
  font-size: 14px;
  border-bottom: 1px solid var(--border-deep);
  color: var(--font-title);

  .iconfont {
    font-size: 12px;
  }
}

.order-create-wrap {
  padding: 0 14px;


  .cart-table {
    .cart-table-head .item {
      text-align: center;

      &:nth-child(2), &:nth-child(3) {
        text-align: left;
      }
    }

    .cart-table-body-tr .tr-body {
      .item {
        text-align: center;

        &:nth-child(2), &:nth-child(3) {
          text-align: left;
        }
      }

      .img-box {
        width: 64px;
        height: 64px;
        line-height: 58px;
        text-align: center;
      }
    }
  }
}

.create-order-headline {
  height: 50px;
  padding-top: 18px;
  line-height: 32px;
  margin-bottom: 16px;
  font-size: 14px;
  color: var(--font-title);
  border-bottom: 1px solid var(--border-deep);

  &:before {
    content: "";
    display: inline-block;
    width: 2px;
    height: 14px;
    margin-right: 6px;
    background: var(--font-title);
  }

  .txt-btn {
    float: right;
    height: 32px;
    font-size: 14px;
    border: 0 none;
    background: transparent;
    color: var(--color);
  }

  .btn {
    float: right;
    height: 32px;
    font-size: 14px;
    border: 0 none;
    background: transparent;
    margin-left: 10px;
  }
}

.create-order-expand {
  display: flex;
  padding: 16px 0 0;
  flex-wrap: wrap;

  .expand-item-card {
    flex: 1;
    max-width: 33.3333%;
    padding-right: 40px;
    margin-bottom: 8px;

    .title-box {
      font-size: 14px;
      color: var(--font-title);
      margin-bottom: 8px;
    }

    .main-box {
      .ant-radio-wrapper {
        display: block;
        margin-bottom: 8px;
      }
    }

    .tax-row {
      font-size: 14px;

      .btn {
        margin-left: 1em;
        color: #1A81E8;
        cursor: pointer;
      }
    }
  }

}

.create-order-total {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 20px;

  td:first-child {
    text-align: right;
    padding-right: 8px;
  }

  .price {
    font-size: 18px;
    color: var(--font-high);
  }

  .icon-shuoming {
    color: #F5A623;
    margin-right: 2px;
    font-size: 16px;
    cursor: pointer;
  }

  .select-tag {
    cursor: pointer;
    .tag {
      font-size: 12px;
      line-height: 16px;
      padding: 0 6px;
      border-radius: 2px;
      color: var(--font-high);
      border: 1px solid var(--color-hint);
    }
    .icon-jump {
      font-size: 12px;
      margin-left: 4px;
      color: var(--font-high);
    }
  }
}

.create-order-address {
  display: flex;
  flex: 0;
  flex-wrap: wrap;

  .ant-empty {
    width: 100%;
  }

  .address-card {
    position: relative;
    width: 350px;
    padding: 16px;
    margin: 0 24px 16px 0;
    font-size: 14px;
    border: 1px solid var(--border-deep);
    border-radius: 4px;
    cursor: pointer;

    .icon-selected {
      position: absolute;
      right: -1px;
      bottom: -1px;
      line-height: 1;
      font-size: 18px;
      text-align: right;
      visibility: hidden;
      color: var(--color);
    }

    &.address-card-selected {
      border: 2px solid var(--color);

      .icon-selected {
        visibility: visible;
      }
    }


    .head {
      line-height: 38px;
      margin-bottom: 12px;
      color: var(--font-title);
      border-bottom: 1px solid var(--border-deep);

      .tips {
        float: right;
      }
    }

    .body {
      color: var(--font-normal);
      line-height: 22px;
    }

    .operate {
      position: absolute;
      right: 16px;
      bottom: 16px;
      font-size: 14px;
      color: var(--font-tips);

      .btn {
        &:hover {
          color: var(--color);
        }
      }
    }
  }

}

.create-order-upload {
  margin-top: 10px;

  .headline {
    font-size: 14px;
    margin-bottom: 8px;
    color: var(--font-tips);
  }

  .image-upload-btn {
    width: 80px;
    height: 80px;
    padding-bottom: 0;
  }
}

.order-promotion-box {
  padding: 15px 16px;
  border: 1px solid var(--border-deep);
  margin: 12px 0;
  font-size: 14px;

  .tag {
    display: inline-block;
    vertical-align: middle;
    margin-right: 4px;
    padding: 0 8px;
    height: 18px;
    font-size: 12px;
    line-height: 16px;
    border: 1px solid #FF6350;
    color: #FF6350;
    border-radius: 4px;
  }

  .text {
    display: inline-block;
    vertical-align: middle;
    margin-right: 4px;
    font-size: 14px;
    color: var(--font-title);
  }

  .tips {
    display: inline-block;
    vertical-align: middle;
    margin-right: 4px;
    color: #FF6350;
  }

  .btn {
    display: inline-block;
    vertical-align: middle;
    margin-right: 4px;
    padding: 0 8px;
    height: 20px;
    font-size: 12px;
    line-height: 18px;
    border: 1px solid var(--border-deep);
    color: var(--font-normal);
    border-radius: 4px;
    cursor: pointer;

    .iconfont {
      font-size: 12px;
      margin-left: 4px;
    }
  }


}

.present-popover-box {
  .ant-popover-inner-content {
    padding: 0;
  }
}

.present-info-wrap {
  width: 240px;
  height: 300px;
  overflow: auto;
  padding: 12px;

  .present-card {
    width: 100%;
    display: flex;
    align-items: center;
    align-content: center;
    font-size: 12px;
    margin: 12px 0;
    cursor: pointer;

    &:hover {
      color: var(--color);
    }

    .img-box {
      width: 50px;
      height: 50px;
      text-align: center;
      line-height: 50px;
      margin-right: 12px;

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }

    .info-box {
      flex: 1;
      width: 0;

      .hint {
        color: var(--font-high);
      }
    }
  }
}

.goods-promotion-box {
  font-size: 14px;

  .tag {
    display: inline-block;
    vertical-align: middle;
    margin-right: 4px;
    padding: 0 8px;
    height: 18px;
    font-size: 12px;
    line-height: 18px;
    background: #FF6350;
    color: #fff;
    border-radius: 3px;
  }

  .text {
    display: inline-block;
    vertical-align: middle;
    margin-right: 4px;
    color: var(--font-title);
  }

  .tips {
    display: inline-block;
    vertical-align: middle;
    margin-right: 4px;
    color: #FF6350;
  }

  .btn {
    display: inline-block;
    vertical-align: middle;
    color: #3399FF;
    cursor: pointer;
  }
}


.order-promotion-present-box {
  .cart-table-body-tr {
    border-bottom: 0 none;

    .tr-body {
      .item {
        padding: 0 10px;

        .img-box {
          display: inline-block;
          width: 40px;
          height: 40px;
          text-align: center;
          line-height: 32px;
        }
      }
    }
  }
}

.cart-free-goods-box {
  margin-top: 12px;
  border: 1px solid var(--border-deep);

  .headline {
    padding: 0 16px;
    line-height: 46px;
    color: var(--font-title);

    .tag {
      display: inline-block;
      line-height: 18px;
      padding: 0 12px;
      margin-right: 8px;
      border: 1px solid var(--color-hint);
      color: var(--color-hint);
      border-radius: 4px;
    }

    .btn {
      border: 0 none;
      margin-left: 16px;
      background-color: transparent;
      color: #3399FF;

      .iconfont {
        font-size: 12px;
      }
    }
  }

  .cart-table-body {
    margin: 0 16px;
    border-top: 1px solid var(--border-deep);

    .cart-table-body-tr {
      border: 0 none;

      .img-box {
        width: 40px;
        height: 40px;
        line-height: 38px;

        img {
          vert-align: top;
        }
      }
    }
  }

}

.cart-free-goods-list {
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  margin: -24px;
  padding: 24px;
  max-height: 440px;
  overflow: auto;

  .free-goods-item {
    width: 50%;
    display: flex;
    align-items: center;
    font-size: 14px;

    &:nth-child(2n+1) {
      padding-right: 8px;
    }

    &:nth-child(2n) {
      padding-left: 8px;
    }

    .img-box {
      width: 80px;
      height: 80px;
      flex: 0 0 auto;
      margin: 0 16px 0 8px;
    }

    .cont-box {
      .name {
        color: var(--font-title);
      }

      .format {
        color: var(--font-tips);
      }

      del {
        padding-left: 4px;
        color: var(--font-tips);
      }
    }


  }
}

.shop-cart-goods-promotion {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  padding: 0 12px;
  .tag {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    vertical-align: middle;
    font-size: 10px;
    color: #fff;
    background: #ff3838;
    border-radius: 3px;
    padding: 0 10px;
    width: 50px;
    height: 16px;
    margin-right: 8px;
    i {
      flex: 1;
      text-align: center;
    }
  }
  .txt {
    font-size: 12px;
    color: var(--font-normal);
    vertical-align: middle;
    .text {
      margin-right: 10px;
    }
    .tips {
      color: #3399FF;
    }
  }

  .txt-meet {
    color: var(--font-title) !important;
  }
}

.goods-package-tag {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  vertical-align: middle;
  font-size: 10px;
  color: #fff;
  background: #ff3838;
  border-radius: 3px;
  padding: 0 10px;
  width: 50px;
  height: 16px;
  margin-right: 8px;
  i {
    flex: 1;
    text-align: center;
  }
}

