@charset "UTF-8";
/*定义公共部分通用css，默认所有页面都需要加载 2016/08/17*/
* {
  box-sizing: border-box; }

html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figure,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: baseline;
  background: transparent; }

html {
  height: 100%;
  overflow: hidden; }

body {
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -webkit-overflow-scrolling: touch;
  background: #f5f5f5; }

input,
select,
textarea,
button {
  font-family: "Arial,Helvetica,Microsoft YaHei"; }

select,
input,
button {
  -webkit-appearance: none;
  vertical-align: middle;
  outline: none; }

h1,
h2,
h3,
h4,
h5,
h6,
i,
b {
  font-weight: normal; }

i {
  font-style: normal; }

ul,
ol,
li {
  list-style-type: none; }

img {
  border: 0;
  vertical-align: middle;
  max-width: 100%; }

img:not([src]) {
  opacity: 0; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

a:link,
a:visited {
  color: inherit;
  text-decoration: none; }

a:hover,
a:active {
  color: inherit;
  text-decoration: none; }

/* 取消苹果圆角 */
input,
button,
textarea {
  -webkit-tap-highlight-color: transparent;
  -webkit-appearance: none;
  border-radius: 0; }

input {
  font-size: 14px; }

button {
  padding: 0; }

p {
  padding: 0;
  margin: 0;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0; }

input[disabled],
textarea[diabled] {
  color: var(--font-normal);
  -webkit-text-fill-color: var(--font-normal);
  opacity: 1; }

/* placeholder  */
:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: var(--disabled); }

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: var(--disabled); }

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: var(--disabled); }

input[disabled]::-webkit-input-placeholder,
textarea[diabled]::-webkit-input-placeholder {
  color: var(--disabled);
  -webkit-text-fill-color: var(--disabled); }

button,
input[type='button'] {
  cursor: pointer; }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0; }

input[type="number"] {
  -moz-appearance: textfield; }

input[readonly] {
  -webkit-user-select: none; }

.h {
  display: none;
  visibility: visible; }

.fix {
  *zoom: 1; }

.fix:after {
  clear: both;
  content: "";
  display: table; }

.fl {
  float: left; }

.fr {
  float: right; }

.pos-r {
  position: relative; }

.pos-a {
  position: absolute; }

.pos-f {
  position: fixed; }

.tc {
  text-align: center !important; }

.tl {
  text-align: left !important; }

.tr {
  text-align: right !important; }

.cur {
  cursor: pointer; }

.align-center {
  align-content: center;
  align-items: center;
  justify-content: center; }

.dis-show {
  display: block !important; }

.disShow {
  display: inline-block !important; }

.dis-flex {
  display: flex !important; }

.dis-hide {
  display: none !important; }

.dis-inb {
  display: inline-block !important; }

.dis-b {
  display: block; }

.va-t {
  vertical-align: top; }

.va-m {
  vertical-align: middle; }

.va-b {
  vertical-align: bottom; }

.m0 {
  margin: 0 !important; }

.mt0 {
  margin-top: 0 !important; }

.ml0 {
  margin-left: 0 !important; }

.ml12 {
  margin-left: 12px; }

.mr0 {
  margin-right: 0 !important; }

.mr24 {
  margin-right: 24px !important; }

.mb0 {
  margin-bottom: 0 !important; }

.mb8 {
  margin-bottom: 8px; }

.mt300 {
  margin-top: 300px !important; }

.p0 {
  padding: 0 !important; }

.pt0 {
  padding-top: 0 !important; }

.pl0 {
  padding-left: 0 !important; }

.pr0 {
  padding-right: 0 !important; }

.plr12 {
  padding: 0 12px; }

.pb0 {
  padding-bottom: 0 !important; }

.txt-hint {
  color: var(--color-hint); }

.txt-red {
  color: var(--orangey-red) !important; }

.txt-green {
  color: var(--font-green) !important; }

.txt-tips {
  color: var(--font-tips) !important; }

.txt-title {
  color: var(--font-title) !important; }

.txt-main-color {
  color: var(--color) !important; }

.txt-hover {
  cursor: pointer; }
  .txt-hover:hover {
    color: var(--color) !important; }

.bg-white {
  background: #fff !important; }

.flex {
  display: -webkit-flex;
  display: -webkit-box;
  display: flex; }

.flex-children {
  flex: 1; }

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.ellipsis-2 {
  display: -webkit-flex;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal !important;
  word-wrap: break-word;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-all;
  white-space: normal; }

.ellipsis-3 {
  display: -webkit-flex;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal !important;
  word-wrap: break-word;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  word-break: break-all;
  white-space: normal; }

.bl {
  border-left: 1px solid var(--border-deep); }

.br {
  border-right: 1px solid var(--border-deep); }

.bt {
  border-top: 1px solid var(--border-deep); }

.bb {
  border-bottom: 1px solid var(--border-deep); }

.b0 {
  border: 0 none; }

.mask, .mask-son, .mask-pop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: -1px;
  right: 0;
  background: rgba(26, 26, 26, 0.3); }

.mask {
  z-index: 3; }

.mask-son {
  z-index: 5; }

.mask-pop {
  z-index: 7; }

.page-wrap {
  max-width: 1200px;
  margin: 0 auto; }

.page-box-wrap {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column; }

.page-body {
  flex: 1;
  width: 1200px;
  margin: 0 auto;
  display: flex; }
  .page-body .page-main {
    flex: 1;
    width: 0; }

.page-max {
  width: 100vw; }

.bg-white {
  background: #fff; }

.login-modal {
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 5;
  width: 304px;
  transform: translate(-50%, -50%); }
  .login-modal .login-modal-title {
    width: 304px;
    height: 48px;
    line-height: 48px;
    font-size: 18px;
    text-align: center;
    background: #fff;
    color: var(--font-title);
    border-radius: 4px 4px 0 0;
    border-bottom: 1px solid var(--border-deep); }
  .login-modal .login-modal-body {
    padding: 32px 0 32px;
    text-align: center;
    background: #fff; }
    .login-modal .login-modal-body .btn {
      width: 240px;
      height: 50px;
      line-height: 1;
      margin-bottom: 16px;
      border-radius: 50px;
      font-size: 18px;
      color: var(--font-normal);
      border: 1px solid var(--border-deep); }
    .login-modal .login-modal-body .btn-confirm {
      background: var(--color);
      color: #fff; }
      .login-modal .login-modal-body .btn-confirm:hover {
        background: var(--color-active); }
  .login-modal .close {
    position: absolute;
    top: 12px;
    right: 12px;
    width: 22px;
    height: 22px;
    cursor: pointer; }
    .login-modal .close:after, .login-modal .close:before {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
      background: #999; }
    .login-modal .close:hover:after, .login-modal .close:hover:before {
      background: #666; }
    .login-modal .close:after {
      width: 1px;
      height: 14px; }
    .login-modal .close:before {
      width: 14px;
      height: 1px; }

.null-list-box {
  text-align: center;
  padding-top: 250px;
  padding-bottom: 1em; }
  .null-list-box img {
    width: 200px;
    height: 162px; }
  .null-list-box .null-tips {
    font-size: 14px;
    color: var(--font-tips); }

.inquiry-model {
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 5;
  width: 300px;
  min-height: 200px;
  border-radius: 6px;
  transform: translate(-50%, -50%);
  background: #fff;
  overflow: hidden;
  padding-bottom: 10px; }
  .inquiry-model .close {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 16px;
    height: 16px;
    cursor: pointer; }
    .inquiry-model .close:hover {
      opacity: .8; }
    .inquiry-model .close:after, .inquiry-model .close:before {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
      background: var(--font-tips); }
    .inquiry-model .close:after {
      width: 1px;
      height: 16px; }
    .inquiry-model .close:before {
      width: 16px;
      height: 1px; }
  .inquiry-model .inquiry-model-title {
    padding: 32px 24px;
    font-size: 16px;
    text-align: center;
    background: #fff;
    color: var(--font-title);
    font-weight: bold;
    word-break: break-all; }
  .inquiry-model .btn {
    display: block;
    width: 200px;
    height: 40px;
    margin: 0 auto 16px;
    background: var(--border-light);
    border: 1px solid var(--border-deep);
    color: var(--font-title);
    border-radius: 50px;
    cursor: pointer; }
    .inquiry-model .btn:hover {
      opacity: .8; }

.page-custom-modal .ant-modal-header {
  padding: 14px 24px;
  text-align: center;
  background: #E3E3E3; }

.page-custom-modal .ant-modal-title {
  font-size: 18px;
  color: var(--font-title); }

.page-custom-modal .ant-modal-close-x {
  width: 48px;
  height: 48px; }

.model-height-450 .ant-modal-content .ant-modal-body {
  height: 450px;
  overflow: auto; }

.model-height-573 .ant-modal-content .ant-modal-body {
  height: 573px;
  overflow: auto; }

.null-tips-box {
  text-align: center;
  padding-top: 100px; }
  .null-tips-box .null-icon {
    display: inline-block; }
    .null-tips-box .null-icon .svg-icon {
      width: 90px;
      height: 90px; }
  .null-tips-box .txt {
    font-size: 14px;
    color: var(--font-normal); }

.user-card {
  display: flex;
  align-content: center;
  align-items: center; }
  .user-card .head-img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 8px; }
    .user-card .head-img img, .user-card .head-img .svg-icon {
      width: 100%;
      height: 100%; }
  .user-card .main-box {
    flex: 1;
    font-size: 12px;
    line-height: 1.2; }
    .user-card .main-box .text-title {
      color: var(--font-title); }
    .user-card .main-box .text-normal {
      margin-top: 4px;
      color: var(--font-normal); }
    .user-card .main-box .text-tips {
      margin-top: 4px;
      color: var(--font-tips); }

.statistic-box-card {
  display: flex; }
  .statistic-box-card.statistic-box-card-divider .statistic-item-card:first-child:before {
    display: none; }
  .statistic-box-card.statistic-box-card-divider .statistic-item-card:before {
    content: "";
    width: 1px;
    height: 24px;
    background: #dcdcdc;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%); }
  .statistic-box-card .statistic-item-card {
    flex: 1;
    width: 0;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    text-align: center;
    position: relative; }
    .statistic-box-card .statistic-item-card .statistic-title {
      font-size: 12px;
      color: var(--font-tips);
      margin-top: 4px; }
    .statistic-box-card .statistic-item-card .statistic-main {
      font-size: 20px;
      color: var(--font-title);
      font-weight: bold; }
    .statistic-box-card .statistic-item-card .txt-hint {
      color: var(--font-high); }

.cascader-box {
  width: 100%; }

.cont-table table {
  width: 100%;
  border: 1px solid var(--border-deep);
  table-layout: fixed; }

.cont-table th {
  height: 40px;
  vertical-align: middle;
  background: #f5f5f5;
  border-bottom: 1px solid var(--border-deep);
  font-size: 14px;
  color: var(--font-normal);
  padding-left: 14px; }

.cont-table tr:last-child {
  border-bottom: 0 none; }

.cont-table td {
  font-size: 14px;
  color: var(--font-normal);
  padding-left: 14px;
  height: 40px;
  vertical-align: middle;
  border-bottom: 1px solid var(--border-deep); }
  .cont-table td .info-box {
    padding: 8px 0;
    font-size: 14px; }
    .cont-table td .info-box .title {
      color: var(--font-title);
      margin-bottom: 4px; }
    .cont-table td .info-box .tips {
      color: var(--font-tips); }
  .cont-table td .txt {
    display: inline-block;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; }
  .cont-table td .img-box {
    display: inline-block;
    vertical-align: top;
    width: 60px;
    height: 60px;
    border: 1px solid var(--border-deep);
    text-align: center;
    line-height: 60px;
    margin: 8px 8px 8px 0; }
    .cont-table td .img-box img {
      max-width: 100%;
      max-height: 100%; }
  .cont-table td .ope {
    width: auto;
    display: inline-block;
    margin-right: 8px;
    cursor: pointer; }
    .cont-table td .ope:hover {
      color: var(--color); }

.page-confirm-pay-modal .ant-modal-content {
  position: relative; }
  .page-confirm-pay-modal .ant-modal-content .ant-modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 430px; }
    .page-confirm-pay-modal .ant-modal-content .ant-modal-body .img-box {
      width: 454px;
      height: 318px;
      margin-bottom: 20px; }
      .page-confirm-pay-modal .ant-modal-content .ant-modal-body .img-box .close {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        position: absolute;
        right: 0;
        top: 0;
        width: 50px;
        height: 50px;
        font-size: 32px;
        transform: rotate(45deg); }
        .page-confirm-pay-modal .ant-modal-content .ant-modal-body .img-box .close:hover {
          cursor: pointer; }
      .page-confirm-pay-modal .ant-modal-content .ant-modal-body .img-box img {
        width: 100%;
        height: 100%; }
    .page-confirm-pay-modal .ant-modal-content .ant-modal-body .confirm-txt {
      text-align: center;
      font-size: 18px;
      font-weight: 700;
      color: #333; }
  .page-confirm-pay-modal .ant-modal-content .ant-modal-footer {
    display: flex;
    justify-content: center;
    padding: 30px 16px; }
    .page-confirm-pay-modal .ant-modal-content .ant-modal-footer button {
      width: 200px;
      height: 40px; }
  .page-confirm-pay-modal .ant-modal-content .ant-modal-footer button + button {
    margin-left: 30px; }

:root {
  --color: #DC3848;
  --color-active: rgba(231, 47, 62, 0.8);
  --color-hover: rgba(231, 47, 62, .1);
  --color-hint: #FF3838;
  --border-deep: #DDDDDD;
  --border-middle: #EEEEEE;
  --border-light: #fcfcfc;
  --font-title: #333;
  --font-normal: #666;
  --font-tips: #999;
  --font-high: #FF3838;
  --disabled: #b3b3b3;
  --bg-color: #fDDDDDD;
  --btn-color: #ffb919;
  --orangey-red: #ff3838;
  --orange: #ffb67a;
  --orange-hover: #cc9e38;
  --orange-red: rgba(255, 99, 80, 0.9);
  --font-green: #4db814; }

.theme-dc3848 {
  --color: #DC3848;
  --color-active: rgba(231, 47, 62, 0.8);
  --color-hover: rgba(231, 47, 62, .1); }

.theme-eb382f {
  --color: #eb382f;
  --color-active: #d1322a;
  --color-hover: rgba(235, 56, 47, .1); }

.theme-fb6321 {
  --color: #fb6321;
  --color-active: #fc824d;
  --color-hover: rgba(251, 99, 33, .1); }

.theme-b25724 {
  --color: #b25724;
  --color-active: #994b1f;
  --color-hover: rgba(178, 87, 36, .1); }

.theme-dbaf58 {
  --color: #dbaf58;
  --color-active: #c29b4e;
  --color-hover: rgba(219, 175, 88, .1); }

.theme-fab414 {
  --color: #fab414;
  --color-active: #e0a212;
  --color-hover: rgba(250, 180, 20, .1); }

.theme-23982d {
  --color: #23982d;
  --color-active: #4fac57;
  --color-hover: rgba(35, 152, 45, .1); }

.theme-f566d3 {
  --color: #f566d3;
  --color-active: #db5cbe;
  --color-hover: rgba(245, 102, 211, .1); }

.theme-c38cfa {
  --color: #c38cfa;
  --color-active: #b07ee0;
  --color-hover: rgba(195, 140, 250, .1); }

.theme-1c69e6 {
  --color: #1c69e6;
  --color-active: #185dcc;
  --color-hover: rgba(28, 105, 230, .1); }

.theme-20ade5 {
  --color: #20ade5;
  --color-active: #4dbdea;
  --color-hover: rgba(32, 173, 229, .1); }

.theme-0ec2b9 {
  --color: #0ec2b9;
  --color-active: #10E1D7;
  --color-hover: rgba(14, 194, 185, .1); }

.theme-1b1b1b {
  --color: #1b1b1b;
  --color-active: #494949;
  --color-hover: rgba(40, 40, 40, .1); }

.theme-2d6285 {
  --color: #2d6285;
  --color-active: #285473;
  --color-hover: rgba(45, 98, 133, .1); }

.page-reset p {
  margin: 0; }

.page-reset .ant-spin {
  color: var(--color); }

.page-reset .ant-spin-dot-item {
  background-color: var(--color); }

.page-reset .ant-pagination-item:hover, .page-reset .ant-pagination-item:focus {
  border-color: var(--color); }
  .page-reset .ant-pagination-item:hover a, .page-reset .ant-pagination-item:focus a {
    color: var(--color); }

.page-reset .ant-pagination-item-active {
  border-color: var(--color); }
  .page-reset .ant-pagination-item-active a {
    color: var(--color); }

.page-reset .ant-pagination-prev:focus a, .page-reset .ant-pagination-prev:hover a,
.page-reset .ant-pagination-next:focus a,
.page-reset .ant-pagination-next:hover a {
  color: var(--color); }

.page-reset .ant-pagination-prev:focus .ant-pagination-item-link, .page-reset .ant-pagination-prev:hover .ant-pagination-item-link,
.page-reset .ant-pagination-next:focus .ant-pagination-item-link,
.page-reset .ant-pagination-next:hover .ant-pagination-item-link {
  color: var(--color);
  border-color: var(--color); }

.page-reset .ant-select-selection:focus, .page-reset .ant-select-selection:hover {
  border-color: var(--color); }

.page-reset .ant-input:hover {
  border-color: var(--color); }

.page-reset .ant-input:focus {
  border-color: var(--color);
  box-shadow: 0 0 0 2px var(--color-hover); }

.page-reset .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-color: var(--color); }

.page-reset .ant-btn-primary {
  background-color: var(--color);
  border-color: var(--color);
  color: #fff; }
  .page-reset .ant-btn-primary:hover, .page-reset .ant-btn-primary:focus {
    background-color: var(--color-active);
    border-color: var(--color-active); }

.page-reset .ant-btn-background-ghost.ant-btn-primary {
  color: var(--color);
  background-color: transparent;
  border-color: var(--color); }
  .page-reset .ant-btn-background-ghost.ant-btn-primary:hover, .page-reset .ant-btn-background-ghost.ant-btn-primary:focus {
    color: var(--color-active);
    background-color: var(--color-active);
    border-color: var(--color-active); }

.page-reset .ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: var(--color-hover); }

.page-reset .ant-select-open .ant-select-selection {
  border-color: var(--color);
  box-shadow: 0 0 0 2px var(--color-hover); }

.page-reset .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
  background-color: var(--color-hover); }

.page-reset .ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled) {
  border-color: var(--color); }

.page-reset .ant-calendar-range .ant-calendar-selected-start-date .ant-calendar-date, .page-reset .ant-calendar-range .ant-calendar-selected-end-date .ant-calendar-date {
  background-color: var(--color); }

.page-reset .ant-calendar-date:hover {
  background: var(--color-hover); }

.page-reset .ant-calendar-range .ant-calendar-in-range-cell::before {
  background-color: var(--color-hover); }

.page-reset .ant-calendar-today .ant-calendar-date {
  color: var(--color);
  border-color: var(--color); }

.page-reset .ant-calendar-range .ant-calendar-selected-start-date .ant-calendar-date:hover, .page-reset .ant-calendar-range .ant-calendar-selected-end-date .ant-calendar-date:hover {
  background: var(--color); }

.page-reset .ant-calendar-range .ant-calendar-selected-start-date .ant-calendar-date, .page-reset .ant-calendar-range .ant-calendar-selected-end-date .ant-calendar-date {
  background: var(--color); }

.page-reset .ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month {
  color: #fff;
  background: var(--color); }

.page-reset .ant-calendar-header a:hover {
  color: var(--color); }

.page-reset .ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month:hover {
  color: #fff;
  background: var(--color); }

.page-reset .ant-calendar-month-panel-month:hover {
  background: var(--color-hover); }

.page-reset .ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--color);
  border-color: var(--color); }

.page-reset .ant-checkbox-checked::after {
  border-color: var(--color); }

.page-reset .ant-checkbox-wrapper:hover .ant-checkbox-inner, .page-reset .ant-checkbox:hover .ant-checkbox-inner, .page-reset .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: var(--color); }

.page-reset .ant-typography a {
  color: inherit; }

.page-reset .ant-typography a:focus, .page-reset .ant-typography a:hover {
  color: var(--color); }

.page-reset .ant-radio-checked .ant-radio-inner {
  border-color: var(--color); }

.page-reset .ant-radio-checked::after {
  border: 1px solid var(--color); }

.page-reset .ant-radio-wrapper:hover .ant-radio, .page-reset .ant-radio:hover .ant-radio-inner, .page-reset .ant-radio-input:focus + .ant-radio-inner {
  border-color: var(--color-active); }

.page-reset .ant-radio-inner::after {
  background-color: var(--color); }

.page-reset textarea.ant-input {
  resize: none; }

.page-reset .ant-radio-button-wrapper:hover {
  color: var(--color-active); }

.page-reset .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
  border-color: var(--color); }

.page-reset .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: var(--color);
  border-color: var(--color); }

.page-reset .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: var(--color-active);
  border-color: var(--color-active); }

.page-reset .ant-input-affix-wrapper:hover {
  border-color: var(--color);
  border-right-width: 1px !important; }

.page-reset .ant-modal-title {
  text-align: center; }

.page-reset .confirm-btn {
  width: 120px;
  height: 42px;
  text-align: center; }

.page-reset .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before,
.page-reset .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before,
.page-reset .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active::before {
  background-color: var(--color); }

.page-reset .ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: var(--color); }

.page-reset .ant-pagination-next:focus .ant-pagination-item-link {
  background-color: #fff;
  border-color: #d9d9d9;
  color: rgba(0, 0, 0, 0.65); }

.page-reset .ant-tabs-nav .ant-tabs-tab:hover {
  color: var(--color); }

.page-reset .ant-tabs-nav .ant-tabs-tab-active {
  color: var(--color); }

.page-reset .ant-tabs-ink-bar {
  background: var(--color); }

.page-reset .ant-carousel .slick-dots {
  z-index: 2; }

.page-reset .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
  border-right-color: var(--color); }

.floor-middle-box {
  max-width: 1200px;
  margin: 0 auto; }

.floor-grail-layout {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between; }
  .floor-grail-layout .layout-little-box {
    width: 200px; }
  .floor-grail-layout .layout-large-box {
    width: 990px; }

.switcher-box {
  position: fixed;
  left: 50%;
  bottom: 12px;
  width: 540px;
  height: 60px;
  z-index: 3;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  background: rgba(104, 104, 104, 0.86);
  box-shadow: 0 0 8px 0 rgba(123, 123, 123, 0.5);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  padding-left: 44px;
  color: #fff;
  border-radius: 100px; }
  .switcher-box .close-btn {
    position: absolute;
    left: 24px;
    cursor: pointer; }
  .switcher-box h2 {
    font-size: 16px;
    color: #fff;
    margin-bottom: 0;
    padding-left: 12px; }
  .switcher-box .btn-box {
    font-size: 0;
    flex: 1;
    text-align: right; }
  .switcher-box .switch-btn {
    width: 140px;
    height: 40px;
    line-height: 40px;
    background: #6ecefc;
    border-radius: 100px;
    font-size: 14px;
    color: #fff;
    text-align: center;
    border: 0 none;
    margin-right: 24px;
    cursor: pointer; }
    .switcher-box .switch-btn:nth-child(2) {
      background: #ff6d6d;
      margin-right: 10px; }
    .switcher-box .switch-btn .iconfont {
      font-size: 14px;
      padding-right: 8px; }

.switcher-right-box {
  position: fixed;
  right: 0;
  bottom: 36px;
  z-index: 3;
  background: rgba(104, 104, 104, 0.86);
  box-shadow: 0 0 8px 0 rgba(123, 123, 123, 0.5);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  color: #fff;
  padding: 16px 12px 16px 14px;
  border-radius: 26px 0 0 26px; }
  .switcher-right-box .close-btn {
    display: none; }
  .switcher-right-box h2 {
    display: none; }
  .switcher-right-box .btn-box {
    font-size: 0;
    text-align: right; }
  .switcher-right-box .switch-btn {
    position: relative;
    display: block;
    width: 32px;
    height: 32px;
    line-height: 32px;
    background: #6ecefc;
    border-radius: 50%;
    font-size: 0;
    color: #fff;
    text-align: center;
    border: 0 none;
    cursor: pointer;
    margin-bottom: 18px; }
    .switcher-right-box .switch-btn:after {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      background: #A1A1A1;
      position: absolute;
      left: 0;
      bottom: -7px; }
    .switcher-right-box .switch-btn:nth-child(1) {
      background: rgba(0, 0, 0, 0.2); }
    .switcher-right-box .switch-btn:nth-child(3) {
      background: #ff6d6d;
      margin-bottom: 0; }
      .switcher-right-box .switch-btn:nth-child(3):after {
        display: none; }
    .switcher-right-box .switch-btn .iconfont {
      font-size: 14px;
      line-height: 32px; }

.pop-experience-tips {
  text-align: left; }
  .pop-experience-tips p {
    font-size: 14px;
    color: var(--font-normal);
    line-height: 1.5;
    margin-bottom: 10px; }
    .pop-experience-tips p .iconfont {
      margin-right: 6px; }
  .pop-experience-tips .btn {
    width: 100%;
    height: 36px;
    line-height: 36px;
    margin-top: 10px;
    display: inline-block;
    vertical-align: middle;
    padding: 0 18px;
    background-color: var(--color);
    color: #fff;
    white-space: nowrap;
    text-align: center;
    font-size: 14px;
    border: none;
    border-radius: 2px;
    cursor: pointer;
    opacity: 0.9;
    filter: alpha(opacity=90);
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none; }
    .pop-experience-tips .btn:hover {
      opacity: 0.7;
      filter: alpha(opacity=70); }

.inquire-page-box {
  height: 100%;
  background: #fff;
  padding: 16px 16px 80px;
  position: relative; }
  .inquire-page-box .ant-spin-nested-loading > div > .ant-spin {
    top: 50%;
    transform: translateY(-50%); }

.spin-box {
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.1);
  z-index: 6;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center; }

.inquire-head-wrap {
  display: flex;
  justify-content: space-between; }
  .inquire-head-wrap .search-wrap {
    width: 578px;
    border: 1px solid var(--color);
    position: relative; }
    .inquire-head-wrap .search-wrap .search-box .txt {
      padding-right: 120px; }
      .inquire-head-wrap .search-wrap .search-box .txt .ant-input-suffix {
        right: 132px;
        z-index: 0;
        line-height: 38px; }
    .inquire-head-wrap .search-wrap .ant-input {
      border: 0 none;
      height: 38px; }
    .inquire-head-wrap .search-wrap .search-btn {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 120px;
      background: var(--color);
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: center;
      color: #fff;
      font-size: 16px;
      cursor: pointer;
      border: 0 none; }
      .inquire-head-wrap .search-wrap .search-btn:hover {
        opacity: .9; }
      .inquire-head-wrap .search-wrap .search-btn.search-btn-disabled {
        background: var(--disabled); }
      .inquire-head-wrap .search-wrap .search-btn i {
        font-size: 18px;
        margin-right: 4px; }
    .inquire-head-wrap .search-wrap .search-dropdown-box {
      position: absolute;
      top: 49px;
      left: 0;
      right: 0;
      height: 344px;
      background: #fff;
      box-shadow: 0 7px 8px 0 rgba(0, 0, 0, 0.1);
      border: 1px solid #f0f0f0;
      z-index: 1; }
      .inquire-head-wrap .search-wrap .search-dropdown-box .search-dropdown-wrap {
        padding: 16px; }
      .inquire-head-wrap .search-wrap .search-dropdown-box .log-item {
        font-size: 12px;
        cursor: pointer;
        padding: 12px 0;
        border-bottom: 1px solid var(--border-deep); }
        .inquire-head-wrap .search-wrap .search-dropdown-box .log-item:first-child {
          padding-top: 0; }
        .inquire-head-wrap .search-wrap .search-dropdown-box .log-item .tit {
          color: var(--font-title); }
        .inquire-head-wrap .search-wrap .search-dropdown-box .log-item .txt {
          color: var(--font-tips); }
        .inquire-head-wrap .search-wrap .search-dropdown-box .log-item .del:hover {
          color: var(--color); }
  .inquire-head-wrap .user-info {
    display: flex;
    align-content: center;
    align-items: center;
    font-size: 14px;
    color: var(--font-title);
    cursor: pointer; }
    .inquire-head-wrap .user-info .txt {
      margin-left: 6px; }
    .inquire-head-wrap .user-info .btn {
      margin-left: 6px;
      color: var(--color); }

.inquire-null-tips-box {
  width: 100%;
  text-align: center;
  padding-top: 80px; }

.car-info-box {
  display: flex;
  padding: 16px;
  background: #FAFAFA;
  margin-top: 16px;
  position: relative; }
  .car-info-box:before {
    content: "";
    display: inline-block;
    width: 16px;
    height: 16px;
    background: #fafafa;
    position: absolute;
    top: -8px;
    left: 40px;
    transform: rotate(45deg); }
  .car-info-box .car-img {
    width: 50px;
    height: 50px;
    background: #fff;
    text-align: center;
    line-height: 50px;
    margin-right: 16px; }
    .car-info-box .car-img img {
      max-width: 100%;
      max-height: 100%; }
  .car-info-box .car-info-wrap {
    flex: 1; }
    .car-info-box .car-info-wrap .title {
      font-size: 16px;
      color: var(--font-title);
      font-weight: bold;
      line-height: 1;
      margin-bottom: 8px; }
    .car-info-box .car-info-wrap .car-list {
      margin-bottom: 8px; }
      .car-info-box .car-info-wrap .car-list .car-item {
        display: inline-block;
        vertical-align: middle;
        min-width: 160px;
        padding: 0 1em;
        height: 24px;
        margin-right: 12px;
        text-align: center;
        line-height: 24px;
        border-radius: 2px;
        font-size: 12px;
        border: 1px solid var(--border-deep);
        color: var(--font-title);
        cursor: pointer; }
        .car-info-box .car-info-wrap .car-list .car-item:hover, .car-info-box .car-info-wrap .car-list .car-item.active {
          border-color: var(--color);
          color: var(--color); }

.inquire-body-wrap {
  min-height: 600px; }

.parts-box-wrap {
  margin-top: 32px; }
  .parts-box-wrap .title {
    padding-bottom: 6px;
    border-bottom: 1px solid var(--border-deep);
    position: relative;
    font-size: 14px;
    font-weight: bold;
    color: var(--font-title);
    display: flex;
    align-items: center; }
    .parts-box-wrap .title:before {
      content: "";
      display: inline-block;
      vertical-align: middle;
      width: 2px;
      height: 14px;
      background: var(--font-title);
      margin-right: 6px; }
  .parts-box-wrap .parts-box-main {
    padding-top: 16px; }
    .parts-box-wrap .parts-box-main .search-box-wrap .input-wrap {
      display: inline-block;
      vertical-align: middle;
      width: 342px;
      position: relative; }
      .parts-box-wrap .parts-box-main .search-box-wrap .input-wrap .input-area {
        display: flex; }
        .parts-box-wrap .parts-box-main .search-box-wrap .input-wrap .input-area .txt {
          flex: 1;
          height: 32px;
          border: 1px solid var(--border-deep);
          border-radius: 4px 0 0 4px;
          padding-left: 1em;
          font-size: 14px;
          color: var(--font-title); }
        .parts-box-wrap .parts-box-main .search-box-wrap .input-wrap .input-area .search-btn {
          display: inline-block;
          width: 52px;
          height: 32px;
          text-align: center;
          line-height: 32px;
          background: var(--color);
          color: #fff;
          font-size: 20px;
          cursor: pointer; }
          .parts-box-wrap .parts-box-main .search-box-wrap .input-wrap .input-area .search-btn:hover {
            opacity: .8; }
      .parts-box-wrap .parts-box-main .search-box-wrap .input-wrap .result-wrap {
        position: absolute;
        top: 32px;
        left: 0;
        right: 0;
        height: 343px;
        background: #fff;
        box-shadow: 0 7px 8px 0 rgba(0, 0, 0, 0.1);
        border: 1px solid #f0f0f0;
        padding: 0 14px; }
        .parts-box-wrap .parts-box-main .search-box-wrap .input-wrap .result-wrap .spin-box {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%); }
        .parts-box-wrap .parts-box-main .search-box-wrap .input-wrap .result-wrap li {
          font-size: 14px;
          color: var(--font-title);
          line-height: 32px;
          border-bottom: 1px solid var(--border-deep);
          cursor: pointer; }
          .parts-box-wrap .parts-box-main .search-box-wrap .input-wrap .result-wrap li:hover {
            color: var(--color); }
    .parts-box-wrap .parts-box-main .search-box-wrap .epc-btn {
      margin-left: 16px;
      display: inline-block;
      vertical-align: middle;
      font-size: 14px;
      color: var(--color);
      cursor: pointer; }
      .parts-box-wrap .parts-box-main .search-box-wrap .epc-btn .iconfont {
        font-size: 18px; }

.parts-result-box {
  margin-top: 24px; }
  .parts-result-box table {
    width: 100%;
    border: 1px solid var(--border-deep);
    table-layout: fixed;
    margin-bottom: 16px; }
  .parts-result-box th {
    height: 40px;
    line-height: 40px;
    background: #f5f5f5;
    font-weight: normal;
    padding: 0 16px;
    border-bottom: 1px solid var(--border-deep);
    color: var(--font-normal); }
    .parts-result-box th:nth-child(2), .parts-result-box th:nth-child(3) {
      width: 15%; }
    .parts-result-box th .price {
      color: var(--font-high); }
      .parts-result-box th .price:hover {
        color: var(--font-high); }
    .parts-result-box th .epc-btn {
      cursor: pointer;
      float: left; }
      .parts-result-box th .epc-btn .svg-icon {
        width: 30px;
        height: 30px; }
    .parts-result-box th span {
      display: inline-block;
      margin-left: 10px;
      cursor: pointer; }
      .parts-result-box th span:hover {
        color: var(--color); }
      .parts-result-box th span .iconfont {
        font-size: 12px; }
    .parts-result-box th:last-child {
      text-align: right; }
  .parts-result-box td {
    padding: 12px 16px;
    border-bottom: 1px solid var(--border-deep);
    color: var(--font-high); }
    .parts-result-box td p {
      margin-bottom: 0; }
    .parts-result-box td .tips {
      font-size: 14px;
      color: var(--font-tips); }
    .parts-result-box td .info-box .tit {
      font-size: 14px;
      color: var(--font-title);
      margin-bottom: 1em; }
    .parts-result-box td .info-box .tips {
      font-size: 14px;
      color: var(--font-tips); }
      .parts-result-box td .info-box .tips span {
        display: inline-block;
        vertical-align: top;
        margin-right: 1em; }
        .parts-result-box td .info-box .tips span i {
          color: var(--font-high); }
    .parts-result-box td .price {
      display: inline-block;
      margin-right: 8px;
      font-size: 16px; }
    .parts-result-box td .cost {
      font-size: 12px;
      color: var(--font-tips);
      text-decoration: line-through; }
    .parts-result-box td .cart {
      cursor: pointer; }
    .parts-result-box td:last-child {
      text-align: right; }

.ys-drawer-box .ant-drawer-content-wrapper:before {
  content: "·原厂数据·";
  display: inline-block;
  width: 144px;
  height: 54px;
  line-height: 54px;
  text-align: center;
  font-size: 18px;
  background: #fff;
  border-radius: 50px 0 0 50px;
  position: absolute;
  top: 0;
  left: -144px; }

.ys-drawer-box .ant-drawer-content-wrapper .ant-drawer-wrapper-body {
  display: flex;
  flex-direction: column;
  overflow: hidden; }
  .ys-drawer-box .ant-drawer-content-wrapper .ant-drawer-wrapper-body .ant-drawer-body {
    flex: 1;
    height: 0;
    padding: 0; }

.ys-drawer-box .epc-data-box {
  height: 100%; }
  .ys-drawer-box .epc-data-box .ant-layout {
    height: 100%;
    background: #fff;
    overflow: hidden;
    padding: 8px; }
    .ys-drawer-box .epc-data-box .ant-layout .ant-layout {
      padding: 0; }
  .ys-drawer-box .epc-data-box .ant-layout-sider {
    background: #f8f8f8;
    overflow: hidden;
    width: 160px; }
    .ys-drawer-box .epc-data-box .ant-layout-sider .label-box {
      height: 40px;
      line-height: 40px;
      padding: 0 12px;
      font-size: 12px;
      color: var(--font-title);
      cursor: pointer; }
      .ys-drawer-box .epc-data-box .ant-layout-sider .label-box:hover, .ys-drawer-box .epc-data-box .ant-layout-sider .label-box.active {
        background: var(--color);
        color: #fff; }
  .ys-drawer-box .epc-data-box .sider-body-box {
    height: 100%; }
  .ys-drawer-box .epc-data-box .ant-spin-nested-loading {
    height: 100%; }
    .ys-drawer-box .epc-data-box .ant-spin-nested-loading .ant-spin-container {
      height: 100%; }

.ys-drawer-box .structure-chart-box {
  padding: 16px 0 16px 16px;
  height: 100%; }

.ys-drawer-box .structure-chart-card {
  display: inline-block;
  vertical-align: top;
  width: 198px;
  margin-right: 16px;
  margin-bottom: 16px;
  box-shadow: 0 2px 8px 0 rgba(153, 153, 153, 0.2);
  background: #fff;
  cursor: pointer; }
  .ys-drawer-box .structure-chart-card:nth-child(3n) {
    margin-right: 0; }
  .ys-drawer-box .structure-chart-card .img-box {
    width: 100%;
    height: 198px;
    text-align: center;
    line-height: 198px; }
    .ys-drawer-box .structure-chart-card .img-box img {
      max-height: 100%;
      max-width: 100%; }
  .ys-drawer-box .structure-chart-card .title {
    height: 32px;
    line-height: 32px;
    font-size: 12px;
    color: var(--font-title);
    text-align: center; }

.ys-drawer-box .structure-chart-detail-box {
  display: flex;
  flex-direction: column;
  height: 100%; }
  .ys-drawer-box .structure-chart-detail-box .structure-chart-detail-head {
    padding: 8px; }
    .ys-drawer-box .structure-chart-detail-box .structure-chart-detail-head .ant-breadcrumb span:first-child .ant-breadcrumb-link {
      cursor: pointer; }
      .ys-drawer-box .structure-chart-detail-box .structure-chart-detail-head .ant-breadcrumb span:first-child .ant-breadcrumb-link:hover {
        color: var(--color); }
  .ys-drawer-box .structure-chart-detail-box .structure-chart-detail-body {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 0; }
    .ys-drawer-box .structure-chart-detail-box .structure-chart-detail-body .structure-chart-detail-img-box {
      width: 100%;
      text-align: center;
      position: relative; }
      .ys-drawer-box .structure-chart-detail-box .structure-chart-detail-body .structure-chart-detail-img-box .img-box {
        display: inline-block;
        width: 400px;
        height: 400px;
        line-height: 400px;
        text-align: center; }
        .ys-drawer-box .structure-chart-detail-box .structure-chart-detail-body .structure-chart-detail-img-box .img-box img {
          max-width: 100%;
          max-height: 100%; }
      .ys-drawer-box .structure-chart-detail-box .structure-chart-detail-body .structure-chart-detail-img-box .pagination-box {
        font-size: 12px;
        color: var(--font-normal);
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translateX(216px); }
        .ys-drawer-box .structure-chart-detail-box .structure-chart-detail-body .structure-chart-detail-img-box .pagination-box .txt {
          display: inline-block;
          vertical-align: middle;
          margin: 0 8px; }
        .ys-drawer-box .structure-chart-detail-box .structure-chart-detail-body .structure-chart-detail-img-box .pagination-box button {
          display: inline-block;
          vertical-align: middle;
          border: 0 none;
          width: auto;
          background: transparent;
          cursor: pointer; }
          .ys-drawer-box .structure-chart-detail-box .structure-chart-detail-body .structure-chart-detail-img-box .pagination-box button:hover {
            color: var(--color); }
    .ys-drawer-box .structure-chart-detail-box .structure-chart-detail-body .structure-chart-detail-title {
      font-size: 14px;
      color: var(--font-title);
      line-height: 1em;
      margin: 16px 0 8px; }
      .ys-drawer-box .structure-chart-detail-box .structure-chart-detail-body .structure-chart-detail-title:before {
        content: "";
        display: inline-block;
        margin: 0 4px 0 14px;
        width: 2px;
        height: 14px;
        background: var(--font-title); }
    .ys-drawer-box .structure-chart-detail-box .structure-chart-detail-body .structure-chart-detail-content {
      flex: 1;
      height: 0;
      position: relative; }
      .ys-drawer-box .structure-chart-detail-box .structure-chart-detail-body .structure-chart-detail-content .table-head {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        width: 100%;
        height: 40px;
        background: #fcfcfc;
        line-height: 40px;
        font-size: 14px;
        color: var(--font-normal);
        border-bottom: 1px solid var(--border-deep);
        z-index: 3; }
        .ys-drawer-box .structure-chart-detail-box .structure-chart-detail-body .structure-chart-detail-content .table-head .head-item {
          padding-left: 24px;
          width: calc(100% / 6); }
      .ys-drawer-box .structure-chart-detail-box .structure-chart-detail-body .structure-chart-detail-content .structure-chart-detail-list-box table {
        width: 100%;
        table-layout: fixed; }
        .ys-drawer-box .structure-chart-detail-box .structure-chart-detail-body .structure-chart-detail-content .structure-chart-detail-list-box table th {
          height: 40px;
          background: #fcfcfc;
          opacity: 0;
          width: calc(100% / 6); }
        .ys-drawer-box .structure-chart-detail-box .structure-chart-detail-body .structure-chart-detail-content .structure-chart-detail-list-box table td {
          height: 50px;
          padding-left: 24px;
          font-size: 14px;
          color: var(--font-title);
          vertical-align: middle;
          border-bottom: 1px solid var(--border-deep); }
          .ys-drawer-box .structure-chart-detail-box .structure-chart-detail-body .structure-chart-detail-content .structure-chart-detail-list-box table td .txt {
            display: inline-block;
            width: 100%; }
          .ys-drawer-box .structure-chart-detail-box .structure-chart-detail-body .structure-chart-detail-content .structure-chart-detail-list-box table td .btn {
            display: inline-block;
            width: 76px;
            height: 22px;
            border: 1px solid var(--color);
            background: #fff;
            text-align: center;
            line-height: 20px;
            font-size: 12px;
            color: var(--color);
            cursor: pointer; }
            .ys-drawer-box .structure-chart-detail-box .structure-chart-detail-body .structure-chart-detail-content .structure-chart-detail-list-box table td .btn:hover {
              opacity: .8; }
          .ys-drawer-box .structure-chart-detail-box .structure-chart-detail-body .structure-chart-detail-content .structure-chart-detail-list-box table td .active-btn {
            display: inline-block;
            width: 78px;
            height: 24px;
            background: var(--color);
            text-align: center;
            line-height: 24px;
            font-size: 12px;
            color: #fff;
            cursor: pointer; }
            .ys-drawer-box .structure-chart-detail-box .structure-chart-detail-body .structure-chart-detail-content .structure-chart-detail-list-box table td .active-btn:before {
              content: "已加入"; }
            .ys-drawer-box .structure-chart-detail-box .structure-chart-detail-body .structure-chart-detail-content .structure-chart-detail-list-box table td .active-btn:hover:before {
              content: "取消加入"; }
  .ys-drawer-box .structure-chart-detail-box .structure-chart-detail-footer {
    height: 50px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
    position: relative;
    padding-left: 24px;
    line-height: 50px; }
    .ys-drawer-box .structure-chart-detail-box .structure-chart-detail-footer .tips-box {
      display: inline-block;
      font-size: 14px;
      color: var(--font-title); }
    .ys-drawer-box .structure-chart-detail-box .structure-chart-detail-footer .btn {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      padding: 0 30px;
      background: var(--color);
      font-size: 16px;
      color: #fff;
      border: 0 none; }
      .ys-drawer-box .structure-chart-detail-box .structure-chart-detail-footer .btn:hover {
        opacity: .8; }

.epc-detail-box {
  width: 100%; }
  .epc-detail-box .img-box-wrap {
    width: 100%;
    text-align: center; }
    .epc-detail-box .img-box-wrap .img-box {
      display: inline-block;
      width: 400px;
      height: 400px;
      text-align: center;
      line-height: 400px;
      overflow: hidden; }
      .epc-detail-box .img-box-wrap .img-box img {
        max-width: 100%;
        max-height: 100%; }
  .epc-detail-box .info-box {
    margin-top: 10px; }
    .epc-detail-box .info-box .descriptions-box {
      border: 1px solid var(--border-deep); }
      .epc-detail-box .info-box .descriptions-box .descriptions-item-box {
        height: 32px;
        line-height: 32px;
        border-bottom: 1px solid var(--border-deep);
        margin: 0;
        padding: 0; }
        .epc-detail-box .info-box .descriptions-box .descriptions-item-box:last-child {
          border-bottom: 0 none; }
        .epc-detail-box .info-box .descriptions-box .descriptions-item-box .descriptions-title {
          width: 90px;
          border-right: 1px solid var(--border-deep);
          margin: 0;
          padding-left: 1em; }
        .epc-detail-box .info-box .descriptions-box .descriptions-item-box .descriptions-main {
          padding-left: 1em; }

.inquire-foot-wrap {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 60px;
  border-top: 1px solid #f2f2f2;
  background: #fcfcfc; }
  .inquire-foot-wrap .btn-group {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%; }
    .inquire-foot-wrap .btn-group .btn {
      border: 0 none;
      width: 150px;
      height: 100%;
      line-height: 60px;
      text-align: center;
      font-size: 16px;
      color: #fff;
      background: var(--color); }
      .inquire-foot-wrap .btn-group .btn[disabled] {
        background: var(--disabled);
        color: #fff; }
      .inquire-foot-wrap .btn-group .btn.minor {
        color: var(--color);
        background: var(--color-hover); }
      .inquire-foot-wrap .btn-group .btn:hover {
        opacity: .8; }

.inquiry-list-page-box {
  height: 100%;
  background: #fff;
  margin-top: 14px; }

.inquiry-list-page-main {
  padding: 16px; }
  .inquiry-list-page-main .inquiry-list-page-main-head {
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    margin-bottom: 8px; }
    .inquiry-list-page-main .inquiry-list-page-main-head .tabs-box .tab {
      display: inline-block;
      vertical-align: middle;
      margin: 0 14px;
      font-size: 14px;
      color: var(--font-normal);
      padding-bottom: 4px;
      border-bottom: 2px solid transparent;
      cursor: pointer; }
      .inquiry-list-page-main .inquiry-list-page-main-head .tabs-box .tab:hover {
        color: var(--color); }
      .inquiry-list-page-main .inquiry-list-page-main-head .tabs-box .tab.active {
        color: var(--color);
        border-color: var(--color); }
    .inquiry-list-page-main .inquiry-list-page-main-head .search-box .input-wrap, .inquiry-list-page-main .inquiry-list-page-main-head .search-box .select-box {
      display: inline-block;
      vertical-align: middle; }
    .inquiry-list-page-main .inquiry-list-page-main-head .search-box .input-wrap {
      margin-right: 8px; }
  .inquiry-list-page-main .inquiry-list-page-main-body {
    border: 1px solid var(--border-deep); }
    .inquiry-list-page-main .inquiry-list-page-main-body .inquiry-list-card {
      padding: 16px;
      border-bottom: 1px solid var(--border-deep);
      font-size: 14px;
      display: flex;
      cursor: pointer; }
      .inquiry-list-page-main .inquiry-list-page-main-body .inquiry-list-card:hover {
        background: var(--color-hover); }
      .inquiry-list-page-main .inquiry-list-page-main-body .inquiry-list-card:last-child {
        border-bottom: 0 none; }
      .inquiry-list-page-main .inquiry-list-page-main-body .inquiry-list-card .img-box {
        width: 50px;
        height: 50px;
        text-align: center;
        line-height: 50px; }
        .inquiry-list-page-main .inquiry-list-page-main-body .inquiry-list-card .img-box img {
          max-width: 100%;
          max-height: 100%; }
      .inquiry-list-page-main .inquiry-list-page-main-body .inquiry-list-card .main-box {
        flex: 1;
        width: 0;
        padding: 0 16px;
        color: var(--font-tips); }
        .inquiry-list-page-main .inquiry-list-page-main-body .inquiry-list-card .main-box .title {
          margin-bottom: 8px; }
          .inquiry-list-page-main .inquiry-list-page-main-body .inquiry-list-card .main-box .title .txt-title {
            color: var(--font-title); }
            .inquiry-list-page-main .inquiry-list-page-main-body .inquiry-list-card .main-box .title .txt-title .tips {
              margin-left: 1em;
              color: var(--font-tips); }
          .inquiry-list-page-main .inquiry-list-page-main-body .inquiry-list-card .main-box .title .txt {
            margin-left: 1em;
            color: var(--font-normal); }
      .inquiry-list-page-main .inquiry-list-page-main-body .inquiry-list-card .tips-box .txt {
        margin-left: 1em;
        color: var(--font-tips); }
      .inquiry-list-page-main .inquiry-list-page-main-body .inquiry-list-card .attachment-box {
        margin-top: 8px;
        border-top: 1px solid var(--border-deep);
        padding-top: 8px; }
        .inquiry-list-page-main .inquiry-list-page-main-body .inquiry-list-card .attachment-box .tit {
          font-size: 14px;
          color: var(--font-tips);
          margin-bottom: 4px; }
        .inquiry-list-page-main .inquiry-list-page-main-body .inquiry-list-card .attachment-box .remark-box {
          font-size: 14px;
          color: var(--font-title);
          line-height: 1.5;
          margin-bottom: 4px;
          word-break: break-all;
          white-space: normal; }
        .inquiry-list-page-main .inquiry-list-page-main-body .inquiry-list-card .attachment-box .attachment-main .img-box {
          display: inline-block;
          vertical-align: top;
          border: 1px solid var(--border-deep);
          width: 80px;
          height: 80px;
          text-align: center;
          line-height: 80px;
          margin-right: 8px; }
          .inquiry-list-page-main .inquiry-list-page-main-body .inquiry-list-card .attachment-box .attachment-main .img-box img {
            max-width: 100%;
            max-height: 100%; }
  .inquiry-list-page-main .inquiry-list-page-main-foot {
    margin-top: 16px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center; }

.inquiry-detail-page-box {
  margin-top: 14px;
  background: #fff;
  height: 100%; }

.inquiry-detail-page-main {
  padding: 16px; }
  .inquiry-detail-page-main .car-info-box {
    align-content: center;
    align-items: center;
    font-size: 14px;
    border: 1px solid var(--border-deep);
    margin-top: 0;
    margin-bottom: 16px;
    padding: 8px 16px; }
    .inquiry-detail-page-main .car-info-box:before {
      display: none; }
    .inquiry-detail-page-main .car-info-box .img-box {
      width: 50px;
      height: 50px;
      text-align: center;
      line-height: 50px;
      background: #fff; }
      .inquiry-detail-page-main .car-info-box .img-box img {
        max-width: 100%;
        max-height: 100%; }
    .inquiry-detail-page-main .car-info-box .info-box {
      flex: 1;
      padding-left: 24px;
      color: var(--font-tips); }
      .inquiry-detail-page-main .car-info-box .info-box span {
        margin-right: 16px; }
        .inquiry-detail-page-main .car-info-box .info-box span:first-child {
          color: var(--font-title); }
    .inquiry-detail-page-main .car-info-box .text-btn {
      color: var(--font-title);
      cursor: pointer; }
      .inquiry-detail-page-main .car-info-box .text-btn:hover {
        color: var(--color); }
  .inquiry-detail-page-main .detail-main-box {
    display: flex; }
    .inquiry-detail-page-main .detail-main-box .left-box {
      width: 200px;
      min-height: 694px;
      margin-right: 16px;
      background: #f8f8f8;
      border: 1px solid var(--border-deep); }
      .inquiry-detail-page-main .detail-main-box .left-box li {
        width: 100%;
        height: 40px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        line-height: 40px;
        font-size: 14px;
        padding: 0 16px;
        color: var(--font-title);
        cursor: pointer; }
        .inquiry-detail-page-main .detail-main-box .left-box li:hover, .inquiry-detail-page-main .detail-main-box .left-box li.active {
          color: var(--color);
          background: #fff; }
    .inquiry-detail-page-main .detail-main-box .right-box {
      flex: 1;
      width: 0; }
  .inquiry-detail-page-main .inquiry-product-list-box {
    margin-bottom: 16px;
    border: 1px solid var(--border-deep); }
    .inquiry-detail-page-main .inquiry-product-list-box .inquiry-product-list-box-head {
      background: #f8f8f8;
      height: 40px;
      line-height: 40px;
      font-size: 14px;
      color: var(--font-title);
      padding: 0 16px;
      border-bottom: 1px solid var(--border-deep); }
      .inquiry-detail-page-main .inquiry-product-list-box .inquiry-product-list-box-head span {
        display: inline-block;
        margin-right: 32px; }
      .inquiry-detail-page-main .inquiry-product-list-box .inquiry-product-list-box-head .epc-btn {
        float: right;
        line-height: 1;
        cursor: pointer;
        margin-right: 0; }
        .inquiry-detail-page-main .inquiry-product-list-box .inquiry-product-list-box-head .epc-btn .svg-icon {
          width: 36px;
          height: 36px; }
    .inquiry-detail-page-main .inquiry-product-list-box .inquiry-product-list-box-main {
      min-height: 220px; }
      .inquiry-detail-page-main .inquiry-product-list-box .inquiry-product-list-box-main .goods-card-box {
        display: flex;
        padding: 16px;
        border-bottom: 1px solid var(--border-deep);
        font-size: 14px; }
        .inquiry-detail-page-main .inquiry-product-list-box .inquiry-product-list-box-main .goods-card-box .info-box {
          flex: 1; }
          .inquiry-detail-page-main .inquiry-product-list-box .inquiry-product-list-box-main .goods-card-box .info-box .tit {
            color: var(--font-title); }
          .inquiry-detail-page-main .inquiry-product-list-box .inquiry-product-list-box-main .goods-card-box .info-box .tips span {
            margin-right: 8px; }
          .inquiry-detail-page-main .inquiry-product-list-box .inquiry-product-list-box-main .goods-card-box .info-box .tips .iconfont {
            font-size: 14px;
            color: var(--font-high);
            margin-right: 4px; }
        .inquiry-detail-page-main .inquiry-product-list-box .inquiry-product-list-box-main .goods-card-box .price-box {
          width: 20%;
          color: var(--font-high); }
        .inquiry-detail-page-main .inquiry-product-list-box .inquiry-product-list-box-main .goods-card-box .add-cart {
          width: 10%;
          text-align: right;
          cursor: pointer;
          color: var(--font-title); }
          .inquiry-detail-page-main .inquiry-product-list-box .inquiry-product-list-box-main .goods-card-box .add-cart.active {
            color: var(--font-high); }
          .inquiry-detail-page-main .inquiry-product-list-box .inquiry-product-list-box-main .goods-card-box .add-cart .iconfont {
            font-size: 14px;
            margin-right: 4px; }
      .inquiry-detail-page-main .inquiry-product-list-box .inquiry-product-list-box-main .null-data-box {
        text-align: center;
        padding-top: 40px; }
        .inquiry-detail-page-main .inquiry-product-list-box .inquiry-product-list-box-main .null-data-box .null-wrap {
          display: inline-block; }
          .inquiry-detail-page-main .inquiry-product-list-box .inquiry-product-list-box-main .null-data-box .null-wrap .icon-box .svg-icon {
            width: 90px;
            height: 90px; }
          .inquiry-detail-page-main .inquiry-product-list-box .inquiry-product-list-box-main .null-data-box .null-wrap .tips-text {
            font-size: 14px;
            color: var(--font-normal); }
          .inquiry-detail-page-main .inquiry-product-list-box .inquiry-product-list-box-main .null-data-box .null-wrap .status-box {
            width: 102px;
            height: 24px;
            border: 1px solid var(--font-tips);
            text-align: center;
            line-height: 22px;
            font-size: 14px;
            color: var(--font-normal);
            border-radius: 4px;
            margin-top: 8px; }
          .inquiry-detail-page-main .inquiry-product-list-box .inquiry-product-list-box-main .null-data-box .null-wrap .time {
            margin-top: 8px;
            font-size: 14px;
            color: var(--font-high); }
  .inquiry-detail-page-main .parts-remark-box {
    border: 1px solid var(--border-deep); }
    .inquiry-detail-page-main .parts-remark-box .parts-remark-box-head {
      background: #f8f8f8;
      height: 40px;
      line-height: 40px;
      font-size: 14px;
      color: var(--font-title);
      padding: 0 16px;
      border-bottom: 1px solid var(--border-deep); }
    .inquiry-detail-page-main .parts-remark-box .parts-remark-box-body {
      min-height: 200px;
      padding: 16px; }
      .inquiry-detail-page-main .parts-remark-box .parts-remark-box-body .remark-wrap {
        font-size: 14px;
        color: var(--font-title);
        margin-bottom: 8px;
        word-break: break-all;
        white-space: normal; }
      .inquiry-detail-page-main .parts-remark-box .parts-remark-box-body .img-wrap .img-box {
        display: inline-block;
        vertical-align: top;
        margin-right: 8px;
        width: 144px;
        height: 144px;
        border: 1px solid var(--border-deep);
        text-align: center;
        line-height: 144px; }
        .inquiry-detail-page-main .parts-remark-box .parts-remark-box-body .img-wrap .img-box img {
          max-height: 100%;
          max-width: 100%; }

.remark-pop-body .text-area-wrap {
  margin-bottom: 16px; }

@media all and (max-height: 768px) {
  .ys-drawer-box .structure-chart-detail-box .structure-chart-detail-body .structure-chart-detail-img-box .img-box {
    width: 240px;
    height: 240px;
    line-height: 240px; } }

.goods-list-wrap {
  width: 1200px;
  min-width: 1200px;
  margin: 10px auto;
  background: #fff; }
  .goods-list-wrap .ant-pagination {
    padding: 32px 0 8px;
    text-align: center; }
  .goods-list-wrap .ant-spin {
    display: block;
    padding: 48px 0 36px; }
  .goods-list-wrap .ant-empty {
    padding: 48px 0 36px; }

.goods-list-box {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap; }

.goods-list-card {
  width: 285px;
  margin-bottom: 20px;
  border: 1px solid var(--border-deep);
  cursor: pointer; }
  .goods-list-card:hover {
    border-color: var(--color); }
  .goods-list-card:not(:nth-child(4n)) {
    margin-right: 20px; }
  .goods-list-card .img-box {
    position: relative;
    height: 285px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    border-bottom: 1px solid var(--border-deep);
    overflow: hidden; }
    .goods-list-card .img-box img {
      max-width: 100%;
      max-height: 100%; }
  .goods-list-card .goods-info-box {
    padding: 12px;
    display: flex;
    flex-direction: column; }
    .goods-list-card .goods-info-box .goods-info-row {
      flex: 1;
      font-size: 14px;
      color: var(--font-title);
      line-height: 1.4; }
      .goods-list-card .goods-info-box .goods-info-row:not(:last-child) {
        margin-bottom: 8px; }
    .goods-list-card .goods-info-box .title-row {
      min-height: 38px; }
    .goods-list-card .goods-info-box .tag-row {
      white-space: nowrap;
      overflow: hidden;
      min-height: 20px; }
      .goods-list-card .goods-info-box .tag-row .tag {
        display: inline-block;
        font-size: 12px;
        color: #fff;
        margin-right: 8px;
        border-radius: 4px;
        background: var(--orange-red);
        padding: 2px 8px; }
        .goods-list-card .goods-info-box .tag-row .tag:nth-child(n+4) {
          display: none; }
    .goods-list-card .goods-info-box .price-row {
      font-size: 18px;
      color: var(--font-high); }
      .goods-list-card .goods-info-box .price-row i {
        font-size: 14px; }
      .goods-list-card .goods-info-box .price-row span {
        font-size: 14px;
        color: var(--font-tips);
        text-decoration: line-through;
        display: inline-block;
        padding-left: 8px; }
    .goods-list-card .goods-info-box .to-cart {
      text-align: right; }
      .goods-list-card .goods-info-box .to-cart span {
        display: inline-block;
        width: 28px;
        height: 28px;
        background: var(--color);
        color: #fff;
        text-align: center;
        line-height: 28px;
        border-radius: 50%;
        cursor: pointer; }
        .goods-list-card .goods-info-box .to-cart span:hover {
          opacity: .8; }
        .goods-list-card .goods-info-box .to-cart span .iconfont {
          font-size: 12px; }

.goods-card-cover {
  text-align: center; }
  .goods-card-cover .goods-list-card {
    display: inline-block;
    margin-right: 0;
    margin-bottom: 0;
    text-align: left; }

.goods-list-row {
  display: flex;
  align-items: flex-start;
  padding: 16px;
  border-bottom: 1px solid var(--border-deep); }
  .goods-list-row .check-box {
    width: 20px;
    margin-right: 10px;
    align-self: center;
    cursor: pointer; }
    .goods-list-row .check-box:hover {
      color: var(--color); }
    .goods-list-row .check-box .icon-check-s {
      color: var(--color); }
  .goods-list-row .image-box {
    width: 120px;
    height: 120px;
    position: relative;
    cursor: pointer;
    text-align: center;
    line-height: 120px; }
    .goods-list-row .image-box img {
      max-height: 100%;
      max-width: 100%; }
    .goods-list-row .image-box .tips-box {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 32px;
      font-size: 14px;
      color: #fff;
      line-height: 32px;
      text-align: center;
      background: rgba(0, 0, 0, 0.8); }
  .goods-list-row .detail-box {
    width: 348px;
    padding-left: 24px;
    word-break: break-all;
    cursor: pointer; }
    .goods-list-row .detail-box .name {
      font-size: 14px; }
    .goods-list-row .detail-box .tips {
      font-size: 12px;
      color: var(--font-tips); }
  .goods-list-row .describe-box {
    width: 336px;
    padding-left: 24px; }
    .goods-list-row .describe-box .price {
      font-size: 16px;
      color: var(--font-high); }
    .goods-list-row .describe-box .unit {
      font-size: 12px;
      color: var(--font-tips); }
  .goods-list-row .cart-box {
    width: 246px;
    padding-left: 24px; }
    .goods-list-row .cart-box .stock {
      font-size: 14px;
      color: var(--font-tips); }
    .goods-list-row .cart-box .label {
      display: inline-block;
      font-size: 14px;
      line-height: 24px;
      padding: 0 8px;
      color: var(--font-title);
      border-radius: 12px;
      background: #F5F5F5; }
  .goods-list-row .operate-box {
    align-self: center; }
    .goods-list-row .operate-box .btn {
      width: 102px;
      line-height: 32px;
      font-size: 14px;
      border: 0 none;
      border-radius: 4px;
      background: var(--color);
      color: #fff;
      cursor: pointer; }
      .goods-list-row .operate-box .btn:hover {
        opacity: .8; }
      .goods-list-row .operate-box .btn:disabled {
        background: var(--disabled); }

.goods-detail-head {
  width: 1200px;
  display: flex;
  padding: 20px 16px; }
  .goods-detail-head .goods-detail-box {
    flex: 1;
    padding-left: 32px;
    width: 0; }
    .goods-detail-head .goods-detail-box .name {
      margin-bottom: 4px;
      font-size: 18px;
      font-weight: 600;
      color: var(--font-title);
      word-break: break-all;
      white-space: normal; }
    .goods-detail-head .goods-detail-box .code {
      color: var(--font-tips); }
      .goods-detail-head .goods-detail-box .code .item {
        display: inline-block;
        vertical-align: top;
        word-break: break-word;
        margin-right: 24px; }
      .goods-detail-head .goods-detail-box .code .icon-shuoming {
        margin-right: 2px; }
  .goods-detail-head .coupon-box {
    display: flex;
    align-items: center;
    height: 44px;
    padding: 0 16px;
    border-bottom: 2px dashed #e3e3e3;
    background: whitesmoke; }
    .goods-detail-head .coupon-box .label {
      padding-right: 8px; }
    .goods-detail-head .coupon-box .more-btn {
      cursor: pointer; }
    .goods-detail-head .coupon-box .icon-jump {
      font-size: 12px; }
    .goods-detail-head .coupon-box .coupon-list-box {
      flex: 1;
      overflow: hidden;
      height: 20px;
      padding: 1px 0; }
    .goods-detail-head .coupon-box .coupon-label {
      align-items: center;
      display: inline-block;
      vertical-align: top;
      font-size: 12px;
      line-height: 18px;
      color: var(--color-hint);
      position: relative;
      margin: 0 4px 0 2px;
      padding: 0 8px;
      border-radius: 2px;
      background: radial-gradient(circle at left, transparent 4px, #FFEFF0 0) left/60% no-repeat, radial-gradient(circle at right, transparent 4px, #FFEFF0 0) right/60% no-repeat;
      filter: drop-shadow(0 0 1px var(--color-hint)); }
  .goods-detail-head .price-wrap-row {
    padding: 12px;
    background-color: #f5f5f5;
    background-image: url("../../assets/images/goods_bg_details_price.png");
    background-repeat: repeat-x;
    background-position: bottom left;
    margin: 16px 0; }
    .goods-detail-head .price-wrap-row .price-row {
      display: flex;
      align-content: center;
      align-items: center;
      margin: 8px 0; }
      .goods-detail-head .price-wrap-row .price-row .title {
        min-width: 76px;
        max-width: 100px;
        color: var(--font-normal);
        font-size: 14px; }
      .goods-detail-head .price-wrap-row .price-row .price {
        flex: 1;
        font-size: 14px;
        color: var(--font-title); }
        .goods-detail-head .price-wrap-row .price-row .price .hint {
          font-size: 24px;
          font-weight: 600;
          color: var(--font-high); }
        .goods-detail-head .price-wrap-row .price-row .price .line-through {
          text-decoration: line-through; }
    .goods-detail-head .price-wrap-row .promotion-row .title {
      align-self: flex-start; }
    .goods-detail-head .price-wrap-row .promotion-row .price {
      width: 0;
      display: flex; }
      .goods-detail-head .price-wrap-row .promotion-row .price .more {
        cursor: pointer;
        white-space: nowrap;
        color: var(--font-tips); }
        .goods-detail-head .price-wrap-row .promotion-row .price .more:hover {
          color: var(--color); }
    .goods-detail-head .price-wrap-row .promotion-row .high {
      color: var(--font-high); }
    .goods-detail-head .price-wrap-row .promotion-item {
      display: inline-block;
      vertical-align: middle;
      margin-right: 8px;
      margin-bottom: 4px; }
      .goods-detail-head .price-wrap-row .promotion-item .img-box {
        display: inline-block;
        vertical-align: middle;
        width: 26px;
        height: 26px;
        border: 1px solid var(--border-deep);
        overflow: hidden;
        margin: 0 4px; }
  .goods-detail-head .product-wrap {
    display: flex;
    padding: 0 12px;
    margin: 16px 0; }
    .goods-detail-head .product-wrap .title {
      width: 100px;
      font-size: 14px;
      text-align: left;
      color: var(--font-normal);
      height: 40px;
      line-height: 40px; }
    .goods-detail-head .product-wrap .product-main {
      flex: 1; }
      .goods-detail-head .product-wrap .product-main .product-item {
        display: inline-block;
        position: relative;
        padding: 6px 12px 8px;
        min-width: 74px;
        line-height: 1.5;
        margin-bottom: 8px;
        margin-right: 8px;
        text-align: center;
        border: 1px solid #b3b3b3;
        cursor: pointer;
        color: var(--font-title);
        background: #fff;
        max-width: 300px; }
        .goods-detail-head .product-wrap .product-main .product-item:not(:disabled):hover, .goods-detail-head .product-wrap .product-main .product-item:not(:disabled).active {
          border-color: var(--color);
          color: var(--color); }
        .goods-detail-head .product-wrap .product-main .product-item:disabled {
          color: #fff;
          border: 1px solid var(--disabled);
          background: var(--disabled);
          cursor: not-allowed; }
  .goods-detail-head .product-info-wrap {
    position: relative;
    font-size: 12px;
    color: var(--font-title);
    margin-top: 42px; }
    .goods-detail-head .product-info-wrap .product-info-row {
      display: flex;
      align-content: center;
      align-items: center;
      height: 50px;
      padding: 0 16px;
      background: #f5f5f5;
      border: 1px solid #e3e3e3; }
      .goods-detail-head .product-info-wrap .product-info-row .format-wrap {
        width: 28%;
        padding-right: 8px;
        position: relative;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; }
        .goods-detail-head .product-info-wrap .product-info-row .format-wrap:after {
          content: "";
          display: inline-block;
          width: 1px;
          height: 50px;
          background: var(--border-deep);
          position: absolute;
          top: 0;
          right: 0;
          transform: translateY(-27%); }
      .goods-detail-head .product-info-wrap .product-info-row .price-wrap {
        width: 20%;
        padding: 0 8px;
        color: var(--font-high); }
      .goods-detail-head .product-info-wrap .product-info-row .unit-wrap {
        flex: 1; }
      .goods-detail-head .product-info-wrap .product-info-row .stock-wrap {
        padding: 16px;
        max-width: 15%; }
      .goods-detail-head .product-info-wrap .product-info-row .quantity {
        margin-right: 10px; }
    .goods-detail-head .product-info-wrap .product-num-row {
      display: none;
      position: absolute;
      left: 0;
      bottom: 50px;
      background: #fafafa;
      width: 100%;
      height: 30px;
      border-width: 1px 1px 0;
      border-style: solid;
      border-color: var(--border-deep);
      align-content: center;
      align-items: center;
      font-size: 14px;
      color: var(--font-title);
      padding: 0 16px; }
      .goods-detail-head .product-info-wrap .product-num-row .number-total-wrap {
        width: 20%;
        border-right: 1px solid var(--border-deep);
        padding-right: 12px;
        text-align: right; }
      .goods-detail-head .product-info-wrap .product-num-row .price-total-wrap {
        flex: 1;
        padding-left: 32px; }
      .goods-detail-head .product-info-wrap .product-num-row .detail-btn {
        cursor: pointer; }
        .goods-detail-head .product-info-wrap .product-num-row .detail-btn .iconfont {
          font-size: 12px;
          padding-left: 4px; }
    .goods-detail-head .product-info-wrap .cart-list-row {
      display: none;
      border-width: 1px 1px 0 1px;
      border-style: solid;
      border-color: var(--border-deep);
      position: absolute;
      width: 100%;
      background: #fff;
      bottom: 79px;
      left: 0;
      z-index: 4; }
      .goods-detail-head .product-info-wrap .cart-list-row .product-info-row {
        border: 0 none;
        border-bottom: 1px solid var(--border-deep);
        background: #fff; }
  .goods-detail-head .btn-wrap {
    margin: 24px 0; }
    .goods-detail-head .btn-wrap .buy-btn {
      width: 150px;
      height: 40px;
      border: 1px solid var(--color);
      font-size: 16px;
      font-weight: 600;
      color: var(--color);
      margin-right: 24px;
      cursor: pointer; }
      .goods-detail-head .btn-wrap .buy-btn:hover {
        opacity: .8; }
    .goods-detail-head .btn-wrap .disabled-btn {
      width: 150px;
      height: 40px;
      font-size: 16px;
      font-weight: 600;
      color: #fff;
      border: 1px solid var(--disabled);
      background: var(--disabled);
      margin-right: 24px;
      cursor: pointer; }
    .goods-detail-head .btn-wrap .add-btn {
      width: 150px;
      height: 40px;
      background-color: var(--color);
      border: 0 none;
      font-size: 16px;
      font-weight: 600;
      color: #ffffff;
      cursor: pointer;
      margin-right: 32px; }
      .goods-detail-head .btn-wrap .add-btn:hover {
        opacity: .8; }
    .goods-detail-head .btn-wrap .collect-btn {
      display: inline-block;
      vertical-align: middle;
      cursor: pointer; }
      .goods-detail-head .btn-wrap .collect-btn .iconfont {
        margin-right: 6px; }
      .goods-detail-head .btn-wrap .collect-btn:hover, .goods-detail-head .btn-wrap .collect-btn.active {
        color: var(--color); }

.goods-package-detail-head .price-wrap-row .price-row .item {
  display: flex;
  align-items: center;
  flex: 1; }

.goods-package-detail-head .price-wrap-row .price-row .item:nth-child(2) .title {
  width: 80px; }

.goods-package-detail-head .price-wrap-row .price-row .item:nth-child(3) .forecast {
  font-size: 12px;
  color: var(--font-title);
  white-space: nowrap; }
  .goods-package-detail-head .price-wrap-row .price-row .item:nth-child(3) .forecast .fb {
    font-weight: 700;
    font-size: 15px; }
  .goods-package-detail-head .price-wrap-row .price-row .item:nth-child(3) .forecast .day {
    display: inline-block;
    padding: 0 6px 0 16px;
    color: var(--color-hint);
    font-weight: 700;
    font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "\5FAE\8F6F\96C5\9ED1", SimSun, sans-serif; }
  .goods-package-detail-head .price-wrap-row .price-row .item:nth-child(3) .forecast .time {
    display: inline-block;
    font-size: 10px;
    line-height: 28px;
    min-width: 28px;
    margin: 0 5px;
    text-align: center;
    background: var(--color-hint);
    border-radius: 3px;
    color: #fff;
    font-weight: 700;
    font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "\5FAE\8F6F\96C5\9ED1", SimSun, sans-serif; }
  .goods-package-detail-head .price-wrap-row .price-row .item:nth-child(3) .forecast.disabled {
    color: var(--font-tips); }
    .goods-package-detail-head .price-wrap-row .price-row .item:nth-child(3) .forecast.disabled .day {
      color: var(--font-tips); }
    .goods-package-detail-head .price-wrap-row .price-row .item:nth-child(3) .forecast.disabled .time {
      background: #D2D2D2; }
  .goods-package-detail-head .price-wrap-row .price-row .item:nth-child(3) .forecast.invalid {
    color: var(--font-title); }
    .goods-package-detail-head .price-wrap-row .price-row .item:nth-child(3) .forecast.invalid .day {
      color: var(--font-title); }
    .goods-package-detail-head .price-wrap-row .price-row .item:nth-child(3) .forecast.invalid .time {
      background: #333; }

.package-list-box {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  height: 200px;
  overflow: auto; }
  .package-list-box .goods-item-cart {
    display: flex;
    align-content: flex-start;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 12px;
    border: 1px solid #DEDEDE;
    padding: 12px;
    height: 118px; }
    .package-list-box .goods-item-cart .img-box {
      width: 90px;
      height: 90px;
      line-height: 90px;
      text-align: center;
      overflow: hidden; }
    .package-list-box .goods-item-cart .info-box {
      padding-left: 8px;
      line-height: 1;
      font-size: 14px;
      flex: 1; }
      .package-list-box .goods-item-cart .info-box p {
        margin-bottom: 8px; }
        .package-list-box .goods-item-cart .info-box p:last-child {
          margin-bottom: 0; }
      .package-list-box .goods-item-cart .info-box .tit {
        font-weight: 600;
        color: var(--font-title); }
      .package-list-box .goods-item-cart .info-box .format {
        color: var(--font-normal); }
      .package-list-box .goods-item-cart .info-box .price {
        color: var(--font-tips); }
        .package-list-box .goods-item-cart .info-box .price i {
          font-size: 16px;
          color: #FA3815;
          font-weight: 600; }

.video-pop-box {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 6; }
  .video-pop-box:hover .video-close, .video-pop-box:hover .video-play-box, .video-pop-box:hover .video-pause-box {
    display: block; }
  .video-pop-box .video-close {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 32px;
    text-align: right;
    padding: 0 16px;
    background: rgba(0, 0, 0, 0.4);
    z-index: 2; }
    .video-pop-box .video-close i {
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translateY(-50%);
      font-size: 14px;
      color: #fff;
      cursor: pointer; }
  .video-pop-box .time-display-box {
    position: absolute;
    left: 24px;
    bottom: 24px;
    font-size: 14px;
    color: #fff;
    z-index: 3;
    padding: 4px 8px;
    border-radius: 100px;
    background: rgba(0, 0, 0, 0.4); }
  .video-pop-box .video-play-box, .video-pop-box .video-pause-box {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.2);
    cursor: pointer; }
    .video-pop-box .video-play-box img, .video-pop-box .video-pause-box img {
      width: 40px;
      height: 40px;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%); }

.play-video-btn {
  position: absolute;
  left: 16px;
  bottom: 40px;
  width: 90px;
  height: 24px;
  line-height: 24px;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 100px;
  text-align: center;
  color: #fff;
  cursor: pointer;
  font-size: 12px; }

.goods-product-box {
  margin-top: 32px; }

.goods-product-item {
  display: flex;
  margin-bottom: 32px;
  font-size: 14px;
  color: var(--font-tips); }
  .goods-product-item .detail-box {
    width: 340px;
    padding-right: 16px; }
    .goods-product-item .detail-box .format {
      line-height: 24px;
      color: var(--font-normal); }
      .goods-product-item .detail-box .format .code {
        color: var(--font-tips); }
    .goods-product-item .detail-box .icon-spec-picture {
      color: var(--font-tips);
      margin-right: 4px;
      font-size: 14px; }
      .goods-product-item .detail-box .icon-spec-picture:hover {
        opacity: .8;
        cursor: pointer; }
    .goods-product-item .detail-box .iconimg {
      color: var(--font-title); }
  .goods-product-item .describe-box {
    margin-top: 8px; }
    .goods-product-item .describe-box .row {
      padding-right: 24px; }
    .goods-product-item .describe-box .iconfont {
      color: var(--font-high); }
  .goods-product-item .price-box {
    width: 120px; }
    .goods-product-item .price-box .price {
      font-size: 16px;
      color: var(--font-high); }
      .goods-product-item .price-box .price .unit {
        font-size: 12px;
        color: var(--font-tips); }
  .goods-product-item .uint-box {
    margin-top: 8px; }
  .goods-product-item .cart-box {
    width: 126px; }
    .goods-product-item .cart-box .stock {
      margin-top: 8px; }
  .goods-product-item .operate-box {
    align-self: flex-end; }
    .goods-product-item .operate-box .btn {
      width: 102px;
      line-height: 32px;
      font-size: 14px;
      border: 0 none;
      border-radius: 4px;
      background: var(--color);
      color: #fff;
      cursor: pointer; }
      .goods-product-item .operate-box .btn:hover {
        opacity: .8; }
      .goods-product-item .operate-box .btn:disabled {
        background: var(--disabled); }

.goods-detail-introduce {
  width: 800px;
  margin: 24px auto 0;
  word-break: break-all;
  white-space: normal; }
  .goods-detail-introduce img {
    min-width: 100%; }

.goods-detail-property {
  display: flex;
  flex-wrap: wrap;
  padding: 0 16px; }
  .goods-detail-property .item {
    display: inline-block;
    width: 294px;
    padding-right: 120px;
    vertical-align: top;
    font-size: 14px;
    margin-bottom: 8px;
    color: var(--font-normal); }

.goods-detail-tabs .ant-tabs-nav-container {
  height: 54px;
  padding-top: 8px;
  padding-left: 16px;
  background: #F5F5F5; }
  .goods-detail-tabs .ant-tabs-nav-container .ant-tabs-nav .ant-tabs-tab:hover, .goods-detail-tabs .ant-tabs-nav-container .ant-tabs-nav .ant-tabs-tab:active {
    color: var(--color); }
  .goods-detail-tabs .ant-tabs-nav-container .ant-tabs-nav .ant-tabs-tab-active {
    color: var(--color); }
  .goods-detail-tabs .ant-tabs-nav-container .ant-tabs-ink-bar {
    background-color: var(--color); }

.goods-detail-tabs .ant-pagination {
  margin: 62px auto 24px;
  text-align: center; }

.goods-detail-tabs .ant-empty {
  margin: 48px 0 24px; }

.recommend-list-box {
  margin-bottom: 40px; }
  .recommend-list-box .ant-carousel .slick-dots-bottom {
    bottom: -18px;
    margin-bottom: 0; }
  .recommend-list-box .ant-carousel .slick-dots li {
    height: 8px;
    width: auto; }
    .recommend-list-box .ant-carousel .slick-dots li button {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: #666; }
    .recommend-list-box .ant-carousel .slick-dots li.slick-active {
      width: auto; }
      .recommend-list-box .ant-carousel .slick-dots li.slick-active button {
        background: red; }

.goods-brand-page-main {
  background: #fff;
  min-height: 600px;
  padding: 16px; }
  .goods-brand-page-main .first-letter-wrap .item {
    display: inline-block;
    vertical-align: middle;
    padding: 0 8px;
    height: 24px;
    line-height: 24px;
    border: 1px solid transparent;
    cursor: pointer;
    font-size: 14px;
    color: var(--font-title); }
    .goods-brand-page-main .first-letter-wrap .item:hover {
      color: var(--color); }
    .goods-brand-page-main .first-letter-wrap .item.active {
      border-color: var(--color);
      color: var(--color); }
  .goods-brand-page-main .brand-list-wrap {
    position: relative;
    display: block;
    transition: all 0.4s ease-out;
    overflow: hidden;
    max-width: 100%; }
    .goods-brand-page-main .brand-list-wrap .brand-card {
      position: absolute;
      display: inline-block;
      vertical-align: top;
      width: 222px;
      height: 116px;
      border: 1px solid var(--border-deep);
      transition: all 0.4s ease-out;
      overflow: hidden; }
      .goods-brand-page-main .brand-list-wrap .brand-card:hover .brand-hover {
        opacity: 1;
        transition: opacity .3s linear; }
    .goods-brand-page-main .brand-list-wrap .brand-main {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center; }
      .goods-brand-page-main .brand-list-wrap .brand-main span {
        font-size: 16px;
        line-height: 22px; }
    .goods-brand-page-main .brand-list-wrap .brand-hover {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.7);
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      opacity: 0;
      transition: opacity .3s linear;
      cursor: pointer; }
      .goods-brand-page-main .brand-list-wrap .brand-hover .box {
        text-align: center; }
      .goods-brand-page-main .brand-list-wrap .brand-hover .tit {
        font-size: 16px;
        line-height: 22px; }
      .goods-brand-page-main .brand-list-wrap .brand-hover .tips {
        font-size: 14px;
        line-height: 20px;
        color: rgba(255, 255, 255, 0.8); }

.goods-sn-search {
  padding: 0 48px;
  text-align: center;
  min-height: 540px; }
  .goods-sn-search .hint {
    width: 292px;
    text-align: center;
    margin: 0 auto;
    padding: 110px 0 26px;
    font-size: 20px;
    color: var(--font-tips); }
  .goods-sn-search .search-status-wrap {
    text-align: center;
    padding: 30px 0 38px;
    margin: 0 auto 42px;
    width: 1088px;
    border-bottom: 1px solid var(--border-deep); }
    .goods-sn-search .search-status-wrap .svg-icon {
      width: 45px;
      height: 45px;
      margin-bottom: 16px; }
    .goods-sn-search .search-status-wrap .tips {
      font-size: 14px;
      color: var(--font-title); }

.goods-sn-search-value .value {
  width: 638px;
  height: 58px;
  padding: 0 16px;
  font-size: 16px;
  text-align: center;
  margin-bottom: 53px;
  background: #FFFFFF;
  border-radius: 37px;
  border: 1px solid #DCDCDD; }

.goods-sn-search-btn {
  text-align: center;
  margin-bottom: 24px; }
  .goods-sn-search-btn .btn {
    width: 234px;
    height: 58px;
    line-height: 56px;
    font-size: 20px;
    border: 0 none;
    border-radius: 37px;
    background-color: var(--color);
    color: #fff; }

.goods-sn-search-product {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  padding: 0 50px; }
  .goods-sn-search-product .img-box {
    width: 212px;
    height: 212px;
    line-height: 212px;
    margin-right: 12px;
    overflow: hidden;
    text-align: center; }
  .goods-sn-search-product .detail {
    flex: 1;
    padding: 0 42px;
    font-size: 14px;
    text-align: left;
    color: var(--font-tips);
    line-height: 2; }

.goods-package-list {
  margin-top: 16px;
  background: #fff; }
  .goods-package-list .goods-package-list-item {
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
    border: 1px solid #F5F5F5;
    padding-bottom: 24px; }
  .goods-package-list:last-child {
    margin-bottom: 8px; }
  .goods-package-list .headline {
    padding: 0 24px;
    line-height: 45px;
    font-size: 15px;
    color: var(--font-title);
    display: flex;
    justify-content: space-between; }
    .goods-package-list .headline .name {
      font-size: 15px;
      font-weight: 700;
      white-space: nowrap; }
    .goods-package-list .headline .forecast {
      font-size: 12px;
      color: var(--font-title);
      white-space: nowrap; }
      .goods-package-list .headline .forecast .fb {
        font-weight: 700;
        font-size: 15px; }
      .goods-package-list .headline .forecast.disabled {
        color: var(--font-tips); }
        .goods-package-list .headline .forecast.disabled .day {
          color: var(--font-tips); }
        .goods-package-list .headline .forecast.disabled .time {
          background: #D2D2D2; }
      .goods-package-list .headline .forecast.invalid {
        color: var(--font-title); }
        .goods-package-list .headline .forecast.invalid .day {
          color: var(--font-title); }
        .goods-package-list .headline .forecast.invalid .time {
          background: #333; }
    .goods-package-list .headline .day {
      display: inline-block;
      padding: 0 6px 0 16px;
      color: var(--color-hint);
      font-weight: 700;
      font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "\5FAE\8F6F\96C5\9ED1", SimSun, sans-serif; }
    .goods-package-list .headline .time {
      display: inline-block;
      font-size: 10px;
      line-height: 28px;
      min-width: 28px;
      margin: 0 5px;
      text-align: center;
      background: var(--color-hint);
      border-radius: 3px;
      color: #fff;
      font-weight: 700;
      font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "\5FAE\8F6F\96C5\9ED1", SimSun, sans-serif; }
  .goods-package-list .goods-package-cart-main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px; }
    .goods-package-list .goods-package-cart-main .img-box {
      display: inline-block;
      vertical-align: top;
      width: 95px;
      height: 95px;
      margin-right: 10px;
      text-align: center;
      line-height: 95px;
      overflow: hidden;
      border: 1px solid var(--border-deep); }
      .goods-package-list .goods-package-cart-main .img-box img {
        max-width: 100%;
        max-height: 100%; }
    .goods-package-list .goods-package-cart-main .detail {
      font-weight: 700; }
    .goods-package-list .goods-package-cart-main .cart-info {
      line-height: 1;
      padding: 20px 0 28px; }
      .goods-package-list .goods-package-cart-main .cart-info .tips {
        font-size: 12px;
        color: var(--font-tips);
        margin-bottom: 10px; }
      .goods-package-list .goods-package-cart-main .cart-info .price-box {
        display: flex;
        justify-content: space-between; }
        .goods-package-list .goods-package-cart-main .cart-info .price-box .label {
          line-height: 48px;
          font-size: 14px; }
          .goods-package-list .goods-package-cart-main .cart-info .price-box .label .price {
            color: var(--color-hint); }

.cheaper-price {
  display: inline-block;
  padding-right: 10px;
  height: 24px;
  line-height: 20px;
  border: 2px solid #FA3815;
  border-radius: 2px;
  overflow: hidden;
  color: #FA3815;
  font-size: 14px;
  font-weight: 600; }
  .cheaper-price span {
    position: relative;
    display: inline-block;
    width: 28px;
    vertical-align: top;
    text-align: center;
    background: #FA3815;
    color: #fff;
    height: 140%;
    line-height: 20px;
    overflow: hidden; }

.package-price-box {
  display: flex;
  padding: 0 24px 12px 24px;
  font-size: 15px;
  font-weight: 700; }
  .package-price-box .flex-item {
    display: flex; }
  .package-price-box .flex-item:nth-child(1) {
    margin-right: 36px; }
    .package-price-box .flex-item:nth-child(1) .label {
      color: var(--color-hint); }

.goods-package-list-wrap {
  width: 1200px;
  min-width: 1200px;
  margin: 10px auto; }
  .goods-package-list-wrap .ant-pagination {
    padding: 32px 0 8px;
    text-align: center; }
  .goods-package-list-wrap .ant-spin {
    display: block;
    padding: 48px 0 36px; }
  .goods-package-list-wrap .ant-empty {
    padding: 48px 0 36px; }

.goods-batch-order-box {
  background-color: #fff;
  position: relative; }
  .goods-batch-order-box .goods-batch-order-wrap {
    width: 1200px;
    min-width: 1200px;
    height: 700px;
    margin: 10px auto;
    padding: 0 24px 24px 24px; }
    .goods-batch-order-box .goods-batch-order-wrap .cont-table {
      height: 600px;
      overflow-y: auto; }
  .goods-batch-order-box .operate-btn-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 40px;
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 0 12px 0 24px;
    background: #f8f8f8; }
    .goods-batch-order-box .operate-btn-box .info {
      font-size: 12px;
      color: #999; }
    .goods-batch-order-box .operate-btn-box .btn-group {
      height: 100%; }
      .goods-batch-order-box .operate-btn-box .btn-group .btn {
        width: 100px;
        height: 100%;
        border: none;
        color: #fff;
        background-color: var(--color); }
      .goods-batch-order-box .operate-btn-box .btn-group .prev-btn {
        background-color: #fff;
        color: var(--color);
        margin-right: 12px;
        border: 1px solid var(--color); }
  .goods-batch-order-box .step-box {
    padding: 12px 24px 12px 12px;
    color: var(--font-tips); }
    .goods-batch-order-box .step-box .mark {
      color: var(--font-title); }
  .goods-batch-order-box .import-info-main-box {
    display: flex;
    justify-content: space-between; }
  .goods-batch-order-box .import-info-tit {
    font-size: 18px;
    color: #333;
    margin-bottom: 20px; }
  .goods-batch-order-box .import-info {
    margin-bottom: 20px;
    padding: 24px;
    background: rgba(248, 248, 248, 0.5); }
    .goods-batch-order-box .import-info .txt {
      font-size: 12px;
      margin-bottom: 12px;
      padding-left: 20px;
      color: #999;
      line-height: 1.5; }
      .goods-batch-order-box .import-info .txt p {
        margin-bottom: 6px; }
  .goods-batch-order-box .import-info-main {
    margin: 0 0 10px 20px; }
    .goods-batch-order-box .import-info-main .select-width {
      width: 320px; }
    .goods-batch-order-box .import-info-main .tit {
      width: 60px; }
    .goods-batch-order-box .import-info-main .download-txt {
      padding: 6px 10px;
      display: inline-block;
      vertical-align: middle;
      font-size: 14px;
      color: var(--font-tips);
      line-height: 1em;
      cursor: pointer;
      background-color: #fff;
      border: 1px solid var(--border-deep);
      border-radius: 3px; }
      .goods-batch-order-box .import-info-main .download-txt i {
        font-size: 14px; }
  .goods-batch-order-box .import-box-main {
    width: 500px;
    font-size: 0; }
    .goods-batch-order-box .import-box-main .txt {
      display: inline-block;
      vertical-align: middle;
      width: 380px;
      height: 36px;
      font-size: 14px;
      padding-left: 1em;
      background: #fff;
      border: 1px solid #E3E3E3;
      border-radius: 4px 0 0 4px;
      margin: 0;
      line-height: 1em;
      color: #333; }
    .goods-batch-order-box .import-box-main .import-btn {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      width: 120px;
      height: 36px;
      text-align: center;
      line-height: 36px;
      border-radius: 0 4px 4px 0;
      font-size: 14px;
      background: var(--color);
      color: #fff;
      cursor: pointer; }
      .goods-batch-order-box .import-box-main .import-btn input[type="file"] {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0; }

.custom-wrap {
  padding-bottom: 10px; }
  .custom-wrap .info-box {
    font-size: 14px;
    color: var(--font-tips);
    margin-top: 5px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; }
    .custom-wrap .info-box .format {
      display: inline-block;
      max-width: 100%;
      padding: 0 8px;
      line-height: 18px;
      background: #f5f5f5;
      border-radius: 50px; }
    .custom-wrap .info-box .format-item {
      font-size: 11px; }
    .custom-wrap .info-box .format-item:nth-child(1) {
      margin-right: 5px; }
  .custom-wrap .custom-info-box {
    height: auto; }

.cart-page-wrap {
  width: 1200px;
  min-width: 1200px;
  min-height: 400px;
  padding: 12px 14px 64px;
  margin: 0 auto;
  background: #fff; }
  .cart-page-wrap .ant-spin {
    display: block;
    padding: 48px 0 36px; }
  .cart-page-wrap .ant-empty {
    padding: 48px 0 36px; }

.cart-table .cart-table-head {
  display: flex;
  align-items: center;
  height: 50px;
  border: 1px solid var(--border-deep);
  background: #F5F5F5; }
  .cart-table .cart-table-head .ant-checkbox-wrapper {
    padding: 0 10px; }
  .cart-table .cart-table-head .item {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    padding: 10px; }

.cart-table .cart-table-body-order-promotion-box .order-promotion-box {
  font-size: 14px; }
  .cart-table .cart-table-body-order-promotion-box .order-promotion-box .tag {
    display: inline-block;
    vertical-align: middle;
    margin-right: 4px;
    padding: 0 8px;
    height: 18px;
    font-size: 12px;
    line-height: 16px;
    border: 1px solid #FF6350;
    color: #FF6350;
    border-radius: 4px; }
  .cart-table .cart-table-body-order-promotion-box .order-promotion-box .text {
    display: inline-block;
    vertical-align: middle;
    margin-right: 4px;
    font-size: 14px;
    color: var(--font-title); }
  .cart-table .cart-table-body-order-promotion-box .order-promotion-box .tips {
    display: inline-block;
    vertical-align: middle;
    margin-right: 4px;
    color: #FF6350; }
  .cart-table .cart-table-body-order-promotion-box .order-promotion-box .btn {
    display: inline-block;
    vertical-align: middle;
    margin-right: 4px;
    padding: 0 8px;
    height: 20px;
    font-size: 12px;
    line-height: 18px;
    border: 1px solid var(--border-deep);
    color: var(--font-normal);
    border-radius: 4px;
    cursor: pointer; }
    .cart-table .cart-table-body-order-promotion-box .order-promotion-box .btn .iconfont {
      font-size: 12px;
      margin-left: 4px; }

.cart-table .cart-table-body-head {
  line-height: 32px;
  margin-top: 8px;
  font-size: 14px;
  color: var(--font-title); }
  .cart-table .cart-table-body-head .col {
    margin: 0 12px; }
    .cart-table .cart-table-body-head .col .time,
    .cart-table .cart-table-body-head .col .icon-goods-tag-delivery,
    .cart-table .cart-table-body-head .col .icon-goods-tag-shipments {
      color: var(--font-high); }
  .cart-table .cart-table-body-head .ant-checkbox-wrapper {
    padding: 10px;
    margin-right: 10px; }

.cart-table .cart-table-body-tr {
  border: 1px solid #E3E3E3;
  padding: 4px 0 6px;
  margin-top: -1px; }
  .cart-table .cart-table-body-tr .tr-expand {
    padding-left: 10px; }
    .cart-table .cart-table-body-tr .tr-expand .iconfont {
      color: var(--color);
      margin-right: 8px; }
    .cart-table .cart-table-body-tr .tr-expand .col {
      margin-right: 16px;
      color: var(--font-tips); }
    .cart-table .cart-table-body-tr .tr-expand .remark-input {
      width: 390px; }
  .cart-table .cart-table-body-tr .tr-expand-check {
    padding-left: 46px; }
  .cart-table .cart-table-body-tr .tr-body {
    display: flex; }
    .cart-table .cart-table-body-tr .tr-body .ant-checkbox-wrapper {
      padding: 10px; }
    .cart-table .cart-table-body-tr .tr-body .check-hint {
      margin: 10px 0 0 6px;
      font-size: 12px;
      line-height: 18px;
      height: 18px;
      width: 46px;
      text-align: center;
      border-radius: 8px;
      color: #fff;
      background-color: #999; }
    .cart-table .cart-table-body-tr .tr-body .item {
      flex: 1 1 auto;
      padding: 10px;
      word-break: break-word; }
    .cart-table .cart-table-body-tr .tr-body .img-box {
      width: 80px;
      height: 80px;
      text-align: center;
      line-height: 74px;
      border: 1px solid var(--border-deep);
      overflow: hidden; }
      .cart-table .cart-table-body-tr .tr-body .img-box img {
        max-width: 100%;
        max-height: 100%; }
    .cart-table .cart-table-body-tr .tr-body .tips {
      font-size: 12px;
      color: var(--font-tips);
      margin-top: 6px; }
    .cart-table .cart-table-body-tr .tr-body .price {
      color: var(--font-high); }
    .cart-table .cart-table-body-tr .tr-body .text-btn {
      border: 0 none;
      cursor: pointer;
      color: var(--font-title);
      background: transparent; }

.page-cart-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1200px;
  margin: 0 auto;
  min-height: 48px;
  max-height: 96px;
  padding: 0 0 0 24px;
  border-top: 1px solid var(--border-deep);
  border-bottom: 1px solid var(--border-deep);
  background: #FCFCFC; }
  .page-cart-footer .opt-btn {
    font-size: 14px;
    color: var(--font-normal);
    margin: 0 16px 0 24px;
    background: transparent;
    border: 0 none;
    cursor: pointer; }
    .page-cart-footer .opt-btn:hover {
      color: var(--font-title); }
  .page-cart-footer .btn {
    width: 114px;
    height: 48px;
    font-size: 16px;
    color: #fff;
    margin-left: 24px;
    border: 0 none;
    background: var(--color); }
    .page-cart-footer .btn:hover, .page-cart-footer .btn:focus {
      cursor: pointer;
      background: var(--color-active); }
    .page-cart-footer .btn:disabled {
      background: var(--disabled);
      cursor: not-allowed; }
  .page-cart-footer .text {
    margin-right: 24px;
    font-size: 14px;
    color: var(--font-normal); }
    .page-cart-footer .text .hint {
      font-size: 18px;
      font-weight: 700;
      color: var(--font-high);
      margin: 0 6px; }

.page-crumb {
  line-height: 58px;
  padding: 0 24px;
  font-size: 14px;
  border-bottom: 1px solid var(--border-deep);
  color: var(--font-title); }
  .page-crumb .iconfont {
    font-size: 12px; }

.order-create-wrap {
  padding: 0 14px; }
  .order-create-wrap .cart-table .cart-table-head .item {
    text-align: center; }
    .order-create-wrap .cart-table .cart-table-head .item:nth-child(2), .order-create-wrap .cart-table .cart-table-head .item:nth-child(3) {
      text-align: left; }
  .order-create-wrap .cart-table .cart-table-body-tr .tr-body .item {
    text-align: center; }
    .order-create-wrap .cart-table .cart-table-body-tr .tr-body .item:nth-child(2), .order-create-wrap .cart-table .cart-table-body-tr .tr-body .item:nth-child(3) {
      text-align: left; }
  .order-create-wrap .cart-table .cart-table-body-tr .tr-body .img-box {
    width: 64px;
    height: 64px;
    line-height: 58px;
    text-align: center; }

.create-order-headline {
  height: 50px;
  padding-top: 18px;
  line-height: 32px;
  margin-bottom: 16px;
  font-size: 14px;
  color: var(--font-title);
  border-bottom: 1px solid var(--border-deep); }
  .create-order-headline:before {
    content: "";
    display: inline-block;
    width: 2px;
    height: 14px;
    margin-right: 6px;
    background: var(--font-title); }
  .create-order-headline .txt-btn {
    float: right;
    height: 32px;
    font-size: 14px;
    border: 0 none;
    background: transparent;
    color: var(--color); }
  .create-order-headline .btn {
    float: right;
    height: 32px;
    font-size: 14px;
    border: 0 none;
    background: transparent;
    margin-left: 10px; }

.create-order-expand {
  display: flex;
  padding: 16px 0 0;
  flex-wrap: wrap; }
  .create-order-expand .expand-item-card {
    flex: 1;
    max-width: 33.3333%;
    padding-right: 40px;
    margin-bottom: 8px; }
    .create-order-expand .expand-item-card .title-box {
      font-size: 14px;
      color: var(--font-title);
      margin-bottom: 8px; }
    .create-order-expand .expand-item-card .main-box .ant-radio-wrapper {
      display: block;
      margin-bottom: 8px; }
    .create-order-expand .expand-item-card .tax-row {
      font-size: 14px; }
      .create-order-expand .expand-item-card .tax-row .btn {
        margin-left: 1em;
        color: #1A81E8;
        cursor: pointer; }

.create-order-total {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 20px; }
  .create-order-total td:first-child {
    text-align: right;
    padding-right: 8px; }
  .create-order-total .price {
    font-size: 18px;
    color: var(--font-high); }
  .create-order-total .icon-shuoming {
    color: #F5A623;
    margin-right: 2px;
    font-size: 16px;
    cursor: pointer; }
  .create-order-total .select-tag {
    cursor: pointer; }
    .create-order-total .select-tag .tag {
      font-size: 12px;
      line-height: 16px;
      padding: 0 6px;
      border-radius: 2px;
      color: var(--font-high);
      border: 1px solid var(--color-hint); }
    .create-order-total .select-tag .icon-jump {
      font-size: 12px;
      margin-left: 4px;
      color: var(--font-high); }

.create-order-address {
  display: flex;
  flex: 0;
  flex-wrap: wrap; }
  .create-order-address .ant-empty {
    width: 100%; }
  .create-order-address .address-card {
    position: relative;
    width: 350px;
    padding: 16px;
    margin: 0 24px 16px 0;
    font-size: 14px;
    border: 1px solid var(--border-deep);
    border-radius: 4px;
    cursor: pointer; }
    .create-order-address .address-card .icon-selected {
      position: absolute;
      right: -1px;
      bottom: -1px;
      line-height: 1;
      font-size: 18px;
      text-align: right;
      visibility: hidden;
      color: var(--color); }
    .create-order-address .address-card.address-card-selected {
      border: 2px solid var(--color); }
      .create-order-address .address-card.address-card-selected .icon-selected {
        visibility: visible; }
    .create-order-address .address-card .head {
      line-height: 38px;
      margin-bottom: 12px;
      color: var(--font-title);
      border-bottom: 1px solid var(--border-deep); }
      .create-order-address .address-card .head .tips {
        float: right; }
    .create-order-address .address-card .body {
      color: var(--font-normal);
      line-height: 22px; }
    .create-order-address .address-card .operate {
      position: absolute;
      right: 16px;
      bottom: 16px;
      font-size: 14px;
      color: var(--font-tips); }
      .create-order-address .address-card .operate .btn:hover {
        color: var(--color); }

.create-order-upload {
  margin-top: 10px; }
  .create-order-upload .headline {
    font-size: 14px;
    margin-bottom: 8px;
    color: var(--font-tips); }
  .create-order-upload .image-upload-btn {
    width: 80px;
    height: 80px;
    padding-bottom: 0; }

.order-promotion-box {
  padding: 15px 16px;
  border: 1px solid var(--border-deep);
  margin: 12px 0;
  font-size: 14px; }
  .order-promotion-box .tag {
    display: inline-block;
    vertical-align: middle;
    margin-right: 4px;
    padding: 0 8px;
    height: 18px;
    font-size: 12px;
    line-height: 16px;
    border: 1px solid #FF6350;
    color: #FF6350;
    border-radius: 4px; }
  .order-promotion-box .text {
    display: inline-block;
    vertical-align: middle;
    margin-right: 4px;
    font-size: 14px;
    color: var(--font-title); }
  .order-promotion-box .tips {
    display: inline-block;
    vertical-align: middle;
    margin-right: 4px;
    color: #FF6350; }
  .order-promotion-box .btn {
    display: inline-block;
    vertical-align: middle;
    margin-right: 4px;
    padding: 0 8px;
    height: 20px;
    font-size: 12px;
    line-height: 18px;
    border: 1px solid var(--border-deep);
    color: var(--font-normal);
    border-radius: 4px;
    cursor: pointer; }
    .order-promotion-box .btn .iconfont {
      font-size: 12px;
      margin-left: 4px; }

.present-popover-box .ant-popover-inner-content {
  padding: 0; }

.present-info-wrap {
  width: 240px;
  height: 300px;
  overflow: auto;
  padding: 12px; }
  .present-info-wrap .present-card {
    width: 100%;
    display: flex;
    align-items: center;
    align-content: center;
    font-size: 12px;
    margin: 12px 0;
    cursor: pointer; }
    .present-info-wrap .present-card:hover {
      color: var(--color); }
    .present-info-wrap .present-card .img-box {
      width: 50px;
      height: 50px;
      text-align: center;
      line-height: 50px;
      margin-right: 12px; }
      .present-info-wrap .present-card .img-box img {
        max-width: 100%;
        max-height: 100%; }
    .present-info-wrap .present-card .info-box {
      flex: 1;
      width: 0; }
      .present-info-wrap .present-card .info-box .hint {
        color: var(--font-high); }

.goods-promotion-box {
  font-size: 14px; }
  .goods-promotion-box .tag {
    display: inline-block;
    vertical-align: middle;
    margin-right: 4px;
    padding: 0 8px;
    height: 18px;
    font-size: 12px;
    line-height: 18px;
    background: #FF6350;
    color: #fff;
    border-radius: 3px; }
  .goods-promotion-box .text {
    display: inline-block;
    vertical-align: middle;
    margin-right: 4px;
    color: var(--font-title); }
  .goods-promotion-box .tips {
    display: inline-block;
    vertical-align: middle;
    margin-right: 4px;
    color: #FF6350; }
  .goods-promotion-box .btn {
    display: inline-block;
    vertical-align: middle;
    color: #3399FF;
    cursor: pointer; }

.order-promotion-present-box .cart-table-body-tr {
  border-bottom: 0 none; }
  .order-promotion-present-box .cart-table-body-tr .tr-body .item {
    padding: 0 10px; }
    .order-promotion-present-box .cart-table-body-tr .tr-body .item .img-box {
      display: inline-block;
      width: 40px;
      height: 40px;
      text-align: center;
      line-height: 32px; }

.cart-free-goods-box {
  margin-top: 12px;
  border: 1px solid var(--border-deep); }
  .cart-free-goods-box .headline {
    padding: 0 16px;
    line-height: 46px;
    color: var(--font-title); }
    .cart-free-goods-box .headline .tag {
      display: inline-block;
      line-height: 18px;
      padding: 0 12px;
      margin-right: 8px;
      border: 1px solid var(--color-hint);
      color: var(--color-hint);
      border-radius: 4px; }
    .cart-free-goods-box .headline .btn {
      border: 0 none;
      margin-left: 16px;
      background-color: transparent;
      color: #3399FF; }
      .cart-free-goods-box .headline .btn .iconfont {
        font-size: 12px; }
  .cart-free-goods-box .cart-table-body {
    margin: 0 16px;
    border-top: 1px solid var(--border-deep); }
    .cart-free-goods-box .cart-table-body .cart-table-body-tr {
      border: 0 none; }
      .cart-free-goods-box .cart-table-body .cart-table-body-tr .img-box {
        width: 40px;
        height: 40px;
        line-height: 38px; }
        .cart-free-goods-box .cart-table-body .cart-table-body-tr .img-box img {
          vert-align: top; }

.cart-free-goods-list {
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  margin: -24px;
  padding: 24px;
  max-height: 440px;
  overflow: auto; }
  .cart-free-goods-list .free-goods-item {
    width: 50%;
    display: flex;
    align-items: center;
    font-size: 14px; }
    .cart-free-goods-list .free-goods-item:nth-child(2n+1) {
      padding-right: 8px; }
    .cart-free-goods-list .free-goods-item:nth-child(2n) {
      padding-left: 8px; }
    .cart-free-goods-list .free-goods-item .img-box {
      width: 80px;
      height: 80px;
      flex: 0 0 auto;
      margin: 0 16px 0 8px; }
    .cart-free-goods-list .free-goods-item .cont-box .name {
      color: var(--font-title); }
    .cart-free-goods-list .free-goods-item .cont-box .format {
      color: var(--font-tips); }
    .cart-free-goods-list .free-goods-item .cont-box del {
      padding-left: 4px;
      color: var(--font-tips); }

.shop-cart-goods-promotion {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  padding: 0 12px; }
  .shop-cart-goods-promotion .tag {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    vertical-align: middle;
    font-size: 10px;
    color: #fff;
    background: #ff3838;
    border-radius: 3px;
    padding: 0 10px;
    width: 50px;
    height: 16px;
    margin-right: 8px; }
    .shop-cart-goods-promotion .tag i {
      flex: 1;
      text-align: center; }
  .shop-cart-goods-promotion .txt {
    font-size: 12px;
    color: var(--font-normal);
    vertical-align: middle; }
    .shop-cart-goods-promotion .txt .text {
      margin-right: 10px; }
    .shop-cart-goods-promotion .txt .tips {
      color: #3399FF; }
  .shop-cart-goods-promotion .txt-meet {
    color: var(--font-title) !important; }

.goods-package-tag {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  vertical-align: middle;
  font-size: 10px;
  color: #fff;
  background: #ff3838;
  border-radius: 3px;
  padding: 0 10px;
  width: 50px;
  height: 16px;
  margin-right: 8px; }
  .goods-package-tag i {
    flex: 1;
    text-align: center; }

.order-pay-head-wrap {
  background: #fff;
  margin-bottom: 12px;
  padding: 0 24px; }
  .order-pay-head-wrap .pay-info-wrap {
    display: flex;
    padding: 16px 0;
    justify-content: space-between; }
    .order-pay-head-wrap .pay-info-wrap .left-wrap .svg-icon {
      display: inline-block;
      vertical-align: middle;
      width: 30px;
      height: 30px;
      margin-right: 8px;
      margin-top: -4px; }
    .order-pay-head-wrap .pay-info-wrap .left-wrap .title {
      display: inline-block;
      vertical-align: middle;
      font-size: 18px;
      color: var(--font-title);
      margin-bottom: 8px; }
    .order-pay-head-wrap .pay-info-wrap .left-wrap .tips {
      font-size: 16px;
      color: var(--font-normal);
      padding-left: 38px; }
    .order-pay-head-wrap .pay-info-wrap .left-wrap .time {
      font-size: 16px;
      color: var(--font-normal);
      padding-left: 38px; }
      .order-pay-head-wrap .pay-info-wrap .left-wrap .time i {
        color: var(--font-high); }
    .order-pay-head-wrap .pay-info-wrap .right-wrap {
      text-align: right;
      font-size: 14px;
      color: var(--font-normal); }
      .order-pay-head-wrap .pay-info-wrap .right-wrap .money-info-wrap {
        margin-bottom: 17px; }
        .order-pay-head-wrap .pay-info-wrap .right-wrap .money-info-wrap span {
          color: var(--font-high); }
          .order-pay-head-wrap .pay-info-wrap .right-wrap .money-info-wrap span.wait-pay {
            font-weight: bold;
            font-size: 28px; }
      .order-pay-head-wrap .pay-info-wrap .right-wrap .order-btn {
        color: #1C90ED;
        cursor: pointer; }
        .order-pay-head-wrap .pay-info-wrap .right-wrap .order-btn .iconfont {
          font-size: 12px;
          margin-left: 4px; }
  .order-pay-head-wrap .order-info-wrap {
    padding: 24px 0;
    line-height: 1;
    border-top: 1px solid var(--border-deep); }
    .order-pay-head-wrap .order-info-wrap dt {
      font-size: 16px;
      color: var(--font-title);
      margin-bottom: 12px; }
      .order-pay-head-wrap .order-info-wrap dt .to-detail-btn {
        float: right;
        color: var(--font-normal);
        font-weight: normal; }
    .order-pay-head-wrap .order-info-wrap dd {
      font-size: 16px;
      color: var(--font-normal); }
      .order-pay-head-wrap .order-info-wrap dd:not(:last-child) {
        margin-bottom: 12px; }

.order-pay-body-wrap {
  padding-bottom: 30px; }
  .order-pay-body-wrap .pay-money-wrap {
    padding: 24px;
    font-size: 18px;
    color: var(--font-title);
    margin-bottom: 8px;
    background: #fff; }
  .order-pay-body-wrap .pay-main-wrap .pay-mode-box {
    background: #fff;
    margin-bottom: 10px; }
    .order-pay-body-wrap .pay-main-wrap .pay-mode-box .pay-type-item {
      display: flex;
      align-content: center;
      align-items: center;
      justify-content: space-between;
      height: 60px;
      font-size: 14px;
      color: var(--font-title);
      padding: 0 24px;
      border-bottom: 1px solid var(--border-deep); }
      .order-pay-body-wrap .pay-main-wrap .pay-mode-box .pay-type-item .svg-icon {
        display: inline-block;
        vertical-align: middle;
        width: 32px;
        height: 32px;
        margin-right: 8px; }
      .order-pay-body-wrap .pay-main-wrap .pay-mode-box .pay-type-item .txt {
        display: inline-block;
        vertical-align: middle;
        font-size: 16px;
        color: #222;
        font-weight: bold; }
        .order-pay-body-wrap .pay-main-wrap .pay-mode-box .pay-type-item .txt span {
          font-size: 14px;
          color: var(--font-normal);
          font-weight: normal; }
      .order-pay-body-wrap .pay-main-wrap .pay-mode-box .pay-type-item .bank-txt {
        display: inline-block;
        padding-left: 4px;
        vertical-align: middle;
        font-size: 12px;
        color: var(--color);
        cursor: pointer; }
        .order-pay-body-wrap .pay-main-wrap .pay-mode-box .pay-type-item .bank-txt i {
          display: inline-block;
          margin-right: 4px; }
    .order-pay-body-wrap .pay-main-wrap .pay-mode-box .pay-total-box {
      height: 48px;
      line-height: 48px;
      padding: 0 24px;
      font-size: 16px;
      color: #000;
      font-weight: bold;
      text-align: right; }
  .order-pay-body-wrap .pay-main-wrap dt {
    padding: 16px 0;
    border-bottom: 1px solid var(--border-deep);
    margin-bottom: 18px;
    line-height: 1; }
    .order-pay-body-wrap .pay-main-wrap dt .iconfont {
      display: inline-block;
      vertical-align: middle;
      font-size: 16px;
      margin-right: 8px;
      cursor: pointer;
      color: var(--font-tips); }
    .order-pay-body-wrap .pay-main-wrap dt .icon-check-s {
      color: var(--color); }
    .order-pay-body-wrap .pay-main-wrap dt .svg-icon {
      display: inline-block;
      vertical-align: middle;
      width: 32px;
      height: 32px;
      margin-right: 8px; }
    .order-pay-body-wrap .pay-main-wrap dt .txt {
      display: inline-block;
      vertical-align: middle;
      font-size: 16px;
      color: #222;
      font-weight: bold; }
      .order-pay-body-wrap .pay-main-wrap dt .txt span {
        font-size: 14px;
        color: var(--font-normal);
        font-weight: normal; }
    .order-pay-body-wrap .pay-main-wrap dt .tips {
      padding-left: 28px;
      font-size: 12px;
      color: var(--font-normal); }
    .order-pay-body-wrap .pay-main-wrap dt .right-box {
      font-size: 14px;
      color: var(--font-normal);
      line-height: 32px; }
  .order-pay-body-wrap .pay-main-wrap dd {
    margin-bottom: 18px; }
    .order-pay-body-wrap .pay-main-wrap dd .svg-icon {
      display: inline-block;
      vertical-align: middle;
      width: 32px;
      height: 32px;
      margin-right: 8px; }
    .order-pay-body-wrap .pay-main-wrap dd .txt {
      display: inline-block;
      vertical-align: middle;
      font-size: 16px;
      color: #222;
      font-weight: bold; }
      .order-pay-body-wrap .pay-main-wrap dd .txt span {
        font-size: 14px;
        color: var(--font-normal);
        font-weight: normal; }
    .order-pay-body-wrap .pay-main-wrap dd .bank-txt {
      display: inline-block;
      padding-left: 4px;
      vertical-align: middle;
      font-size: 12px;
      color: var(--color);
      cursor: pointer; }
      .order-pay-body-wrap .pay-main-wrap dd .bank-txt i {
        display: inline-block;
        margin-right: 4px; }
  .order-pay-body-wrap .order-pay-remark {
    border: 0 none;
    background: #fff;
    padding: 16px 24px;
    margin-bottom: 10px; }
    .order-pay-body-wrap .order-pay-remark .txt-red {
      margin-bottom: 4px; }
    .order-pay-body-wrap .order-pay-remark .image-upload {
      margin-top: 12px; }
  .order-pay-body-wrap .btn-box {
    text-align: right; }
    .order-pay-body-wrap .btn-box .btn {
      display: inline-block;
      vertical-align: middle;
      width: 180px;
      height: 48px;
      background: var(--color);
      border-radius: 4px;
      border: 0 none;
      margin-left: 20px;
      font-size: 16px;
      line-height: 48px;
      text-align: center;
      color: #fff;
      cursor: pointer; }
      .order-pay-body-wrap .btn-box .btn:nth-child(1) {
        background: #fff;
        color: var(--color);
        border: 1px solid var(--color); }

.page-order-pay-result {
  margin: 10px auto;
  padding: 22px 16px; }

.order-pay-result-head {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  padding: 32px 0;
  font-weight: 700;
  background: #EFFAEC; }
  .order-pay-result-head .svg-icon {
    width: 56px;
    height: 56px;
    margin-right: 16px; }

.order-pay-result-detail table {
  margin: 16px auto; }
  .order-pay-result-detail table td {
    padding: 2px; }

.order-pay-result-operate {
  text-align: center; }
  .order-pay-result-operate .btn {
    width: 116px;
    height: 32px;
    margin: 4px;
    font-size: 14px;
    color: var(--font-normal);
    border: 1px solid var(--border-deep);
    background: transparent; }

.order-list-page {
  margin: 16px 0; }
  .order-list-page .ant-pagination {
    padding: 32px 0 8px;
    text-align: center; }
  .order-list-page .ant-spin {
    display: block;
    padding: 48px 0 36px; }
  .order-list-page .ant-empty {
    padding: 48px 0 36px; }
  .order-list-page .order-list-content {
    padding: 16px;
    min-height: 400px; }
  .order-list-page .order-list-head {
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    margin-bottom: 8px; }
    .order-list-page .order-list-head .tabs-box .tab {
      display: inline-block;
      vertical-align: middle;
      margin: 0 14px;
      font-size: 14px;
      color: var(--font-normal);
      padding-bottom: 4px;
      border-bottom: 2px solid transparent;
      cursor: pointer; }
      .order-list-page .order-list-head .tabs-box .tab:hover {
        color: var(--color); }
      .order-list-page .order-list-head .tabs-box .tab.active {
        color: var(--color);
        border-color: var(--color); }
    .order-list-page .order-list-head .search-box .wrap-box {
      display: flex;
      justify-content: center;
      align-items: center; }
      .order-list-page .order-list-head .search-box .wrap-box .batch-pay-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 108px;
        min-width: 108px;
        max-width: 208px;
        height: 32px;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        margin-right: 10px;
        color: var(--color); }
      .order-list-page .order-list-head .search-box .wrap-box .batch-pay-btn:hover {
        cursor: pointer; }
    .order-list-page .order-list-head .search-box .input-wrap, .order-list-page .order-list-head .search-box .select-box {
      display: inline-block;
      vertical-align: middle; }
    .order-list-page .order-list-head .search-box .input-wrap {
      margin-right: 8px; }
  .order-list-page .order-list-body table {
    width: 100%;
    font-size: 14px;
    color: var(--font-normal);
    table-layout: fixed; }
    .order-list-page .order-list-body table thead tr {
      border: 1px solid var(--border-deep); }
    .order-list-page .order-list-body table thead th {
      background: #f5f5f5;
      line-height: 24px;
      padding: 8px 16px;
      text-align: center;
      vertical-align: middle;
      width: 128px; }
      .order-list-page .order-list-body table thead th:nth-child(1) {
        text-align: left;
        width: 416px; }
      .order-list-page .order-list-body table thead th:nth-child {
        width: 162px; }
    .order-list-page .order-list-body table tbody:before {
      content: "";
      display: block;
      height: 16px; }
    .order-list-page .order-list-body table tbody tr:first-child {
      background-color: #f5f5f5;
      line-height: 40px;
      padding: 0 16px; }
    .order-list-page .order-list-body table tbody td {
      padding: 16px;
      text-align: center;
      border: 1px solid var(--border-deep); }
      .order-list-page .order-list-body table tbody td:first-child {
        text-align: left;
        padding: 0; }
      .order-list-page .order-list-body table tbody td .ant-btn {
        margin-bottom: 8px; }
    .order-list-page .order-list-body table .col {
      display: inline-block;
      padding: 0 16px;
      margin-right: 32px;
      color: var(--font-tips); }
    .order-list-page .order-list-body table .code {
      color: var(--font-normal); }
    .order-list-page .order-list-body table .status {
      color: var(--font-tips); }
    .order-list-page .order-list-body table .link {
      display: block;
      color: var(--font-title); }
      .order-list-page .order-list-body table .link:hover {
        color: var(--color); }
    .order-list-page .order-list-body table .text {
      display: inline-block;
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      word-break: break-all; }
    .order-list-page .order-list-body table .img-box {
      display: inline-block;
      width: 100px;
      height: 100px;
      text-align: center;
      line-height: 100px;
      position: relative; }
      .order-list-page .order-list-body table .img-box img {
        max-width: 100%;
        max-height: 100%; }
      .order-list-page .order-list-body table .img-box:after {
        content: attr(data-number);
        display: inline-block;
        width: 100%;
        height: 24px;
        line-height: 24px;
        text-align: center;
        font-size: 12px;
        color: #fff;
        background: rgba(0, 0, 0, 0.8);
        position: absolute;
        left: 0;
        bottom: 0; }
    .order-list-page .order-list-body table .item-list {
      vertical-align: top; }
      .order-list-page .order-list-body table .item-list .item {
        display: flex;
        justify-content: space-between;
        padding: 22px 16px;
        border-bottom: 1px solid var(--border-deep); }
        .order-list-page .order-list-body table .item-list .item:last-child {
          border-bottom: 0; }
        .order-list-page .order-list-body table .item-list .item .name {
          flex: 1;
          padding-right: 16px; }

.order-batch-list-page .order-list-body .footer-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 12px; }
  .order-batch-list-page .order-list-body .footer-box .total {
    font-size: 18px;
    font-weight: 700;
    color: var(--orangey-red); }
  .order-batch-list-page .order-list-body .footer-box .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 108px;
    height: 32px;
    color: var(--color);
    border: 1px solid var(--color);
    border-radius: 4px;
    margin-right: 10px; }
  .order-batch-list-page .order-list-body .footer-box .btn:hover {
    cursor: pointer; }

.order-detail-page {
  padding: 16px;
  background: #fff; }
  .order-detail-page .ant-spin {
    width: 100%;
    text-align: center;
    margin: 48px 0; }

.order-detail-chunk {
  display: flex;
  padding: 16px 0;
  margin-bottom: 16px;
  border: 1px solid var(--border-deep); }
  .order-detail-chunk .chunk-column {
    flex: 1;
    padding: 0 16px;
    border-left: 1px solid var(--border-deep); }
    .order-detail-chunk .chunk-column:first-child {
      border: 0 none; }
  .order-detail-chunk .order-detail-code {
    flex: 0 0 260px; }
    .order-detail-chunk .order-detail-code .status {
      font-size: 24px;
      font-weight: 600;
      margin-top: 16px;
      margin-bottom: 8px;
      text-align: center;
      color: var(--font-title); }
  .order-detail-chunk .order-detail-steps {
    display: flex;
    justify-content: center; }
    .order-detail-chunk .order-detail-steps .step {
      text-align: center;
      display: flex; }
      .order-detail-chunk .order-detail-steps .step:last-child .arrow {
        display: none; }
    .order-detail-chunk .order-detail-steps .step-active .iconfont {
      color: var(--color); }
    .order-detail-chunk .order-detail-steps .detail {
      width: 4em; }
    .order-detail-chunk .order-detail-steps .iconfont {
      font-size: 24px;
      color: var(--font-tips); }
    .order-detail-chunk .order-detail-steps .icon-progress-bar {
      font-size: 17px; }
    .order-detail-chunk .order-detail-steps .label {
      display: block;
      color: var(--font-title); }
    .order-detail-chunk .order-detail-steps .tips {
      display: inline-block;
      width: 90px;
      text-align: left;
      font-size: 12px;
      line-height: 1.3;
      color: var(--font-tips); }
    .order-detail-chunk .order-detail-steps .arrow {
      padding-top: 4px; }
  .order-detail-chunk .order-log-btn, .order-detail-chunk .pick-up-code {
    font-size: 14px;
    padding-top: 10px;
    padding-left: 42px;
    color: var(--font-normal);
    cursor: pointer; }
  .order-detail-chunk .pick-up-code {
    font-size: 16px;
    color: var(--orangey-red); }
  .order-detail-chunk dl dt {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    font-size: 14px;
    font-weight: 600; }
    .order-detail-chunk dl dt .btn-logistics {
      width: 80px;
      height: 24px;
      border: 1px solid var(--color);
      color: var(--color);
      background: transparent;
      border-radius: 2px; }
      .order-detail-chunk dl dt .btn-logistics:hover {
        color: var(--color-active);
        border-color: var(--color-active); }
    .order-detail-chunk dl dt .btn-pay {
      width: 80px;
      height: 24px;
      border: 1px solid var(--color);
      color: var(--color);
      background: transparent;
      border-radius: 2px; }
      .order-detail-chunk dl dt .btn-pay:hover {
        color: var(--color-active);
        border-color: var(--color-active); }
  .order-detail-chunk dl dd {
    display: flex;
    margin-bottom: 8px;
    font-size: 14px; }
    .order-detail-chunk dl dd .label {
      width: 70px;
      color: var(--font-normal); }
    .order-detail-chunk dl dd .value {
      flex: 1;
      width: 0;
      padding-left: 16px;
      color: var(--font-title); }
    .order-detail-chunk dl dd .img-box {
      display: inline-block;
      vertical-align: top;
      height: 70px;
      width: 70px;
      text-align: center;
      line-height: 70px;
      border: 1px solid #f5f5f5;
      margin-right: 4px;
      margin-bottom: 4px; }
      .order-detail-chunk dl dd .img-box img {
        max-width: 100%;
        max-height: 100%; }
    .order-detail-chunk dl dd .tips {
      cursor: pointer; }

.order-detail-table table {
  width: 100%;
  font-size: 14px;
  border: 1px solid var(--border-deep);
  color: var(--font-normal); }
  .order-detail-table table th {
    padding: 0 16px;
    line-height: 40px;
    background: #f5f5f5; }
    .order-detail-table table th:nth-child(1) {
      width: 30%;
      color: var(--font-title);
      font-weight: 600; }
    .order-detail-table table th:nth-child(2) {
      width: 10%; }
    .order-detail-table table th:nth-child(3) {
      width: 10%; }
    .order-detail-table table th:nth-child(4) {
      width: 10%; }
    .order-detail-table table th:nth-child(5) {
      width: 10%; }
    .order-detail-table table th:nth-child(6) {
      width: 10%; }
    .order-detail-table table th:nth-child(7) {
      width: 10%; }
    .order-detail-table table th:nth-child(8) {
      width: 20%;
      text-align: center; }
  .order-detail-table table td {
    padding: 16px;
    line-height: 22px;
    border-bottom: 1px solid var(--border-deep); }
    .order-detail-table table td:nth-child(1) {
      color: var(--font-title); }
    .order-detail-table table td:nth-child(8) {
      border-left: 1px solid var(--border-deep);
      text-align: center;
      vertical-align: middle; }
    .order-detail-table table td[rowSpan] {
      border-left: 1px solid var(--border-deep);
      border-right: 1px solid var(--border-deep);
      text-align: center; }
    .order-detail-table table td .promotion-tips-wrap {
      font-size: 14px;
      color: var(--font-tips); }
      .order-detail-table table td .promotion-tips-wrap p {
        color: var(--font-title); }
  .order-detail-table table .goods-card-item {
    display: flex; }
    .order-detail-table table .goods-card-item .img-box {
      width: 80px;
      height: 80px;
      text-align: center;
      line-height: 70px;
      border: 1px solid var(--border-deep);
      margin-right: 10px; }
      .order-detail-table table .goods-card-item .img-box img {
        max-width: 100%;
        max-height: 100%; }
    .order-detail-table table .goods-card-item .info-box {
      flex: 1; }
      .order-detail-table table .goods-card-item .info-box .title {
        font-size: 14px;
        color: var(--font-title);
        line-height: 1.5; }
      .order-detail-table table .goods-card-item .info-box .tips {
        font-size: 12px;
        color: var(--font-tips); }
  .order-detail-table table .opt-btn {
    background: transparent;
    border: 0 none; }
    .order-detail-table table .opt-btn.btn-disabled {
      color: var(--disabled); }

.order-total-table {
  border: 1px soild var(--border-deep);
  position: relative; }
  .order-total-table .type-hint {
    position: absolute;
    top: 12px;
    left: 0;
    font-size: 14px;
    color: var(--font-high); }
  .order-total-table table {
    margin-left: auto; }
    .order-total-table table td {
      padding: 10px 12px 0; }
      .order-total-table table td:nth-child(1) {
        text-align: right; }
    .order-total-table table .total {
      font-size: 16px;
      font-weight: 600;
      color: var(--font-high); }

.back-edit-box {
  min-height: 400px; }
  .back-edit-box .goods-info-box {
    margin-bottom: 16px; }
    .back-edit-box .goods-info-box table {
      width: 100%;
      table-layout: fixed;
      font-size: 12px;
      color: var(--font-title);
      font-weight: normal;
      text-align: left; }
    .back-edit-box .goods-info-box th {
      height: 32px;
      vertical-align: 32px;
      padding-right: 16px; }
    .back-edit-box .goods-info-box td {
      padding-right: 16px; }
      .back-edit-box .goods-info-box td .txt {
        display: inline-block;
        width: 100%; }
  .back-edit-box .remark-box .text-area {
    position: relative; }
    .back-edit-box .remark-box .text-area .ant-input-textarea-clear-icon {
      margin-right: 28px; }
    .back-edit-box .remark-box .text-area textarea {
      padding-bottom: 32px; }
    .back-edit-box .remark-box .text-area:after {
      content: attr(data-number);
      font-size: 14px;
      color: var(--font-tips);
      position: absolute;
      right: 28px;
      bottom: 6px; }
  .back-edit-box .remark-box .img-upload-box {
    margin-top: 16px; }
    .back-edit-box .remark-box .img-upload-box .title {
      margin-bottom: 8px;
      font-size: 14px;
      color: var(--font-tips); }

.back-result-box {
  margin-top: 40px;
  padding-top: 16px;
  border-top: 1px solid var(--border-deep); }
  .back-result-box li {
    font-size: 14px;
    color: var(--font-title);
    margin-bottom: 4px; }
    .back-result-box li span:first-child {
      color: var(--font-normal); }

.order-detail-out {
  margin-bottom: 16px;
  border: 1px solid var(--border-deep); }
  .order-detail-out .headline {
    padding: 0 16px;
    line-height: 40px;
    font-size: 14px;
    color: var(--font-normal);
    background: #f5f5f5; }
  .order-detail-out .out-list-item {
    display: flex;
    padding: 8px 16px;
    border-bottom: 1px solid var(--border-deep); }
    .order-detail-out .out-list-item:last-child {
      border-bottom: 0 none; }
    .order-detail-out .out-list-item .img-box {
      position: relative;
      width: 50px;
      height: 50px;
      text-align: center;
      line-height: 48px;
      overflow: hidden; }
      .order-detail-out .out-list-item .img-box .tips {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.5);
        color: #fff;
        font-size: 12px;
        line-height: 22px; }
    .order-detail-out .out-list-item .detail-box {
      padding: 0 16px;
      flex: 1;
      width: 0; }
      .order-detail-out .out-list-item .detail-box .name {
        color: var(--font-title); }
      .order-detail-out .out-list-item .detail-box .tips {
        color: var(--font-tips); }
    .order-detail-out .out-list-item .operate-box {
      align-self: center; }
  .order-detail-out .btn-logistics {
    width: 108px;
    height: 24px;
    border: 1px solid var(--border-deep);
    margin-right: 8px;
    color: var(--font-title);
    background: transparent;
    border-radius: 2px; }
    .order-detail-out .btn-logistics:hover {
      color: var(--color-active);
      border-color: var(--color-active); }
  .order-detail-out .btn-receive {
    width: 108px;
    height: 24px;
    border: 1px solid var(--color);
    color: var(--color);
    background: transparent;
    border-radius: 2px; }
    .order-detail-out .btn-receive:hover {
      color: var(--color-active);
      border-color: var(--color-active); }

.order-log-page {
  padding: 16px;
  background: #fff; }
  .order-log-page table {
    width: 100%;
    font-size: 14px;
    border: 1px solid var(--border-deep);
    color: var(--font-normal); }
    .order-log-page table th {
      padding: 0 16px;
      line-height: 40px;
      background: #f5f5f5; }
      .order-log-page table th:nth-child(1) {
        width: 250px;
        font-weight: 600; }
      .order-log-page table th:nth-child(2) {
        width: 680px; }
    .order-log-page table td {
      padding: 0 16px;
      line-height: 40px;
      border-bottom: 1px solid var(--border-deep); }

.pay-modal {
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 5;
  width: 600px;
  transform: translate(-50%, -50%); }
  .pay-modal .pay-modal-title {
    height: 42px;
    line-height: 42px;
    text-align: center;
    font-size: 16px;
    color: var(--font-title);
    background: #fcfcfc;
    border-radius: 4px 4px 0 0;
    border-bottom: 1px solid var(--border-deep); }
    .pay-modal .pay-modal-title .close {
      float: right;
      cursor: pointer;
      margin-right: 24px; }
      .pay-modal .pay-modal-title .close .iconfont {
        font-size: 15px; }
  .pay-modal .pay-modal-body {
    background: #fff;
    border-radius: 0 0 4px 4px; }
    .pay-modal .pay-modal-body .pay-modal-body-head {
      padding: 12px;
      border-bottom: 1px solid var(--border-deep);
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: space-between;
      width: 100%; }
    .pay-modal .pay-modal-body .pau-modal-body-main {
      padding: 48px 0;
      text-align: center; }
      .pay-modal .pay-modal-body .pau-modal-body-main .main-box {
        display: inline-block; }
        .pay-modal .pay-modal-body .pau-modal-body-main .main-box .price-box {
          font-size: 18px;
          font-weight: bold;
          color: var(--orangey-red); }
        .pay-modal .pay-modal-body .pau-modal-body-main .main-box .qrcode-box {
          padding: 2px;
          border: 1px solid var(--border-deep);
          line-height: 1; }
        .pay-modal .pay-modal-body .pau-modal-body-main .main-box .tips-box {
          margin-top: 12px;
          line-height: 1.3;
          font-size: 12px;
          color: var(--font-normal); }

.wechat-pay-page-box .wrap {
  width: 1200px;
  margin: 0 auto; }

.wechat-pay-page-box .wechat-pay-page-head {
  background: #fff;
  border-bottom: var(--border-deep);
  padding: 16px 0; }
  .wechat-pay-page-box .wechat-pay-page-head img, .wechat-pay-page-box .wechat-pay-page-head span {
    display: inline-block;
    vertical-align: middle; }
  .wechat-pay-page-box .wechat-pay-page-head span {
    padding-left: 6px;
    margin-left: 6px;
    position: relative; }
    .wechat-pay-page-box .wechat-pay-page-head span:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 1px;
      height: 32px;
      margin-top: -6px;
      background: var(--border-deep); }

.wechat-pay-page-box .wechat-pay-page-info-box {
  position: relative;
  padding: 32px 24px;
  border-bottom: 3px solid #b3b3b3;
  color: var(--font-title); }
  .wechat-pay-page-box .wechat-pay-page-info-box .title {
    font-size: 12px;
    margin-bottom: 18px; }
  .wechat-pay-page-box .wechat-pay-page-info-box .info {
    display: flex;
    justify-content: space-between;
    font-size: 14px; }
    .wechat-pay-page-box .wechat-pay-page-info-box .info i {
      font-weight: 600;
      display: inline-block;
      margin-right: 32px; }
    .wechat-pay-page-box .wechat-pay-page-info-box .info .price i {
      margin-right: 0;
      font-size: 18px;
      color: var(--font-high); }
  .wechat-pay-page-box .wechat-pay-page-info-box .btn {
    position: absolute;
    right: 20px;
    bottom: 0;
    width: 70px;
    height: 24px;
    border-radius: 2px 2px 0 0;
    line-height: 24px;
    text-align: center;
    font-size: 14px;
    background: #b3b3b3;
    cursor: pointer;
    color: #ffffff; }

.wechat-pay-page-box .wechat-pat-page-content {
  min-height: 526px;
  border-bottom: 3px solid #b3b3b3;
  background: #fff;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center; }
  .wechat-pay-page-box .wechat-pat-page-content .main-box {
    text-align: center;
    font-size: 14px;
    color: var(--font-title);
    line-height: 1; }
    .wechat-pay-page-box .wechat-pat-page-content .main-box .price {
      font-size: 18px;
      color: var(--font-high);
      margin: 10px 0; }
    .wechat-pay-page-box .wechat-pat-page-content .main-box .qrcode-box {
      border: 1px solid var(--border-deep);
      padding: 6px;
      margin: 10px 0; }

.user-page-box {
  padding: 16px;
  background: #fff;
  display: flex;
  height: 100%; }
  .user-page-box .left-box {
    flex: 1; }
  .user-page-box .user-info-card {
    border: 1px solid #f5f5f5;
    margin-bottom: 16px; }
    .user-page-box .user-info-card .info-box {
      padding: 10px;
      background: #F8F8F8;
      display: flex; }
      .user-page-box .user-info-card .info-box .user-card {
        flex: 1; }
      .user-page-box .user-info-card .info-box .account-box {
        width: 40%; }
    .user-page-box .user-info-card .order-statistic-box {
      height: 84px; }
      .user-page-box .user-info-card .order-statistic-box .statistic-box-card {
        height: 100%; }
  .user-page-box .info-list-card {
    border: 1px solid #f5f5f5;
    margin-bottom: 16px; }
    .user-page-box .info-list-card .info-list-card-head {
      height: 40px;
      line-height: 40px;
      background: #f8f8f8;
      border-bottom: 1px solid #f5f5f5;
      font-size: 14px;
      color: var(--font-normal);
      padding: 0 16px; }
    .user-page-box .info-list-card .info-list-card-main .null-tips-box {
      padding: 40px 0; }
    .user-page-box .info-list-card .info-list-card-main .list-card-box {
      padding: 16px;
      display: flex;
      align-content: center;
      align-items: center;
      border-bottom: 1px solid #f5f5f5; }
      .user-page-box .info-list-card .info-list-card-main .list-card-box:last-child {
        border-bottom: 0 none; }
      .user-page-box .info-list-card .info-list-card-main .list-card-box .img-box {
        width: 36px;
        height: 36px;
        text-align: center;
        line-height: 36px;
        margin-right: 8px; }
        .user-page-box .info-list-card .info-list-card-main .list-card-box .img-box img {
          max-height: 100%;
          max-width: 100%; }
      .user-page-box .info-list-card .info-list-card-main .list-card-box .main-box {
        flex: 1;
        width: 0;
        line-height: 1;
        font-size: 12px;
        margin-right: 24px; }
        .user-page-box .info-list-card .info-list-card-main .list-card-box .main-box .text-title {
          color: var(--font-title); }
        .user-page-box .info-list-card .info-list-card-main .list-card-box .main-box .text-tips {
          margin-top: 8px;
          color: var(--font-tips); }
      .user-page-box .info-list-card .info-list-card-main .list-card-box .btn-box .btn {
        width: 64px;
        height: 24px;
        border-radius: 2px;
        border: 1px solid #dcdcdc;
        text-align: center;
        line-height: 22px;
        font-size: 12px;
        color: var(--font-title);
        background: #fff; }
        .user-page-box .info-list-card .info-list-card-main .list-card-box .btn-box .btn:hover {
          color: #fff;
          background: var(--color); }
      .user-page-box .info-list-card .info-list-card-main .list-card-box .tips-box {
        font-size: 12px;
        color: var(--font-tips); }
  .user-page-box .info-data-card {
    border: 1px solid #f5f5f5;
    margin-bottom: 16px; }
    .user-page-box .info-data-card .info-data-card-head {
      height: 40px;
      line-height: 40px;
      background: #f8f8f8;
      border-bottom: 1px solid #f5f5f5;
      font-size: 14px;
      color: var(--font-normal);
      padding: 0 16px; }
    .user-page-box .info-data-card .info-data-card-body {
      padding: 16px;
      font-size: 12px;
      color: var(--font-normal); }
      .user-page-box .info-data-card .info-data-card-body .row-card {
        margin-bottom: 10px; }
      .user-page-box .info-data-card .info-data-card-body .iconfont {
        display: inline-block;
        vertical-align: middle;
        margin-right: 4px;
        font-size: 12px; }
  .user-page-box .right-box {
    width: 290px;
    margin-left: 16px; }

.account-details-page-box {
  margin-top: 14px;
  background: #fff;
  height: 100%; }
  .account-details-page-box .account-details-page-main {
    padding: 16px; }
  .account-details-page-box .account-details-page-main-head {
    margin-bottom: 8px; }
    .account-details-page-box .account-details-page-main-head .input-wrap {
      display: inline-block;
      vertical-align: middle;
      width: 260px;
      margin-right: 14px; }
    .account-details-page-box .account-details-page-main-head .select-box {
      display: inline-block;
      vertical-align: middle;
      width: 260px;
      margin-right: 14px; }
    .account-details-page-box .account-details-page-main-head .order-statistics-export-btn {
      width: 68px;
      height: 32px;
      background-color: transparent;
      border: 1px solid #e3e3e3;
      border-radius: 6px;
      float: right; }
  .account-details-page-box .account-details-page-main-body .statistics-box {
    height: 96px;
    background: #f5f5f5;
    border: 1px solid var(--border-deep);
    display: flex;
    align-items: center;
    align-content: center;
    margin-bottom: 16px; }
    .account-details-page-box .account-details-page-main-body .statistics-box .statistics-item {
      flex: 1;
      text-align: center; }
      .account-details-page-box .account-details-page-main-body .statistics-box .statistics-item .number {
        font-size: 20px;
        font-weight: bold; }
      .account-details-page-box .account-details-page-main-body .statistics-box .statistics-item .title {
        font-size: 12px;
        color: var(--font-tips); }
  .account-details-page-box .account-details-page-main-body .order-statistic-box {
    background-color: #fff;
    border: none; }
  .account-details-page-box .account-details-page-main-foot {
    text-align: center;
    margin-top: 16px; }

.pick-up-list-page .account-details-page-main .account-details-page-main-head .input-wrap .ant-input-affix-wrapper {
  margin-right: 30px; }

.pick-up-list-page .account-details-page-main-body .cont-table .tr-txt {
  cursor: pointer; }
  .pick-up-list-page .account-details-page-main-body .cont-table .tr-txt.active {
    background-color: pink; }

.title-line-box {
  padding-left: 12px;
  position: relative;
  font-size: 14px;
  line-height: 28px;
  color: var(--font-title);
  border-bottom: 1px solid var(--border-deep); }
  .title-line-box:before {
    content: "";
    display: inline-block;
    width: 2px;
    height: 12px;
    background: var(--font-title);
    position: absolute;
    left: 0;
    top: 8px; }

.account-page-box {
  margin-top: 14px;
  background: #fff; }
  .account-page-box .account-page-body {
    padding: 16px; }
    .account-page-box .account-page-body .title-line-box {
      margin-bottom: 16px; }
    .account-page-box .account-page-body .form-item-box {
      width: 400px;
      display: inline-block;
      vertical-align: middle;
      margin-right: 8px; }

.sub-account-box {
  height: 500px; }

.notices-details-page-box {
  margin-top: 14px;
  background: #fff; }
  .notices-details-page-box .notices-details-page-body {
    padding: 16px; }
  .notices-details-page-box .attachment-list a {
    display: flex; }
  .notices-details-page-box .attachment-list .img-box {
    width: 50px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 8px; }
    .notices-details-page-box .attachment-list .img-box img {
      max-width: 100%;
      max-height: 100%; }
    .notices-details-page-box .attachment-list .img-box .svg-icon {
      width: 100%;
      height: 100%; }
  .notices-details-page-box .attachment-list .name {
    flex: 1; }

.staff-page-box {
  margin-top: 14px;
  background: #fff; }
  .staff-page-box .staff-page-body {
    padding: 16px; }
    .staff-page-box .staff-page-body .add-btn-box {
      padding-bottom: 16px; }
      .staff-page-box .staff-page-body .add-btn-box .link-btn {
        border: 0 none;
        background: transparent;
        color: var(--color);
        font-size: 14px;
        margin-right: 16px;
        display: inline-block;
        vertical-align: middle; }
        .staff-page-box .staff-page-body .add-btn-box .link-btn[disabled] {
          color: var(--disabled); }
        .staff-page-box .staff-page-body .add-btn-box .link-btn .iconfont {
          margin-right: 4px;
          font-size: 14px; }
      .staff-page-box .staff-page-body .add-btn-box .tips {
        display: inline-block;
        vertical-align: middle;
        font-size: 12px;
        color: var(--font-tips); }

.collect-list-box {
  margin-top: 14px;
  background: #fff;
  min-height: 100%; }
  .collect-list-box .collect-list-body {
    padding: 16px 0; }
    .collect-list-box .collect-list-body .filter-sheet-wrap {
      padding: 0; }
    .collect-list-box .collect-list-body .sort-sheet-box {
      padding: 16px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid var(--border-deep); }
      .collect-list-box .collect-list-body .sort-sheet-box .ope-wrap .btn {
        margin-left: 12px;
        display: inline-block;
        vertical-align: middle;
        width: auto;
        padding: 0 8px;
        font-size: 12px;
        color: var(--font-title);
        background: #f5f5f5;
        border: 1px solid var(--border-deep); }
      .collect-list-box .collect-list-body .sort-sheet-box .ope-wrap .link-btn {
        display: inline-block;
        vertical-align: middle;
        font-size: 14px;
        color: var(--font-normal);
        margin-left: 12px;
        cursor: pointer; }
        .collect-list-box .collect-list-body .sort-sheet-box .ope-wrap .link-btn:hover {
          color: var(--color); }
        .collect-list-box .collect-list-body .sort-sheet-box .ope-wrap .link-btn .iconfont {
          font-size: 14px;
          display: inline-block;
          vertical-align: middle;
          margin-right: 4px; }
        .collect-list-box .collect-list-body .sort-sheet-box .ope-wrap .link-btn .icon-check-s {
          color: var(--color); }

.help-detail-page-box {
  display: flex;
  width: 100%; }
  .help-detail-page-box .notices-details-page-box {
    flex: 1; }

.view-card-box {
  display: flex;
  align-items: center;
  align-content: center;
  border-bottom: 1px solid var(--border-deep);
  padding: 10px 0; }
  .view-card-box .title-box {
    width: 90px;
    display: flex;
    align-content: center;
    align-items: center;
    font-size: 14px;
    color: var(--font-normal); }
    .view-card-box .title-box .txt:before {
      content: "";
      display: inline-block;
      vertical-align: middle;
      width: 8px;
      height: 8px;
      background: var(--border-deep);
      border-radius: 50%;
      margin-right: 4px; }
  .view-card-box .main-box {
    padding-left: 1em;
    font-size: 14px;
    color: var(--font-title);
    flex: 1; }
    .view-card-box .main-box.hint {
      color: var(--font-high); }
    .view-card-box .main-box .img-box {
      display: inline-block;
      vertical-align: top;
      width: 100px;
      height: 100px;
      border: 1px solid var(--border-deep);
      margin-right: 8px;
      margin-bottom: 8px;
      text-align: center;
      line-height: 98px; }
      .view-card-box .main-box .img-box img {
        max-width: 100%;
        max-height: 100%; }

.invoice-list-page {
  margin: 16px 0; }
  .invoice-list-page .invoice-list-page-main {
    padding: 16px;
    background-color: #fff; }
    .invoice-list-page .invoice-list-page-main .tabs-box .tab {
      display: inline-block;
      vertical-align: middle;
      margin: 0 14px;
      font-size: 14px;
      color: var(--font-normal);
      padding-bottom: 4px;
      border-bottom: 2px solid transparent;
      cursor: pointer; }
      .invoice-list-page .invoice-list-page-main .tabs-box .tab:hover {
        color: var(--color); }
      .invoice-list-page .invoice-list-page-main .tabs-box .tab.active {
        color: var(--color);
        border-color: var(--color); }
  .invoice-list-page .invoice-list-table-wrap {
    padding: 16px; }
    .invoice-list-page .invoice-list-table-wrap .cont-table {
      min-height: 320px;
      margin-bottom: 24px; }
    .invoice-list-page .invoice-list-table-wrap .ant-empty {
      margin-top: 32px; }

.invoice-detail-des {
  padding: 8px 12px;
  background: #FAFAFA; }
  .invoice-detail-des .head {
    font-size: 16px;
    font-weight: 700; }
    .invoice-detail-des .head .label {
      color: var(--font-normal); }
    .invoice-detail-des .head .value {
      color: var(--font-title); }
  .invoice-detail-des .row {
    margin-top: 6px; }
    .invoice-detail-des .row .label {
      font-size: 14px;
      color: var(--font-normal); }
    .invoice-detail-des .row .value {
      display: inline-block;
      min-width: 130px;
      margin-right: 24px;
      color: var(--font-title); }

.invoice-detail-attachment {
  padding: 24px; }

.coupon-list-page {
  position: relative;
  margin: 16px 0; }
  .coupon-list-page .coupon-center-btn {
    position: absolute;
    right: 0;
    top: 0;
    line-height: 40px;
    font-size: 14px;
    padding-right: 24px;
    color: var(--font-title);
    font-weight: 600;
    cursor: pointer; }
  .coupon-list-page .coupon-list-tabs {
    margin-top: 8px;
    padding: 14px;
    background-color: #fff; }
    .coupon-list-page .coupon-list-tabs .coupon-list-tabpane {
      display: flex;
      flex-wrap: wrap;
      width: 100%; }
      .coupon-list-page .coupon-list-tabs .coupon-list-tabpane .coupon-list-card:nth-child(3n) {
        margin-right: 0; }
      .coupon-list-page .coupon-list-tabs .coupon-list-tabpane .ant-empty {
        display: block;
        margin: 16px auto; }
  .coupon-list-page .coupon-list-card {
    width: 310px;
    margin-right: 16px;
    margin-bottom: 16px; }
    .coupon-list-page .coupon-list-card .info-box {
      position: relative;
      height: 124px;
      padding: 16px;
      color: #fff;
      background: radial-gradient(circle at left center, transparent 4px, #FF3838 0) left top/4px 12px repeat-y, linear-gradient(to left, #FF6969, #FF3838) 3px/303px no-repeat, radial-gradient(circle at right center, transparent 4px, #FF6969 0) right top/4px 12px repeat-y; }
      .coupon-list-page .coupon-list-card .info-box .price {
        font-size: 36px;
        line-height: 1; }
        .coupon-list-page .coupon-list-card .info-box .price:first-letter {
          font-size: 24px; }
        .coupon-list-page .coupon-list-card .info-box .price .target {
          font-size: 16px;
          margin-left: 8px; }
      .coupon-list-page .coupon-list-card .info-box .name {
        font-size: 18px;
        line-height: 1;
        margin: 8px 0; }
      .coupon-list-page .coupon-list-card .info-box .time {
        font-size: 14px;
        opacity: .85; }
      .coupon-list-page .coupon-list-card .info-box .tag {
        position: absolute;
        right: 16px;
        top: 0;
        height: 24px;
        line-height: 24px;
        padding: 0 8px;
        border-radius: 0 0 6px 6px;
        color: #FF3838;
        background-color: #ffcdcd; }
      .coupon-list-page .coupon-list-card .info-box .status-icon {
        position: absolute;
        right: 8px;
        bottom: 8px; }
    .coupon-list-page .coupon-list-card .intro-box {
      margin-top: -1px;
      border: 1px solid var(--border-deep);
      border-radius: 0 4px 4px 0; }
    .coupon-list-page .coupon-list-card .explain-box {
      display: flex;
      justify-content: space-between;
      padding: 0 16px;
      margin: 16px 0;
      cursor: pointer; }
      .coupon-list-page .coupon-list-card .explain-box .label {
        color: var(--font-title); }
      .coupon-list-page .coupon-list-card .explain-box .introduce {
        flex: 1;
        color: var(--font-normal); }
      .coupon-list-page .coupon-list-card .explain-box .iconfont {
        font-size: 14px; }
    .coupon-list-page .coupon-list-card .coupon-btn {
      width: 128px;
      height: 24px;
      border-radius: 12px;
      background-color: white;
      border: 1px solid var(--color);
      color: var(--color);
      text-align: center;
      margin: 0 auto 16px;
      cursor: pointer;
      user-select: none; }
  .coupon-list-page .coupon-list-card-used .info-box,
  .coupon-list-page .coupon-list-card-expires .info-box {
    position: relative;
    height: 124px;
    padding: 16px;
    color: #000;
    background: radial-gradient(circle at left center, transparent 4px, #E3E3E3 0) left top/4px 12px repeat-y, linear-gradient(to left, #E3E3E3, #E3E3E3) 3px/303px no-repeat, radial-gradient(circle at right center, transparent 4px, #E3E3E3 0) right top/4px 12px repeat-y; }
    .coupon-list-page .coupon-list-card-used .info-box .tag,
    .coupon-list-page .coupon-list-card-expires .info-box .tag {
      background-color: #f5f5f5;
      color: rgba(0, 0, 0, 0.45); }
  .coupon-list-page .ant-pagination {
    padding: 24px 0;
    background-color: #fff;
    text-align: center; }

.coupon-center-page .headline {
  text-align: center;
  height: 114px;
  line-height: 114px;
  background-color: #fff; }
  .coupon-center-page .headline .icon-title-1 {
    width: 316px;
    height: 40px; }

.coupon-center-page .coupon-center-main {
  width: 1200px;
  margin: 8px auto;
  padding: 24px;
  background-color: #fff; }
  .coupon-center-page .coupon-center-main .ant-pagination {
    padding-top: 24px;
    text-align: center; }

.coupon-center-page .coupon-center-list {
  display: flex;
  flex-wrap: wrap; }

.coupon-center-page .coupon-center-card {
  width: 374px;
  display: flex;
  margin-bottom: 16px;
  margin-right: 15px;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.2); }
  .coupon-center-page .coupon-center-card:nth-child(3n) {
    margin-right: 0; }
  .coupon-center-page .coupon-center-card .price-box {
    width: 120px;
    height: 108px;
    padding-top: 32px;
    text-align: center;
    color: #fff;
    background: radial-gradient(circle at left center, transparent 3px, #FF3838 0) left top/4px 12px repeat-y, linear-gradient(to left, #FF6969, #FF3838) 3px/118px no-repeat; }
    .coupon-center-page .coupon-center-card .price-box .money {
      font-size: 24px;
      line-height: 1; }
      .coupon-center-page .coupon-center-card .price-box .money:first-letter {
        font-size: 12px; }
    .coupon-center-page .coupon-center-card .price-box .target {
      line-height: 1;
      font-size: 12px;
      margin-top: 8px; }
  .coupon-center-page .coupon-center-card .info-box {
    display: flex;
    width: 255px;
    height: 108px;
    padding: 16px 0;
    border-radius: 0 2px 2px 0;
    background-color: white;
    border: 1px solid #e3e3e3; }
  .coupon-center-page .coupon-center-card .intro-box {
    width: 162px;
    padding: 0 12px 0 16px;
    border-right: 1px dashed var(--border-deep); }
    .coupon-center-page .coupon-center-card .intro-box .tag {
      display: inline-block;
      padding: 0 8px;
      font-size: 12px;
      line-height: 16px;
      border-radius: 49px;
      background-color: var(--color-hint);
      color: #fff; }
    .coupon-center-page .coupon-center-card .intro-box .name {
      font-size: 14px;
      color: var(--font-title);
      font-weight: 600; }
    .coupon-center-page .coupon-center-card .intro-box .time {
      font-size: 12px;
      color: var(--font-tips); }
  .coupon-center-page .coupon-center-card .status-box {
    width: 92px;
    text-align: center; }
  .coupon-center-page .coupon-center-card .btn-box {
    text-align: center; }
    .coupon-center-page .coupon-center-card .btn-box .btn {
      font-size: 12px;
      line-height: 24px;
      padding: 0 8px;
      border-radius: 11px;
      border: 0 none;
      background-color: var(--color-hint);
      color: #fff; }

.coupon-center-page .coupon-card-use .price-box {
  background: #e3e3e3;
  color: #000; }
  .coupon-center-page .coupon-card-use .price-box .target {
    opacity: .65; }

.coupon-center-page .coupon-card-use .info-box .tag {
  color: var(--font-normal);
  background-color: #e3e3e3; }

.experience-add-box {
  width: 100%;
  background: #fff; }

.experience-add-body {
  width: 1200px;
  height: 300px;
  margin: 0 auto;
  background: url("../../assets/images/map.png") no-repeat center/530px 260px;
  text-align: center; }
  .experience-add-body h2 {
    padding-top: 100px;
    font-size: 24px;
    color: var(--color); }
  .experience-add-body .experience-add {
    padding-top: 22px; }
    .experience-add-body .experience-add .select-box {
      display: inline-block;
      vertical-align: middle;
      width: 240px;
      padding: 0;
      margin: 0 20px; }
    .experience-add-body .experience-add .search-size-max {
      height: 40px;
      line-height: 40px; }
    .experience-add-body .experience-add .dis-inb:last-child .search-size-max {
      width: 260px; }
    .experience-add-body .experience-add .dis-inb:last-child .txt {
      padding-right: 20px; }

.experience-list-box {
  width: 1200px;
  margin: 0 auto;
  font-size: 0;
  padding-top: 16px; }
  .experience-list-box .null-tips-box {
    height: 300px;
    text-align: center;
    padding-top: 120px;
    font-size: 14px;
    color: var(--font-normal); }

.experience-card {
  position: relative;
  display: inline-block;
  vertical-align: top;
  padding: 16px;
  background: #fff;
  border: 1px solid var(--border-deep);
  width: calc(50% - 8px);
  margin-right: 16px;
  margin-bottom: 16px;
  font-size: 0;
  cursor: pointer; }
  .experience-card:nth-child(2n) {
    margin-right: 0; }
  .experience-card h2 {
    font-size: 16px;
    color: var(--font-title);
    margin-bottom: 12px;
    cursor: pointer;
    min-height: 40px; }
    .experience-card h2:hover {
      color: var(--color); }
    .experience-card h2 .iconfont {
      margin-right: 4px; }
  .experience-card .img-box {
    width: 160px;
    height: 160px;
    display: inline-block;
    vertical-align: top; }
    .experience-card .img-box img {
      width: 160px;
      height: 160px; }
  .experience-card .txt-box {
    display: inline-block;
    width: calc(100% - 170px);
    vertical-align: top;
    padding: 12px 0 0 24px; }
    .experience-card .txt-box dt {
      font-size: 14px;
      color: var(--font-title);
      font-weight: bold;
      line-height: 1.3;
      padding-bottom: 12px;
      cursor: pointer; }
      .experience-card .txt-box dt:hover {
        color: var(--color); }
    .experience-card .txt-box dd {
      font-size: 12px;
      color: var(--font-tips);
      line-height: 1.3;
      padding-bottom: 12px; }
  .experience-card .experience-btn {
    position: absolute;
    bottom: 16px;
    right: 16px;
    width: 149px;
    height: 34px;
    border-radius: 4px;
    background-color: #ffffff;
    border: solid 1px var(--color);
    line-height: 34px;
    text-align: center;
    font-size: 14px;
    color: var(--color);
    cursor: pointer; }

.experience-detail-box {
  width: 1200px;
  margin: 0 auto; }

.experience-detail-head {
  margin: 20px 0;
  font-size: 0;
  height: 420px; }
  .experience-detail-head .experience-info {
    display: inline-block;
    vertical-align: top;
    width: 450px;
    height: 100%;
    background: #fff;
    padding: 18px 0 26px;
    text-align: center; }
    .experience-detail-head .experience-info h4 {
      font-size: 14px;
      color: var(--font-title);
      font-weight: bold;
      line-height: 1em;
      padding-bottom: 24px; }
    .experience-detail-head .experience-info .code-box {
      display: inline-block;
      width: 180px;
      height: 180px;
      border: 1px solid var(--border-deep);
      margin-bottom: 32px;
      padding: 4px; }
    .experience-detail-head .experience-info p {
      font-size: 12px;
      color: var(--font-title);
      padding-bottom: 8px;
      line-height: 1em; }
    .experience-detail-head .experience-info .experience-btn {
      margin-top: 26px;
      display: inline-block;
      width: 149px;
      height: 34px;
      border-radius: 4px;
      background-color: #ffffff;
      border: solid 1px var(--color);
      line-height: 34px;
      text-align: center;
      font-size: 14px;
      color: var(--color);
      cursor: pointer; }
  .experience-detail-head .experience-banner {
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 450px);
    height: 420px;
    background: #fff;
    position: relative; }
    .experience-detail-head .experience-banner .ant-carousel {
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2); }
      .experience-detail-head .experience-banner .ant-carousel .slick-slide {
        text-align: center;
        height: 420px;
        line-height: 420px;
        overflow: hidden; }
      .experience-detail-head .experience-banner .ant-carousel .slick-dots li {
        overflow: hidden;
        border-radius: 4px; }
        .experience-detail-head .experience-banner .ant-carousel .slick-dots li.slick-active button {
          background: var(--color); }
    .experience-detail-head .experience-banner .img-box {
      width: 100%;
      height: 420px;
      position: relative; }
      .experience-detail-head .experience-banner .img-box .img-wrap {
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        max-width: none; }
        .experience-detail-head .experience-banner .img-box .img-wrap img {
          width: 100%;
          height: 100%; }

.dis-inb {
  display: inline-block; }

.pop-experience .experience-body {
  text-align: center; }
  .pop-experience .experience-body h4 {
    font-size: 14px;
    color: var(--font-title);
    font-weight: bold;
    line-height: 1em;
    padding-bottom: 10px;
    text-align: center; }
  .pop-experience .experience-body .code {
    display: inline-block;
    width: 180px;
    height: 180px;
    border: 1px solid var(--border-deep);
    padding: 4px;
    margin-bottom: 18px; }
  .pop-experience .experience-body p {
    font-size: 12px;
    line-height: 1em;
    color: var(--font-title);
    padding-bottom: 10px;
    text-align: center; }

.experience-detail-body {
  background: #fff; }
  .experience-detail-body .experience-detail-body-title {
    font-size: 14px;
    font-weight: bold;
    padding: 16px;
    border-bottom: 1px solid var(--border-deep);
    position: relative; }
    .experience-detail-body .experience-detail-body-title:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      display: inline-block;
      width: 2px;
      height: 16px;
      background: var(--font-title);
      transform: translateY(-50%); }

.video-box {
  padding: 16px 0;
  text-align: center; }
  .video-box .video-wrap {
    display: inline-block;
    width: 600px;
    height: 400px; }
  .video-box video {
    display: inline-block;
    width: 100%;
    height: 100%; }

.experience-introduce-box {
  padding: 24px; }

.experience-page-footer {
  text-align: center; }
