.inquire-page-box {
  height: 100%;
  //min-height: 80vh;
  background: #fff;
  padding: 16px 16px 80px;
  position: relative;

  .ant-spin-nested-loading > div > .ant-spin {
    top: 50%;
    transform: translateY(-50%);
  }
}

.spin-box {
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, .1);
  z-index: 6;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.inquire-head-wrap {
  display: flex;
  justify-content: space-between;

  .search-wrap {
    width: 578px;
    border: 1px solid var(--color);
    position: relative;

    .search-box {

      .txt {
        padding-right: 120px;

        .ant-input-suffix {
          right: 132px;
          z-index: 0;
          line-height: 38px;
        }
      }
    }

    .ant-input {
      border: 0 none;
      height: 38px;
    }

    .search-btn {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 120px;
      background: var(--color);
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: center;
      color: #fff;
      font-size: 16px;
      cursor: pointer;
      border: 0 none;

      &:hover {
        opacity: .9;
      }

      &.search-btn-disabled {
        background: var(--disabled);
      }

      i {
        font-size: 18px;
        margin-right: 4px;
      }
    }

    .search-dropdown-box {
      position: absolute;
      top: 49px;
      left: 0;
      right: 0;
      height: 344px;
      background: #fff;
      box-shadow: 0 7px 8px 0 rgba(0, 0, 0, 0.1);
      border: 1px solid #f0f0f0;
      z-index: 1;

      .search-dropdown-wrap {
        padding: 16px;
      }

      .log-item {
        font-size: 12px;
        cursor: pointer;
        padding: 12px 0;
        border-bottom: 1px solid var(--border-deep);

        &:first-child {
          padding-top: 0;
        }

        .tit {
          color: var(--font-title);
        }

        .txt {
          color: var(--font-tips);
        }

        .del {
          &:hover {
            color: var(--color);
          }
        }
      }
    }
  }

  .user-info {
    display: flex;
    align-content: center;
    align-items: center;
    font-size: 14px;
    color: var(--font-title);
    cursor: pointer;

    .txt {
      margin-left: 6px;
    }

    .btn {
      margin-left: 6px;
      color: var(--color);
    }
  }
}

.inquire-null-tips-box {
  width: 100%;
  text-align: center;
  padding-top: 80px;
}

.car-info-box {
  display: flex;
  padding: 16px;
  background: #FAFAFA;
  margin-top: 16px;
  position: relative;

  &:before {
    content: "";
    display: inline-block;
    width: 16px;
    height: 16px;
    background: #fafafa;
    position: absolute;
    top: -8px;
    left: 40px;
    transform: rotate(45deg);
  }

  .car-img {
    width: 50px;
    height: 50px;
    background: #fff;
    text-align: center;
    line-height: 50px;
    margin-right: 16px;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .car-info-wrap {
    flex: 1;

    .title {
      font-size: 16px;
      color: var(--font-title);
      font-weight: bold;
      line-height: 1;
      margin-bottom: 8px;
    }

    .car-list {
      margin-bottom: 8px;

      .car-item {
        display: inline-block;
        vertical-align: middle;
        min-width: 160px;
        padding: 0 1em;
        height: 24px;
        margin-right: 12px;
        text-align: center;
        line-height: 24px;
        border-radius: 2px;
        font-size: 12px;
        border: 1px solid var(--border-deep);
        color: var(--font-title);
        cursor: pointer;

        &:hover, &.active {
          border-color: var(--color);
          color: var(--color);
        }
      }
    }
  }
}

.inquire-body-wrap {
  min-height: 600px;
}

.parts-box-wrap {
  margin-top: 32px;

  .title {
    padding-bottom: 6px;
    border-bottom: 1px solid var(--border-deep);
    position: relative;
    font-size: 14px;
    font-weight: bold;
    color: var(--font-title);
    display: flex;
    align-items: center;

    &:before {
      content: "";
      display: inline-block;
      vertical-align: middle;
      width: 2px;
      height: 14px;
      background: var(--font-title);
      margin-right: 6px;
    }
  }

  .parts-box-main {
    padding-top: 16px;

    .search-box-wrap {
      .input-wrap {
        display: inline-block;
        vertical-align: middle;
        width: 342px;
        position: relative;

        .input-area {
          display: flex;

          .txt {
            flex: 1;
            height: 32px;
            border: 1px solid var(--border-deep);
            border-radius: 4px 0 0 4px;
            padding-left: 1em;
            font-size: 14px;
            color: var(--font-title);
          }

          .search-btn {
            display: inline-block;
            width: 52px;
            height: 32px;
            text-align: center;
            line-height: 32px;
            background: var(--color);
            color: #fff;
            font-size: 20px;
            cursor: pointer;

            &:hover {
              opacity: .8;
            }
          }
        }

        .result-wrap {
          position: absolute;
          top: 32px;
          left: 0;
          right: 0;
          height: 343px;
          background: #fff;
          box-shadow: 0 7px 8px 0 rgba(0, 0, 0, 0.1);
          border: 1px solid rgba(240, 240, 240, 1);
          padding: 0 14px;

          .spin-box {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }

          li {
            font-size: 14px;
            color: var(--font-title);
            line-height: 32px;
            border-bottom: 1px solid var(--border-deep);
            cursor: pointer;

            &:hover {
              color: var(--color);
            }
          }
        }
      }

      .epc-btn {
        margin-left: 16px;
        display: inline-block;
        vertical-align: middle;
        font-size: 14px;
        color: var(--color);
        cursor: pointer;

        .iconfont {
          font-size: 18px;
        }
      }
    }
  }
}

.parts-result-box {
  margin-top: 24px;

  table {
    width: 100%;
    border: 1px solid var(--border-deep);
    table-layout: fixed;
    margin-bottom: 16px;
  }

  th {
    height: 40px;
    line-height: 40px;
    background: #f5f5f5;
    font-weight: normal;
    padding: 0 16px;
    border-bottom: 1px solid var(--border-deep);
    color: var(--font-normal);

    &:nth-child(2), &:nth-child(3) {
      width: 15%;
    }

    .price {
      color: var(--font-high);

      &:hover {
        color: var(--font-high);
      }
    }

    .epc-btn {
      cursor: pointer;
      float: left;

      .svg-icon {
        width: 30px;
        height: 30px;
      }
    }

    span {
      display: inline-block;
      margin-left: 10px;
      cursor: pointer;

      &:hover {
        color: var(--color);
      }

      .iconfont {
        font-size: 12px;
      }
    }

    &:last-child {
      text-align: right;
    }
  }

  td {
    padding: 12px 16px;
    border-bottom: 1px solid var(--border-deep);
    color: var(--font-high);

    p {
      margin-bottom: 0;
    }

    .tips {
      font-size: 14px;
      color: var(--font-tips);
    }

    .info-box {
      .tit {
        font-size: 14px;
        color: var(--font-title);
        margin-bottom: 1em;
      }

      .tips {
        font-size: 14px;
        color: var(--font-tips);

        span {
          display: inline-block;
          vertical-align: top;
          margin-right: 1em;

          i {
            color: var(--font-high);
          }
        }
      }

    }

    .price {
      display: inline-block;
      margin-right: 8px;
      font-size: 16px;
    }

    .cost {
      font-size: 12px;
      color: var(--font-tips);
      text-decoration: line-through;
    }

    .cart {
      cursor: pointer;
    }

    &:last-child {
      text-align: right;
    }
  }
}

.ys-drawer-box {
  .ant-drawer-content-wrapper {
    &:before {
      content: "·原厂数据·";
      display: inline-block;
      width: 144px;
      height: 54px;
      line-height: 54px;
      text-align: center;
      font-size: 18px;
      background: #fff;
      border-radius: 50px 0 0 50px;
      position: absolute;
      top: 0;
      left: -144px;
    }

    .ant-drawer-wrapper-body {
      display: flex;
      flex-direction: column;
      overflow: hidden;

      .ant-drawer-body {
        flex: 1;
        height: 0;
        padding: 0;
      }
    }
  }

  .epc-data-box {
    height: 100%;

    .ant-layout {
      height: 100%;
      background: #fff;
      overflow: hidden;
      padding: 8px;

      .ant-layout {
        padding: 0;
      }
    }

    .ant-layout-sider {
      background: #f8f8f8;
      overflow: hidden;
      width: 160px;

      .label-box {
        height: 40px;
        line-height: 40px;
        padding: 0 12px;
        font-size: 12px;
        color: var(--font-title);
        cursor: pointer;

        &:hover, &.active {
          background: var(--color);
          color: #fff;
        }
      }
    }

    .sider-body-box {
      height: 100%;
    }

    .ant-spin-nested-loading {
      height: 100%;

      .ant-spin-container {
        height: 100%;
      }
    }
  }

  .structure-chart-box {
    padding: 16px 0 16px 16px;
    height: 100%;
  }

  .structure-chart-card {
    display: inline-block;
    vertical-align: top;
    width: 198px;
    margin-right: 16px;
    margin-bottom: 16px;
    box-shadow: 0 2px 8px 0 rgba(153, 153, 153, 0.2);
    background: #fff;
    cursor: pointer;

    &:nth-child(3n) {
      margin-right: 0;
    }

    .img-box {
      width: 100%;
      height: 198px;
      text-align: center;
      line-height: 198px;

      img {
        max-height: 100%;
        max-width: 100%;
      }
    }

    .title {
      height: 32px;
      line-height: 32px;
      font-size: 12px;
      color: var(--font-title);
      text-align: center;
    }
  }


  .structure-chart-detail-box {
    display: flex;
    flex-direction: column;
    height: 100%;

    .structure-chart-detail-head {
      padding: 8px;

      .ant-breadcrumb span:first-child {
        .ant-breadcrumb-link {
          cursor: pointer;

          &:hover {
            color: var(--color);
          }
        }
      }

    }

    .structure-chart-detail-body {
      display: flex;
      flex-direction: column;
      flex: 1;
      height: 0;

      .structure-chart-detail-img-box {
        width: 100%;
        text-align: center;
        position: relative;

        .img-box {
          display: inline-block;
          width: 400px;
          height: 400px;
          line-height: 400px;
          text-align: center;

          img {
            max-width: 100%;
            max-height: 100%;
          }
        }

        .pagination-box {
          font-size: 12px;
          color: var(--font-normal);
          position: absolute;
          left: 50%;
          bottom: 0;
          transform: translateX(216px);

          .txt {
            display: inline-block;
            vertical-align: middle;
            margin: 0 8px;
          }

          button {
            display: inline-block;
            vertical-align: middle;
            border: 0 none;
            width: auto;
            background: transparent;
            cursor: pointer;

            &:hover {
              color: var(--color);
            }
          }
        }
      }

      .structure-chart-detail-title {
        font-size: 14px;
        color: var(--font-title);
        line-height: 1em;
        margin: 16px 0 8px;

        &:before {
          content: "";
          display: inline-block;
          margin: 0 4px 0 14px;
          width: 2px;
          height: 14px;
          background: var(--font-title);

        }
      }

      .structure-chart-detail-content {
        flex: 1;
        height: 0;
        position: relative;

        .table-head {
          position: absolute;
          top: 0;
          left: 0;
          display: flex;
          width: 100%;
          height: 40px;
          background: #fcfcfc;
          line-height: 40px;
          font-size: 14px;
          color: var(--font-normal);
          border-bottom: 1px solid var(--border-deep);
          z-index: 3;

          .head-item {
            padding-left: 24px;
            width: calc(100% / 6);
          }
        }

        .structure-chart-detail-list-box {


          table {
            width: 100%;
            table-layout: fixed;

            th {
              height: 40px;
              background: #fcfcfc;
              opacity: 0;
              width: calc(100% / 6);
            }

            td {
              height: 50px;
              padding-left: 24px;
              font-size: 14px;
              color: var(--font-title);
              vertical-align: middle;
              border-bottom: 1px solid var(--border-deep);

              .txt {
                display: inline-block;
                width: 100%;
              }

              .btn {
                display: inline-block;
                width: 76px;
                height: 22px;
                border: 1px solid var(--color);
                background: #fff;
                text-align: center;
                line-height: 20px;
                font-size: 12px;
                color: var(--color);
                cursor: pointer;

                &:hover {
                  opacity: .8;
                }
              }

              .active-btn {
                display: inline-block;
                width: 78px;
                height: 24px;
                background: var(--color);
                text-align: center;
                line-height: 24px;
                font-size: 12px;
                color: #fff;
                cursor: pointer;

                &:before {
                  content: "已加入";
                }

                &:hover {
                  &:before {
                    content: "取消加入";
                  }
                }
              }
            }
          }
        }
      }


    }

    .structure-chart-detail-footer {
      height: 50px;
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
      position: relative;
      padding-left: 24px;
      line-height: 50px;

      .tips-box {
        display: inline-block;
        font-size: 14px;
        color: var(--font-title);
      }

      .btn {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        padding: 0 30px;
        background: var(--color);
        font-size: 16px;
        color: #fff;
        border: 0 none;

        &:hover {
          opacity: .8;
        }

      }
    }
  }
}

.epc-detail-box {
  width: 100%;

  .img-box-wrap {
    width: 100%;
    text-align: center;

    .img-box {
      display: inline-block;
      width: 400px;
      height: 400px;
      text-align: center;
      line-height: 400px;
      overflow: hidden;

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }

  }

  .info-box {
    margin-top: 10px;

    .descriptions-box {
      border: 1px solid var(--border-deep);

      .descriptions-item-box {
        height: 32px;
        line-height: 32px;
        border-bottom: 1px solid var(--border-deep);
        margin: 0;
        padding: 0;

        &:last-child {
          border-bottom: 0 none;
        }

        .descriptions-title {
          width: 90px;
          border-right: 1px solid var(--border-deep);
          margin: 0;
          padding-left: 1em;
        }

        .descriptions-main {
          padding-left: 1em;
        }
      }
    }
  }
}

.inquire-foot-wrap {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 60px;
  border-top: 1px solid #f2f2f2;
  background: #fcfcfc;

  .btn-group {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;

    .btn {
      border: 0 none;
      width: 150px;
      height: 100%;
      line-height: 60px;
      text-align: center;
      font-size: 16px;
      color: #fff;
      background: var(--color);

      &[disabled] {
        background: var(--disabled);
        color: #fff;
      }

      &.minor {
        color: var(--color);
        background: var(--color-hover);
      }

      &:hover {
        opacity: .8;
      }
    }
  }
}

.inquiry-list-page-box {
  height: 100%;
  background: #fff;
  margin-top: 14px;
}

.inquiry-list-page-main {
  padding: 16px;

  .inquiry-list-page-main-head {
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    margin-bottom: 8px;

    .tabs-box {
      .tab {
        display: inline-block;
        vertical-align: middle;
        margin: 0 14px;
        font-size: 14px;
        color: var(--font-normal);
        padding-bottom: 4px;
        border-bottom: 2px solid transparent;
        cursor: pointer;

        &:hover {
          color: var(--color);
        }

        &.active {
          color: var(--color);
          border-color: var(--color);
        }
      }
    }

    .search-box {
      .input-wrap, .select-box {
        display: inline-block;
        vertical-align: middle;
      }

      .input-wrap {
        margin-right: 8px;
      }
    }
  }

  .inquiry-list-page-main-body {
    border: 1px solid var(--border-deep);

    .inquiry-list-card {
      padding: 16px;
      border-bottom: 1px solid var(--border-deep);
      font-size: 14px;
      display: flex;
      cursor: pointer;

      &:hover {
        background: var(--color-hover);
      }

      &:last-child {
        border-bottom: 0 none;
      }

      .img-box {
        width: 50px;
        height: 50px;
        text-align: center;
        line-height: 50px;

        img {
          max-width: 100%;
          max-height: 100%;
        }
      }

      .main-box {
        flex: 1;
        width: 0;
        padding: 0 16px;
        color: var(--font-tips);

        .title {
          margin-bottom: 8px;

          .txt-title {
            color: var(--font-title);

            .tips {
              margin-left: 1em;
              color: var(--font-tips);
            }
          }

          .txt {
            margin-left: 1em;
            color: var(--font-normal);
          }
        }
      }

      .tips-box {
        .txt {
          margin-left: 1em;
          color: var(--font-tips);
        }
      }

      .attachment-box {
        margin-top: 8px;
        border-top: 1px solid var(--border-deep);
        padding-top: 8px;

        .tit {
          font-size: 14px;
          color: var(--font-tips);
          margin-bottom: 4px;
        }

        .remark-box {
          font-size: 14px;
          color: var(--font-title);
          line-height: 1.5;
          margin-bottom: 4px;
          word-break: break-all;
          white-space: normal;
        }

        .attachment-main {
          .img-box {
            display: inline-block;
            vertical-align: top;
            border: 1px solid var(--border-deep);
            width: 80px;
            height: 80px;
            text-align: center;
            line-height: 80px;
            margin-right: 8px;

            img {
              max-width: 100%;
              max-height: 100%;
            }
          }
        }
      }
    }
  }

  .inquiry-list-page-main-foot {
    margin-top: 16px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
  }
}

.inquiry-detail-page-box {
  margin-top: 14px;
  background: #fff;
  height: 100%;

}

.inquiry-detail-page-main {
  padding: 16px;

  .car-info-box {
    align-content: center;
    align-items: center;
    font-size: 14px;
    border: 1px solid var(--border-deep);
    margin-top: 0;
    margin-bottom: 16px;
    padding: 8px 16px;

    &:before {
      display: none;
    }

    .img-box {
      width: 50px;
      height: 50px;
      text-align: center;
      line-height: 50px;
      background: #fff;

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }

    .info-box {
      flex: 1;
      padding-left: 24px;
      color: var(--font-tips);

      span {
        margin-right: 16px;

        &:first-child {
          color: var(--font-title);
        }
      }
    }

    .text-btn {
      color: var(--font-title);
      cursor: pointer;

      &:hover {
        color: var(--color);
      }
    }
  }

  .detail-main-box {
    display: flex;

    .left-box {
      width: 200px;
      min-height: 694px;
      margin-right: 16px;
      background: #f8f8f8;
      border: 1px solid var(--border-deep);

      li {
        width: 100%;
        height: 40px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        line-height: 40px;
        font-size: 14px;
        padding: 0 16px;
        color: var(--font-title);
        cursor: pointer;

        &:hover, &.active {
          color: var(--color);
          background: #fff;
        }
      }
    }

    .right-box {
      flex: 1;
      width: 0;
    }
  }

  .inquiry-product-list-box {
    margin-bottom: 16px;
    border: 1px solid var(--border-deep);

    .inquiry-product-list-box-head {
      background: #f8f8f8;
      height: 40px;
      line-height: 40px;
      font-size: 14px;
      color: var(--font-title);
      padding: 0 16px;
      border-bottom: 1px solid var(--border-deep);

      span {
        display: inline-block;
        margin-right: 32px;
      }

      .epc-btn {
        float: right;
        line-height: 1;
        cursor: pointer;
        margin-right: 0;

        .svg-icon {
          width: 36px;
          height: 36px;
        }
      }
    }

    .inquiry-product-list-box-main {
      min-height: 220px;

      .goods-card-box {
        display: flex;
        padding: 16px;
        border-bottom: 1px solid var(--border-deep);
        font-size: 14px;

        .info-box {
          flex: 1;

          .tit {
            color: var(--font-title);
          }

          .tips {
            span {
              margin-right: 8px;
            }

            .iconfont {
              font-size: 14px;
              color: var(--font-high);
              margin-right: 4px;
            }
          }
        }

        .price-box {
          width: 20%;
          color: var(--font-high);
        }

        .add-cart {
          width: 10%;
          text-align: right;
          cursor: pointer;
          color: var(--font-title);

          &.active {
            color: var(--font-high);
          }

          .iconfont {
            font-size: 14px;
            margin-right: 4px;
          }
        }
      }

      .null-data-box {
        text-align: center;
        padding-top: 40px;

        .null-wrap {
          display: inline-block;

          .icon-box {
            .svg-icon {
              width: 90px;
              height: 90px;
            }
          }

          .tips-text {
            font-size: 14px;
            color: var(--font-normal);
          }

          .status-box {
            width: 102px;
            height: 24px;
            border: 1px solid var(--font-tips);
            text-align: center;
            line-height: 22px;
            font-size: 14px;
            color: var(--font-normal);
            border-radius: 4px;
            margin-top: 8px;
          }

          .time {
            margin-top: 8px;
            font-size: 14px;
            color: var(--font-high);
          }
        }
      }
    }
  }

  .parts-remark-box {
    border: 1px solid var(--border-deep);

    .parts-remark-box-head {
      background: #f8f8f8;
      height: 40px;
      line-height: 40px;
      font-size: 14px;
      color: var(--font-title);
      padding: 0 16px;
      border-bottom: 1px solid var(--border-deep);

    }

    .parts-remark-box-body {
      min-height: 200px;
      padding: 16px;

      .remark-wrap {
        font-size: 14px;
        color: var(--font-title);
        margin-bottom: 8px;
        word-break: break-all;
        white-space: normal;
      }

      .img-wrap {
        .img-box {
          display: inline-block;
          vertical-align: top;
          margin-right: 8px;
          width: 144px;
          height: 144px;
          border: 1px solid var(--border-deep);
          text-align: center;
          line-height: 144px;

          img {
            max-height: 100%;
            max-width: 100%;
          }
        }
      }
    }
  }
}

.remark-pop-body {
  .text-area-wrap {
    margin-bottom: 16px;
  }
}

@media all and (max-height: 768px) {
  .ys-drawer-box .structure-chart-detail-box .structure-chart-detail-body .structure-chart-detail-img-box .img-box {
    width: 240px;
    height: 240px;
    line-height: 240px;
  }
}